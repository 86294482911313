import React from "react";
import {
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch
} from "@carbon/react";
import {Pagination} from "carbon-components-react";
import {roundFloat} from "../index";

function asString(obj) {
    try {
        return (typeof obj == 'number') ? obj : JSON.stringify(obj);
    } catch (e) {
        return obj;
    }

}

export default class DatasetTable extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.paginationChanges = this.paginationChanges.bind(this);
        this.searchTable = this.searchTable.bind(this);
        this.filteredData = undefined
        this.state = {
            // rowsData: this.props.rowsData ? this.props.rowsData : [],
            // headersData: this.props.headersData ? this.props.headersData : [],
            sortedAsc: true,
            pagination: {
                pageIndex: 1,
                pageSize: props.pageSize === undefined ? 10 : props.pageSize,
                totalItems: this.props.rowsData ? this.props.rowsData.length : 0
            }
        };
    }

    renderPagination(totalItems) {
        if (this.props.hasPagination !== undefined && this.props.hasPagination == false) {
            return (<b/>)
        } else return (<Pagination onChange={this.paginationChanges}
                                   backwardText="Previous page"
                                   forwardText="Next page"
                                   itemsPerPageText="Items per page:"
                                   page={this.state.pagination.pageIndex}
                                   pageNumberText="Page Number"
                                   pageSize={this.state.pagination.pageSize}
                                   pageSizes={[this.state.pagination.pageSize]}
                                   totalItems={totalItems.length}
                                   isLastPage={false} disabled={false} pagesUnknown={false}/>)
    }

    componentDidMount() {
        this.paginationChanges({pageSize: this.props.pageSize === undefined ? 10 : this.props.pageSize, page: 1})
    }

    headerContent(header) {
        return header.header
    }

    cellContent(row, cell) {
        return (<TableCell key={cell.id}>{roundFloat(cell.value, 2)}</TableCell>)
    }


    searchTable(event) {
        const searchValue = (event.currentTarget ? event.currentTarget.value : "").toLowerCase();
        let newData = this.props.rowsData ? this.props.rowsData : []
        newData = newData.filter(item => JSON.stringify(item).toLowerCase().indexOf(searchValue) >= 0)

        const newPagination = this.state.pagination
        newPagination.pageIndex = 1

        this.filteredData = newData
        return this.setState({
            pagination: newPagination
        })
    }

    paginationChanges(event) {
        const newPagination = this.state.pagination
        newPagination.pageIndex = event.page
        return this.setState({
            pagination: newPagination
        })
    }

    sortByColumn(column) {
        const asc = ((a, b) => {
            return asString(a) >= asString(b)
        })
        const desc = ((a, b) => {
            return asString(a) <= asString(b)
        })
        const sortFunc = this.state.sortedAsc ? asc : desc

        if (this.props.rowsData) {
            const newRows = this.props.rowsData.sort((a, b) => {
                return (sortFunc(a[column.key], b[column.key]) ? 1 : -1)
            })

            this.filteredData = newRows
            return this.setState({
                sortedAsc: !this.state.sortedAsc
            })
        }
    }

    renderToolbar() {
        if (this.props.renderToolbar !== undefined && this.props.renderToolbar === false) {
            return (<b/>)
        }

        return (<TableToolbar>
            <TableToolbarContent>
                <TableToolbarSearch onChange={this.searchTable} persistent={true}/>
            </TableToolbarContent>
        </TableToolbar>)
    }

    render() {
        const itemsStart = (this.state.pagination.pageIndex - 1) * this.state.pagination.pageSize
        const itemsEnd = this.state.pagination.pageIndex * this.state.pagination.pageSize
        const totalItems = this.filteredData !== undefined ? this.filteredData : this.props.rowsData
        const viewData = totalItems.slice(itemsStart, itemsEnd)
        this.filteredData = undefined

        return (<TableContainer>
            {this.renderToolbar()}
            <div className={"mlp_data_table_holder"}>
                <DataTable rows={viewData} headers={this.props.headersData}>
                    {({rows, headers, getHeaderProps, getTableProps}) => (
                        <TableContainer title={this.props.tableTitle ? this.props.tableTitle : ""}>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({
                                                header,
                                                isSortable: this.props.isSortable
                                            })}
                                                         className={"mlp_data_table_header"}
                                                         onClick={() => this.sortByColumn(header)} key={header.key}>
                                                <div
                                                    className={"mlp_data_fields_" + header.header}>{this.headerContent(header)}</div>
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.id}>
                                            {row.cells.map((cell) => {
                                                return this.cellContent(row, cell)
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DataTable></div>
            {this.renderPagination(totalItems)}
        </TableContainer>)
    }


}