import React from "react";
import User20 from "@carbon/icons-react/lib/user/20";

import {
    Header,
    HeaderGlobalAction,
    HeaderGlobalBar,
    HeaderMenuItem,
    HeaderName,
    HeaderNavigation
} from "carbon-components-react/lib/components/UIShell";

import './header.css'
import {ToastNotification} from "@carbon/react";
import {render} from "react-dom";
import {versionInfo} from "../components/Notifications";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import {isLoggedInUser} from "../index";
import MLPGoogleLogin from "../components/GoogleLogin";
import LocalLogin from "../components/LocalLogin";
import {getPermissions} from "../common/utils.js";

export default class MLPHeader extends React.Component {

    displayAbout() {
        const MsgWindow = () => (
            <div className={"mlp_ontop"}>
                <ToastNotification
                    kind={"info"}
                    subtitle={"Version: " + versionInfo() + " by DataGrid Software"}
                    timeout={5000}
                    title="Deep Insights Platform"
                />
            </div>

        );
        return render(<MsgWindow/>, document.getElementById('mlp_head_info'));
    }

    _withLogout(props) {
        if (props.withAccount) {
            const cookies = new Cookies();
            const jwtToken = cookies.get('MLP_JWT');
            if (isLoggedInUser()) {
                cookies.set('MLP_JWT', jwtToken, {path: '/', maxAge: 3600, sameSite: true});
                return (
                    <HeaderMenuItem
                        onClick={() => {
                            cookies.remove('MLP_JWT')
                            cookies.remove('PERMISSIONS')
                        }
                        }
                        href="/">Logout</HeaderMenuItem>)
            } else return (<div/>);

        }
        return (<div/>)
    }

    _withAccount() {
        if (isLoggedInUser()) {
            return (
                <HeaderGlobalAction aria-label="User" onClick={() => {
                    const cookies = new Cookies();
                    const jwtToken = cookies.get('MLP_JWT');
                    if (jwtToken !== undefined) {
                        const jwt = jwt_decode(jwtToken);
                        const MsgWindow = () => (
                            <div>
                                <ToastNotification
                                    statusIconDescription="Logged in user"
                                    kind={"info"}
                                    role={"status"}
                                    subtitle={"Welcome " + jwt.given_name + " (" + jwt.email + ")"}
                                    timeout={5000}
                                    title="Logged in user"
                                />
                            </div>

                        );
                        return render(<MsgWindow/>, document.getElementById('mlp_head_info'));
                    } else return false;
                }}><User20/></HeaderGlobalAction>
            )
        }
        return <b/>//(window.LOCAL_LOGIN) ? <LocalLogin/> : <MLPGoogleLogin/>

    }

    isAdmin = () => {
        const perms = getPermissions();
        if (perms === undefined || perms.admin === false) {
            return false
        }
        return perms.admin == true
    }

    render() {
        return (
            <div className="header_container">
                <div id={"notifications"}></div>

                <Header aria-label="DataGrid Deep Insights Platform">

                    <HeaderName href="/" prefix="[&copy;DataGrid Software]" className={"mlp_header_app_title"}>
                        Deep Insights Platform
                    </HeaderName>
                    <HeaderName prefix=""
                                className={"mlp_header_app_title"}>{this.props.subCat ? this.props.subCat : ""}</HeaderName>
                    <HeaderNavigation aria-label="Deep Insights Platform [&copy; DataGrid]"
                                      className="mlp_header_holder">
                        <HeaderMenuItem href="/main">Home</HeaderMenuItem>
                        {this._withUsers(this.props)}
                        {this._withDashboard()}
                        {this._withDatasets()}
                        <HeaderMenuItem onClick={this.displayAbout} href="#">About</HeaderMenuItem>
                        {this._withLogout(this.props)}
                    </HeaderNavigation>
                    <HeaderGlobalBar>
                        {this._withAccount(this.props)}
                    </HeaderGlobalBar>

                </Header>


                <div id="mlp_head_info"></div>
            </div>
        )
    }

    _withDashboard() {
        const perms = getPermissions();
        const canViewDashboard = (perms !== undefined && (perms.manage_models || perms.admin))
        return (isLoggedInUser() && canViewDashboard) ? (<HeaderMenuItem href="/mlops">Dashboard</HeaderMenuItem>) : (
            <b/>)
    }

    _withUsers() {
        return isLoggedInUser() && (this.isAdmin()) ? (<HeaderMenuItem href="/users">Users</HeaderMenuItem>) : (<b/>)
    }

    _withDatasets() {
        if (this.props.onDatasets !== undefined && this.props.withDatasets) {
            return <HeaderMenuItem onClick={this.props.onDatasets} href="#">Datasets</HeaderMenuItem>
        }
        return isLoggedInUser() ? (<HeaderMenuItem href="/datasets">Datasets</HeaderMenuItem>) : (<b/>)
    }

}