import React from "react";
import "./churn.css"
import "@carbon/charts/styles.css";

import {
    Loading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Toggletip,
    ToggletipButton,
    ToggletipContent
} from "@carbon/react";
import DatasetTable from "../datasets/DataTable";

import Information from "@carbon/icons-react/lib/information/16";
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from "carbon-components-react";
import { displayRequiredAction } from "../components/Notifications";
import { mlp_get, percent, SERVER_API_URL } from "../index";
import MLPTextWithMore from "../components/TextWithMore";


export default class MLPChurnPredict extends React.Component {

    LIMIT_PREDICTIONS = 1000;

    constructor(props, context) {
        super(props, context);
        this.jobId = props.meta.jobId()
        this.state = {
            churnPredictedHeaders: [
                { key: "customer", header: this._headerOf("customer") },
                { key: "num_transactions", header: this._headerOf("num_transactions") },
                { key: "tenure", header: this._headerOf("tenure") },
                { key: "spendings", header: this._headerOf("spendings") },
                { key: "churn", header: this._headerOf("churn") }
            ],
            churnPredictedData: null
        };
    }

    content = () => {
        return <div>
            <StructuredListWrapper>
                <StructuredListBody>
                    <StructuredListRow>
                        <StructuredListCell>
                            <div className={"mlp_left"}>
                                <div className={"mlp_churn_dataset_description"}>
                                    <MLPTextWithMore above={<div>
                                        <h6>The following list displays the churn prediction based
                                            on two Machine Learning models that were previously
                                            computed.</h6>
                                    </div>}>
                                        <h7><br />
                                            The question is <em>"which users are likely to churn in
                                                the next N months?"</em>. The AI determines if user
                                            is churn in the folloowing way:<br />
                                            <ul>
                                                <li> - From the cut-point we have N months interval
                                                    in the past to consider if a user is churned or
                                                    not.
                                                </li>
                                                <li> - A new data set is built containing customers
                                                    and information about those customers and
                                                    whether they churned or not relative to this
                                                    cut-point. This data is built fully
                                                    automatically.
                                                </li>
                                                <li> - Multiple machine learning models are trained
                                                    on this data and the platform evaluates the
                                                    quality of these model against that data that we
                                                    see after this cut point till the end of your
                                                    dataset. It is important to mention that that
                                                    data used for evaluating the models did not
                                                    participate in the training process of these
                                                    model.
                                                </li>
                                                <li> - Machine learning models generate specific
                                                    metrics that would show the models quality on
                                                    this data.
                                                </li>
                                                <li> - But at the end we need to be able to predict
                                                    which customers are likely to churn in the next
                                                    N months. These N months refer to the future
                                                    because this is data that doesn't exist yet.
                                                </li>
                                                <li> - To achieve this the platform is not using the
                                                    models trained above, but instead re-trains
                                                    those models on the entire churned data. In
                                                    other words the cut-point is the end of the
                                                    data. Even if we do not have now a seprate
                                                    dataset to evaluate the models against we still
                                                    apply certain machine learning technqiques (such
                                                    as cross validation) to assess the quality of
                                                    these final machine learning models.
                                                </li>
                                                <li> - Using these final models predictions can be
                                                    made on which customers are likely to churn in
                                                    the next N months and generate the data set
                                                    bellow to show these results.
                                                </li>
                                            </ul>
                                        </h7>
                                    </MLPTextWithMore>
                                    <br />
                                </div>
                            </div>
                        </StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                        <StructuredListCell>
                            {this._showPredictContent()}
                        </StructuredListCell>
                    </StructuredListRow>
                </StructuredListBody>
            </StructuredListWrapper>
        </div>
    }
    render() {
        if (this.props.view === true) {
            return this.content()
        }
        return (
            <div className={"mlp_churn_tabs_holder"}>
                <Tabs>
                    <TabList aria-label="List of tabs" light={true} iconSize={"lg"}
                        className={"mlp_churn_tabs_list_holder"}>
                        <Tab disabled className={"mlp_churn_tab_holder"}>1. Setup</Tab>
                        <Tab disabled className={"mlp_churn_tab_holder"}>2. Run</Tab>
                        <Tab className={"mlp_churn_tab_holder"}>3. Predict</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel />
                        <TabPanel />
                        <TabPanel>
                            {
                                this.content()
                            }
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        )
    }


    _showPredictContent() {
        if (this.state.churnPredictedData === null) {
            this._loadPredictedData();
            return (<div className={"mlp_center"}>
                <Loading description="loading prediction" withOverlay={false} />
            </div>)
        } else {
            return (<DatasetTable isSortable={true} rowsData={this.state.churnPredictedData}
                headersData={this.state.churnPredictedHeaders} />)
        }
    }

    toggletip(content) {
        return (<Toggletip className={"mlp_absolute mlp_padding_right_2_rem mlp_prediction_details_content"}
            align={"bottom"}>
            <ToggletipButton label="Additional information">
                <Information />
            </ToggletipButton>
            <ToggletipContent>{content}</ToggletipContent>
        </Toggletip>)
    }


    predictionToggletip(pred1, prob1, pred2, prob2) {
        return this.toggletip((<div>
            Prediction of XGBoost Classifier: {pred1 === 1 ? "CHURN" : "NOT CHURN"} with
            probability {percent(prob1)}<br />
            Prediction of Logistic Regression: {pred2 === 1 ? "CHURN" : "NOT CHURN"} with
            probability {percent(prob2)}<br />
        </div>))
    }


    _headerOf(header) {
        if (header === "customer") {
            return (<div>Customer &nbsp;{this.toggletip("Customer field information explanation")}</div>)
        } else if (header.startsWith("prediction")) {
            return (<div>Prediction &nbsp;{this.toggletip("Prediction field information explanation")}</div>)
        } else if (header.startsWith("spendings")) {
            return (<div>Spendings &nbsp;{this.toggletip("Spendings field information explanation")}</div>)
        } else if (header.startsWith("tenure")) {
            return (<div>Tenure &nbsp;{this.toggletip("Tenure field information explanation")}</div>)
        } else if (header.startsWith("num_transactions")) {
            return (<div>Number of
                transactions &nbsp;{this.toggletip("Number transactions field information explanation")}</div>)
        } else if (header.startsWith("churn")) {
            return (
                <div>Churn prediction &nbsp;{this.toggletip("Churn prediction field information explanation")}</div>)
        } else return header;
    }

    _churnLike(pred1, prob1, pred2, prob2) {
        const churnPred1 = (pred1 === 1 || pred1 === '1' || pred1 === true)
        const churnPred2 = (pred2 === 1 || pred2 === '1' || pred2 === true)
        const churnScore = 5 * pred1 * prob1 + 5 * pred2 * prob2;
        if (churnPred1 && churnPred2 && (prob1 > 0.5 || prob2 > 0.5)) {
            return (
                <h6 title={churnScore}
                    className={"mlp_color_red"}>Likely&nbsp;{this.predictionToggletip(pred1, prob1, pred2, prob2)}
                </h6>)
        } else if (churnPred1 || churnPred2) {
            return (<h6 title={churnScore}
                className={"mlp_color_orange"}>Normal&nbsp;{this.predictionToggletip(pred1, prob1, pred2, prob2)}
            </h6>)
        } else {
            return (
                <h6 title={churnScore}
                    className={"mlp_color_green"}>Unlikely&nbsp;{this.predictionToggletip(pred1, prob1, pred2, prob2)}
                </h6>)
        }
    }

    _loadPredictedData() {
        const pathToFetch = SERVER_API_URL + '/v1/jobs/' + this.jobId + "/outputs/churn_predictions.json"
        return mlp_get(pathToFetch)
            .then(async jsonData => {

                const uiRows = jsonData.data.slice(0, this.LIMIT_PREDICTIONS).map(row => {
                    return {
                        id: "row_of_" + row[0],
                        customer: row[0],
                        num_transactions: row[1],
                        tenure: row[2],
                        spendings: row[3],
                        churn: this._churnLike(row[4], row[5], row[6], row[7])
                    }
                })
                this.setState({
                    churnPredictedData: uiRows
                })
            }).catch(error => {
                displayRequiredAction("Fail to get churn predicted data. We will investigate this problem on our side")
            })
    }
}