import React from "react";

import {StructuredListBody, StructuredListWrapper} from "carbon-components-react/lib/components/StructuredList";
import {DonutChart, SimpleBarChart} from "@carbon/charts-react";
import "@carbon/charts/styles.css";
import {InlineLoading, Loading} from "@carbon/react";
import {ViewAdapter} from "../common/ViewAdapter";


export default class MLPChurnInsights extends React.Component {

    roles = this.props.meta.getRoles()
    schema = this.props.meta.getSchemaFields()
    jobDef = this.props.meta.jobJson
    jobId = this.jobDef.system.id

    viewAdapters = new ViewAdapter(/*this.jobDef.system.id*/)

    constructor(props) {
        super(props);
        this.state = {
            churnRatioView: null,
            churnTopCustBySales: null,
            churnTopCustByProducts: null,
            churnTopCustByTransactions: null
        }
    }

    componentWillUnmount() {
        this.viewAdapters.abort()
    }

    _showChurnRatio() {
        if (this.state.churnRatioView) {
            return (<div className={"mlp_churn_ratio_donut"}>
                <DonutChart data={this.state.churnRatioView} options={{
                    "title": "Churn ratio",
                    "resizable": false,
                    "donut": {
                        "center": {
                            "label": "Customers"
                        }
                    },
                    "height": "400px",
                    "width": "600px",
                }}></DonutChart></div>)
        } else {
            this.viewAdapters.registerViewListener('/v1/jobs/' + this.jobId + "/outputs/churn_ratio.json",
                (ratioView) => {
                    const churned = ratioView.series[0].values[0]
                    const notChurned = ratioView.series[0].values[1]
                    const churnRatioView = [
                        {
                            "group": "Churn",
                            "value": churned
                        },
                        {
                            "group": "Not Churn",
                            "value": notChurned
                        },
                    ]

                    this.setState({churnRatioView: churnRatioView})
                })
            return (<div className={"mlp_loading_graph_holder_600_400"}>
                <Loading description="loading churn ratio" withOverlay={false}/>
            </div>)
        }
    }

    _showChurnTopCustBy(byView, byDescription) {
        if (byView) {
            return (
                <div className={"mlp_churn_run_chart mlp_churn_run_right_chart"}>
                    <SimpleBarChart
                        data={byView}
                        options={{
                            "title": "Top 10 churn customers by " + byDescription,
                            "height": "400px",
                            "width": "600px",
                            "bars": {
                                "width": 20,
                                "maxWidth": 70
                            },
                            "axes": {
                                "left": {
                                    "mapsTo": "group",
                                    "title": "Customer identifier",
                                    "scaleType": "labels"
                                },
                                "bottom": {
                                    "title": byDescription,
                                    "mapsTo": "value"
                                }
                            },
                        }}></SimpleBarChart></div>
            )
        } else {
            this.viewAdapters.registerViewListener('/v1/jobs/' + this.jobId + "/outputs/top_churn.json",
                (jsonData) =>
                    this.setState({
                        churnTopCustBySales: this._viewForChart(jsonData[0], 1, 0),
                        churnTopCustByProducts: this._viewForChart(jsonData[1], 3, 0),
                        churnTopCustByTransactions: this._viewForChart(jsonData[2], 2, 0)
                    })
            )
            return (
                <div className={"mlp_loading_graph_holder_600_400"}><Loading description="loading top churn"
                                                                             withOverlay={false}/></div>)
        }
    }

    _viewForChart(chart, xIndex, yIndex) {
        let view = []
        const viewToShow = chart.series[yIndex].values.length
        for (let i = 0; i < viewToShow; i++) {
            const item = {
                "group": chart.series[yIndex].values[i].toString(),
                "value": chart.series[xIndex].values[i]
            }
            view.push(item)
        }
        return view.reverse()
    }


    render() {
        if (this.props.meta.isJobFailed()) {
            this.viewAdapters.abort();
            return (
                <div className={"mlp_center"}><InlineLoading
                    description="This churn insight failed on this setup. This will ring a bell on our end."
                    status='error'/></div>)
        } else return (
            <StructuredListWrapper>
                <StructuredListBody>
                    <div className={"mlp_grid_1_2 mlp_chart_grid"}>
                        <div>{this._showChurnRatio()}</div>
                        <div>{this._showChurnTopCustBy(this.state.churnTopCustBySales, "Sales volume")}</div>
                        <div>{this._showChurnTopCustBy(this.state.churnTopCustByProducts, "Number of Products")}</div>
                        <div>{this._showChurnTopCustBy(this.state.churnTopCustByTransactions, "Number of transactions")}</div>
                    </div>

                </StructuredListBody>
            </StructuredListWrapper>
        )
    }
}