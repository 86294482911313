import React from "react";
import {Meta} from "../common/Meta";

export class RiskMeta extends Meta {

    SUPPORTED_ROLES = [
        {
            role: "ignore",
            occurrences: "zero_or_more"
        },
        {
            role: "feature",
            occurrences: "one_or_more"
        },
        {
            role: "target",
            occurrences: "one"
        }
    ]

    defaultFieldRole = "feature"
    scenario = "risk"

}