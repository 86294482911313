import React from "react";
import { hasPermissions } from "../common/utils.js";


export default class Check extends React.Component {

    constructor(props, context) {
        super(props);
        this.perms = this.props.perms.split(",")
    }

    render() {
        return hasPermissions(this.perms) ? (<React.Fragment>{this.props.children}</React.Fragment>) : (<React.Fragment/>)
    }
}