import React from "react";

import {Tab, TabList, TabPanel, TabPanels, Tabs, TextInput} from '@carbon/react';

import "../churn/churn.css"
import "@carbon/charts/styles.css";
import MLPCard from "../components/Card";


export default class MLPSentimentsSetup extends React.Component {

    render() {
        return (
            <div className={"mlp_churn_tabs_holder"}>
                <Tabs>
                    <TabList aria-label="List of tabs" light={true} iconSize={"lg"}
                             className={"mlp_churn_tabs_list_holder"}>
                        <Tab className={"mlp_churn_tab_holder"}>1. Setup</Tab>
                        <Tab disabled className={"mlp_churn_tab_holder"}>2. Run</Tab>
                        {/*<Tab disabled className={"mlp_churn_tab_holder"}>3. Predict</Tab> */}

                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <div className={"mlp_risk_setup_tab"}>
                                <MLPCard isFocus={true} styleClass="mlp_card_info">
                                    <div className={"mlp_padding_1rem"}>
                                        <TextInput
                                            readOnly={this.props.meta.jobId() !== undefined}
                                            className={"mlp_padding_1rem"}
                                            ref={this.props.jobName}
                                            labelText={this.props.meta.jobId() !== undefined ? "Sentiments job name" : "Please provide a name for this job"}
                                            helperText=""
                                            id={"sentiments_job_name"}
                                            invalidText="You must provide a name for Sentiments job"
                                            placeholder={this.getJobName()}/>

                                    </div>
                                </MLPCard>
                            </div>
                        </TabPanel>
                        <TabPanel/>
                        <TabPanel/>
                    </TabPanels>
                </Tabs>
            </div>
        )
    }

    getJobName() {
        return this.props.jobName.current === null ? this.props.meta.jobName() : this.props.jobName.current.value
    }


}

