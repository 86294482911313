import React from "react";
import {isLoggedInUser, queryParams} from "../index";

import MLPHeader from "../header/Header";
import MLPFooter from "../footer/Footer";



export default class MLPPropensityPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (!isLoggedInUser()) {
            window.location.href = "/"
            return (<div/>)
        }

        return <div className="mlp_content_holder">
            <MLPHeader withAccount={true} subCat={"Propensity analysis"} />
            <div className="mlp_center">

            </div>
            <MLPFooter />
        </div>
    }
}