import React from "react";
import {
    Button,
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    Toggletip,
    ToggletipButton,
    ToggletipContent
} from "@carbon/react";
import {roundFloat, trimTo} from "../index";
import Information from "@carbon/icons-react/lib/information/16";


export default class DatasetSchemaInfo extends React.Component {

    headerData = [
        {
            key: 'name',
            header: 'Name',
        },
        {
            key: 'data_type',
            header: 'Type',
        },
        {
            key: 'stats',
            header: 'Statistics',
        }
    ];

    constructor(props, context) {
        super(props, context);
        this.hideControls = this.props.hideControls !== undefined && this.props.hideControls === true
        this.renderSchema = this.renderSchema.bind(this);
    }

    isReadOnly() {
        return (this.props.dataSet.system.author === "")
    }

    toggleTip(content) {
        return (<Toggletip className={"mlp_padding_right_2_rem mlp_prediction_details_content"}
                           align={"left"}>
            <ToggletipButton label="Additional information">
                <Information/>
            </ToggletipButton>
            <ToggletipContent>{content}</ToggletipContent>
        </Toggletip>)
    }

    valOrNA(val) {
        return val !== undefined && val !== null ? roundFloat(val, 3) : "NA"
    }

    renderSchema() {
        const rowData = this.props.dataSet.object.schema.fields.map(row => {
            row.id = row.name;
            const stats = (<div className={"mlp_grid_1_2 mlp_grid_1_2_nogap mlp_grid_1_2_medium"}>
                <div>Count:</div>
                <div>{this.valOrNA(row.summary_stats.count)}</div>
                <div>Count unique:</div>
                <div>{this.valOrNA(row.summary_stats.count_unique)}</div>
                <div>Count missing:</div>
                <div>{this.valOrNA(row.summary_stats.count_missing)}</div>
                <div>Mean:</div>
                <div>{this.valOrNA(row.summary_stats.mean)}</div>
                <div>Min:</div>
                <div>{this.valOrNA(row.summary_stats.min)}</div>
                <div>Std deviation:</div>
                <div>{this.valOrNA(row.summary_stats.std_dev)}</div>
            </div>)
            row.stats = this.toggleTip(stats)
            return row
        })

        return (
            <div className={"mlp_schema_table_scroll"}><DataTable rows={rowData} headers={this.headerData}>
                {({rows, headers, getHeaderProps, getTableProps}) => (
                    <Table {...getTableProps()}>
                        <TableHead>
                            <TableRow>
                                <TableHeader>Field Name</TableHeader>
                                <TableHeader>Field Type</TableHeader>
                                <TableHeader>Statistics</TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.cells.map((cell) => {
                                        return <TableCell key={cell.id}>{cell.value}</TableCell>
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </DataTable></div>)
    }

    renderControls() {
        if (!this.hideControls) {
            return (<div className={"mlp_datasets_actions"}>
                <Button size='sm' onClick={this.props.onClose}><p>Close</p></Button>&nbsp;&nbsp;&nbsp;
                <Button disabled={this.isReadOnly()} size='sm' kind={"secondary"} onClick={this.props.onEdit}>
                    <p>Edit</p></Button>
            </div>)
        } else return (<div/>)
    }

    render() {
        return (<div className={"mlp_dataset_modal_holder"}>
            <br/>
            <h4>Data schema</h4>
            <br/><br/>
            {this.renderSchema()}
            <br/>
            <b>Fields names and types were computed based on input data headers</b>
            <br/>
            <br/>
            {this.renderControls()}
        </div>)
    }
}
