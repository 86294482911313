import React from "react";

import './mlops.css'

import {mlp_patch, SERVER_API_URL} from "../index";

import ModelCard from "./ModelCard";
import {BsFillCaretDownFill, BsFillCaretUpFill} from 'react-icons/bs';


export default class ModelsPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showEditModel: false,
            editContent: undefined,
            items: []
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let res = prevState;
        res.items = nextProps.models;
        return res;
    }

    showEditModel = (content) => {
        this.setState({
            showEditModel: true,
            editContent: content
        })
        this.props.mlp_ops_parent.displayUploadModelBtn(false)
    }

    hideEditModel = () => {
        this.setState({
            showEditModel: false,
            editContent: undefined
        })
        this.props.mlp_ops_parent.displayUploadModelBtn(true)
    }


    deleteFromSourcePanel = (state) => {
        this.props.mlp_ops_parent.deleteModelFromSource(state, this.props.stage);
    }

    insertToDestPanel = (pos, state) => {

        this.props.mlp_ops_parent.insertModelToDest(state,
            pos,
            this.props.stage);

        const update = [
            {"op": "replace", "path": "/mlops/stage", "value": this.props.stage},
        ];

        mlp_patch(SERVER_API_URL + '/v1/models/' + state.model.system.id, update)
            .then(jsonData => {
                this.props.mlp_ops_parent.modelUpdated()
            })

    }


    renderModels = () => {
        if (this.state.showEditModel) {
            return this.state.editContent
        } else {
            return <div>
                <div className="mlp_ops_model_panel_title">
                </div>
                {
                    this.state.items.map((st, i) => {
                        return <ModelCard model={st.model}
                                          job={st.job}
                                          index={i}
                                          deleteFromSourcePanel={this.deleteFromSourcePanel}
                                          panel={this}
                                          showEditModel={this.showEditModel}
                                          hideEditModel={this.hideEditModel}
                                          mlp_ops_parent={this.props.mlp_ops_parent}
                                          key={st.model.system.id}/>

                    })
                }
            </div>
        }
    }

    render() {
        if (this.state.items == undefined || this.state.items.length == 0) {
            return (
                <div className={"mlp_center mlp_padding_1rem"}>You do not have any ML Models train. Please run a usecase
                    in order to train a model on
                    your data</div>)
        } else
            return (
                <React.Fragment>
                    {this.renderModels()}
                </React.Fragment>
            )
    }
}