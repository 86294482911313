import React from "react";

import './datasets.css'
import MLPHeader from "../header/Header";
import MLPFooter from "../footer/Footer";

import '../main/main.css'
import Datasets from "./DataSets";
import { getPermissions } from "../common/utils";


export default class DatasetsPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="mlp_content_holder">
                <MLPHeader withAccount={true} subCat={"Datasets"} />
                <br />
                <div className="mlp_center">
                    <div className="mlp_datasets_holder">
                        <Datasets onDataSet={this.props.onDataSet} />
                    </div>
                </div>
                <MLPFooter />
            </div>
        )
    }
}
