import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import './common.css'

import MLPHomePage from "./home/Home";
import MLPAppPage from "./main/Main";
import MLOpsPage from "./mlops/MLOps";
import DatasetsPage from "./datasets/DatasetsPage";
import UsersPage from "./users/users";
import MLPChurnPage from "./churn/Churn";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import MLPCustomPage from "./custom/Custom";
import MLPRiskPage from "./risk/Risk"
import MLPSentimentsPage from "./sentiments/Sentiments";
import ReactGA from 'react-ga4';
import MLPPropensityPage from "./propensity/Propensity";

export const SERVER_API_URL = window.REACT_APP_API_URL || ""

export function uuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function trimTo(length, value) {
    if (value === undefined) {
        return ""
    }
    let ret = String(value)
    if (ret.length - 2 > length) {
        return ret.substring(0, length) + "..."
    }

    return ret
}

export function isFloat(n, dec) {
    return n === +n && n !== (n | 0);
}

export function valueOrNA(val) {
    return val !== undefined && val !== null ? val : "N/A"
}


export function roundFloat(val, dec) {
    return isFloat(val) ? val.toFixed(dec) : val;
}

export function percent(val) {
    return "" + Math.round(val * 100) + "%"
}

export function mlp_delete(url) {

    const options = {
        method: 'DELETE',
        headers: {}
    }
    const token = jwtToken()
    if (token) {
        options.headers.Authorization = 'Bearer ' + token
    }

    return fetch(url, options).then(handle_401)
}

export function mlp_patch(url, body) {

    const options = {
        method: 'PATCH',
        headers: {},
        body: JSON.stringify(body)
    }
    const token = jwtToken()
    if (token) {
        options.headers.Authorization = 'Bearer ' + token
    }

    return fetch(url, options).then(handle_401)
}

export function mlp_post(url, body) {

    const options = {
        method: 'POST',
        headers: {},
        body: JSON.stringify(body)
    }
    const token = jwtToken()
    if (token) {
        options.headers.Authorization = 'Bearer ' + token
    }

    return fetch(url, options).then(handle_401)
}

export function mlp_put(url, body) {

    const options = {
        method: 'PUT',
        headers: {},
        body: JSON.stringify(body)
    }
    const token = jwtToken()
    if (token) {
        options.headers.Authorization = 'Bearer ' + token
    }

    return fetch(url, options).then(handle_401)
}

async function handle_401(resp) {
    if (resp.status === 204) {
        return {}
    }

    const json = await resp.json();
    if (resp.status === 401) {
        if (json.code !== undefined && json.code === "err_auth_permissions") {
            return json
        }
        const cookies = new Cookies();
        cookies.remove('MLP_JWT')
        cookies.remove("PERMISSIONS")
        cookies.remove("USER")
        window.location.href = "/"
    }
    return json
}

export function mlp_get(input, includePublic = true) {
    let url = input
    const options = {
        method: 'GET',
        headers: {}
    }
    const token = jwtToken()
    if (token) {
        options.headers.Authorization = 'Bearer ' + token
    }
    if (includePublic) {
        url = url + (url.includes("?") ? "&include_public=true" : "?include_public=true")
    }
    return fetch(url, options).then(handle_401)
}

export function mlp_get_options(input, options) {
    if (!options.headers) {
        options.headers = {}
    }
    const token = jwtToken()
    if (token) {
        options.headers.Authorization = 'Bearer ' + token
    }
    return fetch(input, options).then(handle_401)
}


export function jwtToken() {
    const cookies = new Cookies();
    return cookies.get('MLP_JWT');
}

export function loggedInUserId() {
    const cookies = new Cookies();
    const jwtToken = cookies.get('MLP_JWT');
    if (jwtToken !== undefined) {
        const jwt = jwt_decode(jwtToken);
        return jwt.email;
    }
    return "anonymous"
}

export function isLoggedInUser() {
    const cookies = new Cookies();
    const jwtToken = cookies.get('MLP_JWT');
    if (jwtToken !== undefined) {
        const jwt = jwt_decode(jwtToken);
        return true
    }
    return false
}

export function handleRestError(jsonData) {
    if (jsonData.code !== undefined) {
        window.location.href = "/main";
    }
}

export function niceDate(uglyDate) {
    return uglyDate.replace("T", " ").slice(0, -5)
}

export function niceTimestamp(uglyDate) {
    return uglyDate.replace("T", " ").replace('Z','')
}

export function jsonPretty(json) {
    return JSON.stringify(json, undefined, 2)
}

export function queryParams() {
    let queryDict = {}
    window.location.search.substr(1).split("&").forEach(function (item) {
        queryDict[item.split("=")[0]] = item.split("=")[1]
    })
    return queryDict;
}

export class PrivateRoute extends React.Component {

    isAuthenticated() {
        const cookies = new Cookies();
        const jwtToken = cookies.get('MLP_JWT');
        return (jwtToken !== undefined) //TODO - validate token
    }

    render() {
        return this.isAuthenticated() ? <Outlet/> : <Navigate to="/"/>;
    }
}

export function copyArray(array) {
    if (array === undefined || array === null)
        return []

    let na = []
    array.forEach(e => {
        na.push(e)
    });
    return na
}

export default class App extends React.PureComponent {


    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/main" element={<MLPAppPage/>}/>
                    <Route path="/mlops" element={<MLOpsPage/>}/>
                    <Route path="/datasets" element={<DatasetsPage/>}/>
                    <Route path="/users" element={<UsersPage/>}/>
                    <Route path="/churn" element={<MLPChurnPage/>}/>
                    <Route path="/risk" element={<MLPRiskPage/>}/>
                    <Route path="/sentiments" element={<MLPSentimentsPage/>}/>
                    <Route path="/propensity" element={<MLPPropensityPage/>}/>
                    <Route path="/custom" element={<MLPCustomPage/>}/>
                    <Route path="*" element={<MLPHomePage/>}/>
                </Routes>
            </BrowserRouter>
        );
    }
}
ReactGA.initialize('G-QQT5QKCSVH');
ReactDOM.render(<App/>, document.getElementById("root"));
