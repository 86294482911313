import React from "react";
import Cookies from 'universal-cookie'
import { fetchPermissions, getPermissions } from "../common/utils.js";
import { Modal, TextInput } from "@carbon/react";
import Login from "@carbon/icons-react/lib/login/32";

export default class LocalLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openLogin: false
        };
        this.userName = React.createRef("")
        this.password = React.createRef("")
    }

    cookies = new Cookies();

    showLogin = (open) => {
        this.setState({
            openLogin: open
        });
        return false
    }

    ok = () => {
        this.showLogin(false)
    }

    render() {
        return <div className="mlp_login_form">
            <Login className="login_icon" onClick={() => this.showLogin(true)} />
            <Modal
                modalHeading="Login"
                open={this.state.openLogin}
                size="sm"
                onRequestClose={() => this.showLogin(false)}
                onRequestSubmit={this.ok}
                primaryButtonText="Login"
                secondaryButtonText="Cancel"
            >

                <TextInput
                    id="username"
                    labelText="User name"
                    type="text"
                    ref={this.userName}
                />

                <TextInput
                    id="pass"
                    labelText="Password"
                    type="password"
                    ref={this.password}
                />

            </Modal>
        </div>
    }

}
