import React from "react";

import MLPModelInfo from "../components/ModelInfo";
import { InlineLoading, Loading } from "@carbon/react";
import { ViewAdapter } from "../common/ViewAdapter";


export default class MLPRiskML extends React.Component {

    jobDef = this.props.meta.jobJson
    viewAdapters = new ViewAdapter()

    constructor(props) {
        super(props);
        this.setModels = this.setModels.bind(this);
        this.state = {
            modelActivityView: []
        }
    }

    setModels(models) {
        this.props.meta.setModels(models)
        this.setState({ modelActivityView: models })
    }

    render() {
        if (this.props.meta.isJobFailed()) {
            this.viewAdapters.abort();
            return (
                <div className={"mlp_center"}><InlineLoading
                    description="This risk insight failed on this setup. This will ring a bell on our end."
                    status='error' /></div>)
        } else if (this.props.meta.isJobRunning()) {
            const jobId = this.jobDef.system.id
            this.viewAdapters.registerViewListener('/v1/models?object.job_id=' + jobId,
                (view) => this.setModels(view.data.filter(model => model.object.job_id === jobId)), 5000)
            return (
                <div className={"mlp_center"}><Loading description="computing ML Models" withOverlay={false} /></div>)
        } else {
            const jobId = this.jobDef.system.id
            this.viewAdapters.registerViewListener('/v1/models?object.job_id=' + jobId,
                (view) => {
                    this.setModels(view.data.filter(model => model.object.job_id === jobId))
                    this.viewAdapters.abort();
                }, 5000)
            return (
                this.state.modelActivityView.map((model) => (
                    <MLPModelInfo model={model} />
                ))
            )
        }
    }
}