import React from "react";
import {DataTable, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow} from "@carbon/react";
import {AreaChart} from "@carbon/charts-react";
import {mlp_get, SERVER_API_URL, uuid} from "../index";

export default class MLPModelInfo extends React.Component {

    modelName = this.props.model.object.learner.name
    rocOptions = {
        "title": "ROC - Curve",
        "axes": {
            "left": {
                "stacked": false,
                "scaleType": "linear",
                "mapsTo": "truePositive"
            },
            "bottom": {
                "stacked": false,
                "scaleType": "linear",
                "mapsTo": "falsePositive"
            }
        },
        "curve": "curveCatmullRom",
        "height": "22rem",
        "width": "22rem"
    };

    MAX_ROC_POINTS = 20;
    modelUri = this.props.model.path ? this.props.model.path : "REST API available";
    metricsHeaders = [
        {
            key: 'name',
            header: 'Metric name',
        },
        {
            key: 'value',
            header: 'Metric value',
        }
    ];

    constructor(props, context) {
        super(props, context);
        this.state = {
            rocData: null
        };
    }


    componentDidMount() {
        const pathToFetch = SERVER_API_URL + '/v1/jobs/' + this.props.model.object.job_id + "/outputs/roc_curve-" + this.props.model.system.id + ".json"
        mlp_get(pathToFetch)
            .then(jsonData => {
                if (jsonData.id === "roc_curve") {
                    let rocs = [{
                        "group": this.modelName,
                        "falsePositive": 0.0,
                        "truePositive": 0.0,
                    }]
                    const rocsToShow = jsonData.series[0].values.length > this.MAX_ROC_POINTS ? this.MAX_ROC_POINTS : jsonData.series[0].values.length
                    const step = Math.round(jsonData.series[0].values.length / rocsToShow)
                    for (let i = 0; i < rocsToShow; i++) {
                        const idx = i * step
                        if (idx < jsonData.series[0].values.length) {
                            const roc = {
                                "group": this.modelName,
                                "falsePositive": jsonData.series[1].values[idx],
                                "truePositive": jsonData.series[0].values[idx],
                            }
                            rocs.push(roc)
                        }
                    }
                    rocs.push({
                        "group": this.modelName,
                        "falsePositive": 1,
                        "truePositive": 1,
                    })
                    this.setState({rocData: rocs})
                }
            })
    }

    metricsData() {
        let metricsData = []//this.props.model.metrics ? this.props.model.metrics : [];
        const metrics = this.props.model.object.metrics
        if (metrics && metrics.measurements) {
            Object.keys(metrics.measurements).forEach(function (key) {
                metricsData.push({
                    "id": uuid(),
                    "name": key,
                    "value": metrics.measurements[key]
                })
            })
        }
        return metricsData

    }

    _showROC() {
        return this.state.rocData ? (<AreaChart data={this.state.rocData} options={this.rocOptions}/>) : (<div/>)
    }

    render() {
        return (
            <div className={"mlp_model_info_holder"}>
                <div className={"mlp_model_info_line_holder"}>
                    <p>Model name: </p><h6>{this.modelName}</h6>
                    <div></div>
                </div>
                <div className={"mlp_model_info_line_holder"}>
                    <p>Model path:</p><h6>{this.modelUri}</h6>
                    <div></div>
                </div>
                <div className={"mlp_model_info_line_holder"}>
                    <p>Model metrics:</p>
                    <div className={"mlp_model_info_metrics_holder"}>
                        <DataTable rows={this.metricsData()} headers={this.metricsHeaders}>
                            {({rows, headers, getHeaderProps, getTableProps}) => (
                                <TableContainer>
                                    <Table {...getTableProps()}>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map((header) => (
                                                    <TableHeader {...getHeaderProps({header})}>{header.header}</TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow key={row.id}>
                                                    {row.cells.map((cell) => (
                                                        <TableCell key={cell.id}>{cell.value}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </DataTable></div>
                    <div>
                        <div className={"mlp_center"}>
                            {this._showROC()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}