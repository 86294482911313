import React from "react";

export class Meta {

    SUPPORTED_ROLES = []
    scenario = undefined
    defaultFieldRole = "feature";
    fieldsRoles = new Map();
    dataSourceJson = null;
    jobJson = null;
    models = [];

    constructor() {
        this.onRoleFieldChange = this.onRoleFieldChange.bind(this);
        this.setDataSource = this.setDataSource.bind(this);
        this.getSchemaFields = this.getSchemaFields.bind(this);
    }

    setSharing(s) {
        this.jobJson.object.sharing = s
    }

    getSharing() {
        if (this.jobJson === null || this.jobJson.object.sharing === null)
            return []
        return this.jobJson.object.sharing
    }

    jobName() {
        return this.jobJson ? this.jobJson.object.name : "";
    }

    jobId() {
        return this.jobJson ? this.jobJson.system.id : undefined;
    }

    datasetId() {
        return this.jobJson ? this.jobJson.object.dataset_id : undefined;
    }

    setDataSource(dataSourceJson) {
        this.dataSourceJson = dataSourceJson
    }

    setJob(jobJson) {
        this.jobJson = jobJson
    }

    isJobRunning() {
        return this.jobJson && (this.jobJson.system.status === "pending" || this.jobJson.system.status === "running")
    }

    isJobFailed() {
        return this.jobJson && (this.jobJson.system.status === "failed")
    }

    getSchemaFields() {
        return this.dataSourceJson === null ? [] : this.dataSourceJson.object.schema.fields.map(row => {
            row.id = row.name;
            row.role = this.getRoles().find(r => r.name === row.name).role
            return row
        })
    }

    onRoleFieldChange(field, event) {
        const fieldName = field.id.replace(":role", "")
        const fieldRole = event.selectedItem
        this.fieldsRoles.set(fieldName, fieldRole)
    }

    setModels(models) {
        this.models = models
    }

    getModels() {
        return this.models.map(model => {
            model.key = model.system.id;
            return model
        });
    }

    getRoles() {
        const jobRoles = new Map()
        if (this.jobJson && this.jobJson.object.roles) {
            this.jobJson.object.roles.map(role => {
                jobRoles.set(role.name, role.role)
                return null;
            })
        }

        return this.dataSourceJson != undefined ? this.dataSourceJson.object.schema.fields.map(field => {
            const fieldName = field.name
            const fieldRole = this.fieldsRoles.has(fieldName) ? this.fieldsRoles.get(fieldName) :
                (jobRoles.has(fieldName) ? jobRoles.get(fieldName) : this.defaultFieldRole);
            return {"name": field.name, "role": fieldRole};
        }) : []
    }


    validateRoles() {
        let ret = {
            isValid: true
        }
        const selectedRoles = this.getRoles().map(r => r.role)
        this.SUPPORTED_ROLES.map(supportedRole => {
            if (supportedRole.occurrences === "one" || supportedRole.occurrences === "one_or_more") {
                if (!selectedRoles.includes(supportedRole.role)) {
                    ret = {
                        isValid: false,
                        message: (<div className={"mlp_center"}>Please select one <h6
                            className={"mlp_text_bold"}>&nbsp;{supportedRole.role}&nbsp;</h6> role for your data</div>)
                    }
                } else if (supportedRole.occurrences === "one" && selectedRoles.filter(e => e == supportedRole.role).length > 1) {
                    ret = {
                        isValid: false,
                        message: (<div className={"mlp_center"}>No more that one role of <h6
                            className={"mlp_text_bold"}>&nbsp;{supportedRole.role}&nbsp;</h6>should be selected</div>)
                    }
                }
            }
            return true
        })
        return ret
    }
}