import React from "react";

import './components.css'
import {
    Accordion,
    AccordionItem,
    Button,
    Checkbox,
    CodeSnippet,
    DataTable,
    Loading,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    TextInput
} from "@carbon/react";

import {isLoggedInUser, mlp_get, mlp_get_options, roundFloat, SERVER_API_URL, trimTo} from "../index";
import {displayWarnAction} from "./Notifications";
import MLPGoogleAnalytics from "./GA";
import {InlineLoading} from "carbon-components-react";


export default class MLPPredict extends React.Component {

    ga = new MLPGoogleAnalytics();

    predictedHeaderData = [
        {
            key: 'name',
            header: 'Model name',
        },
        {
            key: 'accuracy',
            header: 'Probability',
        },
        {
            key: 'label',
            header: 'Predicted value',
        },
    ];

    schemaHeaderData = [
        {
            key: 'name',
            header: 'Field',
        },
        {
            key: 'data_type',
            header: 'Type',
        },
        {
            key: 'role',
            header: 'Role',
        },
        {
            key: 'action',
            header: 'Value',
        },
    ];

    constructor(props, context) {
        super(props, context);
        this.state = {
            renderModels: false,
            predicted: undefined,
            rest: undefined,
            sample: {}
        };
        this.inputSchema = props.meta.getSchemaFields().filter(r => r.role !== "target")
        this.inputRefs = new Map()

        this._predict = this._predict.bind(this);
        this.monitorModelsDeployment = this.monitorModelsDeployment.bind(this);

        this.undeployModel = this.undeployModel.bind(this);
        this.deployModel = this.deployModel.bind(this);

        this.readDataSamples()
        this.monitorModelsDeployment()
        this.monitorModels = false
    }

    readDataSamples() {
        const pathToFetchSamples = SERVER_API_URL + '/v1/datasets/' + this.props.meta.datasetId() + "/outputs/sample.json"
        mlp_get(pathToFetchSamples)
            .then(sampleData => {
                let index = 0
                const oneSample = {}
                sampleData.headers.map(header =>
                    oneSample[header] = sampleData.data[0][index++])
                this.setState({sample: oneSample})
            })
    }

    _isDeployed() {
        return this.props.meta.getModels().find(model => model.system.status === "ready") !== undefined
    }

    deployModel(model) {
        if (this.props.meta.isJobRunning()) {
            displayWarnAction("Please allow more time for this analysis in order to be able to use predictions...")
            return;
        }
        this.ga.undeploy(this.props.meta.scenario)
        const deployPayload = [
            {"op": "replace", "path": "/prediction_info/path", "value": model.system.id},
            {"op": "replace", "path": "/prediction_info/enabled", "value": true},
            {
                "op": "replace", "path": "/prediction_info/lifetime", "value": {
                    "value": 5,
                    "unit": "minutes"
                }
            }
        ]
        return this._patchModel(deployPayload, model.system.id).then(r => this.monitorModelsDeployment()).then(r => {
            this.setState({
                renderModels: false
            })
        })
    }

    undeployModel(model) {
        if (this.props.meta.isJobRunning()) {
            displayWarnAction("Please allow more time for this analysis in order to be able to use predictions...")
            return;
        }
        this.ga.undeploy(this.props.meta.scenario)

        const deployPayload = [
            {"op": "replace", "path": "/prediction_info/enabled", "value": false}
        ]
        return this._patchModel(deployPayload, model.system.id).then(r => this.monitorModelsDeployment()).then(r => {
            this.setState({
                renderModels: false
            })
        })
    }

    _patchModel(deployPayload, modelId) {
        const pathToFetch = SERVER_API_URL + '/v1/models/' + modelId
        return mlp_get_options(pathToFetch, {method: 'PATCH', body: JSON.stringify(deployPayload)})
            .then(jsonData => {
                return jsonData
            })
    }

    monitorModelsDeployment(count = 0) {
        const pathToFetch = SERVER_API_URL + '/v1/models?object.job_id=' + this.props.meta.jobId()
        mlp_get(pathToFetch)
            .then(async jsonData => {
                this.props.meta.setModels(jsonData.data)
                this.setState({
                    renderModels: true
                })
                this.props.meta.getModels().map(model => {
                    if (this.monitorModels === false && model.system.status === "pending" && model.object.prediction_info.enabled === true && count < 100) {
                        this.monitorModels = true
                        return new Promise(r => setTimeout(r, 3000)).then(r => {
                                this.monitorModels = false
                                this.monitorModelsDeployment(count + 1)
                            }
                        )
                    }
                })
            })
    }


    modelDeploymentStatus(model) {
        if (model.system.status === "pending") {
            if (model.object.prediction_info.enabled === true) {
                return (<div className={"mlp_deploy_btn"}><InlineLoading
                    description={"Enabling predictions..."}></InlineLoading>
                </div>)
            } else {
                return isLoggedInUser() ? (
                    <div className={"mlp_deploy_btn"}>
                        <Button disabled={false} kind={"primary"} size={"sm"} onClick={() => this.deployModel(model)}>
                            <p>Enable predictions</p>
                        </Button></div>) : (<h6>Disabled</h6>)
            }
        } else if (model.system.status === "ready") {
            if (model.object.prediction_info.enabled === false) {
                return (<h6>Disabled</h6>)
            } else {
                return isLoggedInUser() ? (
                    <div className={"mlp_deploy_btn"}>
                        <Button disabled={false} kind={"secondary"} size={"sm"}
                                onClick={() => this.undeployModel(model)}>
                            <p>Disable predictions</p>
                        </Button></div>) : (<h6>Disabled</h6>)
            }
        } else {
            return (<h6>Disabled</h6>)
        }
    }

    _renderModelsInfo() {
        const models = this.props.meta.getModels()
        if (models.length > 0) {
            return (
                <div className={"mlp_padding_1rem"}>
                    <div className={"mlp_pred_models_parent"}>
                        <div>
                            <div className={"mlp_pred_models_holder mlp_header_colors"}>
                                <div>&nbsp;</div>
                                <div>Model Name</div>
                                <div>Accuracy</div>
                                <div>Predictor status</div>
                            </div>

                            {models.map(model => {
                                return (<div key={model.system.id} className={"mlp_pred_models_holder"}>
                                    <Checkbox id={"check-" + model.system.id} checked={true} disabled={true}
                                              labelText=""/>
                                    <h6>{model.object.name}</h6>
                                    <div>{"with precision: " + roundFloat(model.object.metrics.measurements.precision * 100, 0) + "%"}</div>
                                    {this.modelDeploymentStatus(model)}
                                </div>)
                            })}</div>
                        <div className={"mlp_pred_description"}>After building a prediction and reviewing its scorecard,
                            you decide whether to enable it.
                            Deep Insights Platform gives you a certain number of total predictions and enabled
                            predictions.
                            Disabling predictions frees up space and saves prediction settings for later use.
                        </div>
                    </div>
                </div>
            )
        } else
            return (<div/>)
    }


    _renderSchemaDataCell(cell, row) {
        if (cell.id.endsWith(':action')) {
            const inputRef = React.createRef()
            this.inputRefs.set(row.cells[0].value, inputRef)
            const key = row.cells[0].value
            const sample = this.state.sample.hasOwnProperty(key) ? this.state.sample[key] : ""
            return (<TableCell key={"id_predict_input_" + key}><TextInput
                defaultValue={sample}
                ref={inputRef}
                id={"predict_input_" + key}
                placeholder={"empty"}
                invalidText="A valid value is required" labelText={""}/></TableCell>)
        } else {
            return (<TableCell key={cell.id}>{cell.value}</TableCell>)
        }
    }

    _predictionProbability(prediction) {
        if (prediction.error !== undefined) {
            return (<b className="mlp_color_red">N/A</b>)
        }
        const probabilities = prediction.probabilities[0]
        return roundFloat(Math.max(...probabilities) * 100, 0) + "%"
    }

    _predictionLabel(prediction) {
        if (prediction.error !== undefined) {
            return (<b className="mlp_color_red">{prediction.error}</b>)
        }
        return (
            <h6 title={prediction.values[0]} className="mlp_color_blue bold">{trimTo(20, prediction.values[0])}</h6>)
    }

    predict() {
        this.ga.predict(this.props.meta.scenario)
        this.setState({
                predicted: []
            }
        )

        const allPredictions = Promise.all(this.props.meta.getModels().filter(model => (model.system.status === "ready")).map(model => {
                return this._predict(model)
            }
        ).flat())
        allPredictions.then(predictions => {
            this.setState({
                predicted: predictions.map(res => res.predictions),
                rest: predictions.map(res => res.rest)
            })
        })
    }

    _predict(model) {
        const toPredict = Array.from(this.inputRefs, ([key, value]) => {
            const fieldInfo = this.inputSchema.find(e => e.name === key).data_type
            let val = value.current.value
            if (fieldInfo === "int" || fieldInfo === "float" || fieldInfo === "double") {
                val = parseFloat(value.current.value)
                if (isNaN(val)) val = null
            }
            return val
        })

        const predJson = {
            "features": [
                toPredict
            ]
        }

        const pathToFetch = SERVER_API_URL + '/v1/predictions/~/' + model.object.prediction_info.path
        return mlp_get_options(pathToFetch, {method: "POST", body: JSON.stringify(predJson)})
            .then(jsonData => {
                const predictedValues = jsonData.predictions.map(prediction => {
                    return {
                        id: model.system.id,
                        name: model.object.name,
                        accuracy: this._predictionProbability(prediction),
                        label: this._predictionLabel(prediction),
                    }
                })

                return {
                    "predictions": predictedValues[0],
                    "rest": {
                        "payload": JSON.stringify(predJson),
                        "path": model.object.prediction_info.path
                    }
                }
            }).catch(error => {
                    return {
                        "predictions": undefined,
                        "rest": undefined
                    }
                }
            )
    }

    _renderPredictionData() {
        if (this.state.predicted === undefined) {
            return (<div/>)
        } else if (this.state.predicted.length === 0) {
            return (<Loading description="loading prediction" withOverlay={true}/>)
        } else {
            return (
                <AccordionItem className={"mlp_predict_results_holder mlp_churn_accordion_insights"} title="Predictions"
                               open={true}>

                    <div className={"mlp_pred_predictions_parent"}>

                        <DataTable rows={this.state.predicted} headers={this.predictedHeaderData}>
                            {({rows, headers, getHeaderProps, getTableProps}) => (
                                <Table {...getTableProps()}>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header) => (
                                                <TableHeader {...getHeaderProps({header})}>
                                                    <div
                                                        className={"mlp_predicted_field_" + header.key}>{header.header}</div>
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow key={"prediction_row_" + row.id}>
                                                {row.cells.map((cell) => {
                                                    return <TableCell
                                                        key={"prediction_of_" + cell.id}>{cell.value}</TableCell>
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                            )}
                        </DataTable>
                        <div className={"mlp_left mlp_code_snippet"}>
                            <h6>Note: 'Bulk mode' predictions on Deep Insights Platform are also available through REST
                                API for an easy integration with various customer platforms</h6><br/>
                            <CodeSnippet type="multi">{this.predictCurlSample()}</CodeSnippet>
                        </div>
                    </div>
                </AccordionItem>)
        }
    }

    predictCurlSample() {

        return (<div>{
            this.state.rest.map(rest => {
                return (<div> {">"} curl -X POST -H 'Authorization: Bearer <i>token</i>' \<br/>
                    &nbsp;&nbsp;{SERVER_API_URL + '/v1/predictions/~' + rest.path} \<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;-d '{rest.payload}'<br/><br/>
                </div>)
            })
        }</div>)
    }

    canPredict() {
        return isLoggedInUser() && this._isDeployed()
    }

    render() {
        return (
            <div>
                {this._renderModelsInfo()}
                <Accordion>
                    <AccordionItem className={"mlp_predict_input_data_holder mlp_churn_accordion_insights"}
                                   disabled={!this._isDeployed()}
                                   title="Prediction Input Data" open={this._isDeployed()}>
                        <DataTable rows={this.inputSchema} headers={this.schemaHeaderData}>
                            {({rows, headers, getHeaderProps, getTableProps}) => (
                                <Table {...getTableProps()}>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header) => (
                                                <TableHeader {...getHeaderProps({header})}>
                                                    <div
                                                        className={"mlp_schema_field_" + header.key}>{header.header}</div>
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow key={row.id}>
                                                {row.cells.map((cell) => {
                                                    return this._renderSchemaDataCell(cell, row);
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                            )}
                        </DataTable>
                        <br/>
                        <Button disabled={!this.canPredict()} kind={"primary"} size={"lg"}
                                onClick={() => this.predict()}><p>Predict</p></Button>
                    </AccordionItem>
                    {this._renderPredictionData()}
                </Accordion>
            </div>
        )

    }
}