import React, { Fragment } from "react";

import './mlops.css'

import { Modal, TextArea, RadioButtonGroup, RadioButton } from "@carbon/react";

import { niceDate } from "../index";


export default class ApproveModel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            newLabel: "",
            model: this.props.model
        }

        this.approval = this.props.model.object.mlops.approval
        this.comment = React.createRef(this.props.model.object.mlops.comment);
    }

    ok = () => {
        this.props.approve({
            approval: this.approval,
            comment: this.comment.current.value
        });
    }


    renderReviewer = () => {
        if (this.state.model.object.mlops.reviewer != "") {
            return <div>
                <p><b className="mlp_text_bold">Reviewer:</b> {this.state.model.object.mlops.reviewer}</p>

            </div>
        }
        return <div />
    }

    radioChange = (e) => {
        this.approval = e      
    }
    
    render() {

        return <Modal
            key={this.state.model.system.id + "-approvals"}
            modalHeading={this.state.model.object.name}
            open={this.props.open}
            size="xs"
            onRequestClose={this.props.close}
            onRequestSubmit={this.ok}
            primaryButtonText="Save"
            secondaryButtonText="Cancel">

            <RadioButtonGroup
                id="approvals"
                defaultSelected={this.approval}
                name={"approvalradiongroup-" + this.state.model.system.id}
                orientation='vertical'
                onChange={this.radioChange}>

                <RadioButton
                    labelText="Approve"
                    value="approved"
                />
                <RadioButton
                    labelText="Reject"
                    value="rejected"
                />
                <RadioButton
                    labelText="None"
                    value="none"
                />
            </RadioButtonGroup>

            <div className="text_field">
                <TextArea
                    id="model_name"
                    labelText="Comment"
                    type="text"
                    defaultValue={this.state.model.object.mlops.comment}
                    ref={this.comment}
                />
            </div>
            {
                this.renderReviewer()
            }
        </Modal>
    }
}