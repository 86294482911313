import React from "react";
import {
    StructuredListBody,
    StructuredListRow,
    StructuredListWrapper
} from "carbon-components-react/lib/components/StructuredList";
import "@carbon/charts/styles.css";
import {InlineLoading, Loading} from "@carbon/react";
import '../churn/churn.css'
import './risk.css'
import {DonutChart, HeatmapChart} from "@carbon/charts-react";
import {ViewAdapter} from "../common/ViewAdapter";


export default class MLPRiskInsights extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            riskRatioView: null,
            correlationView: null
        }

        this.schema = this.props.meta.getSchemaFields()
        this.jobDef = this.props.meta.jobJson

        this.viewAdapters = new ViewAdapter(/*this.jobDef.system.id*/)
    }

    componentWillUnmount() {
        this.viewAdapters.abort()
    }

    _showRiskRatio() {
        if (this.state.riskRatioView) {
            return (<div className={"mlp_churn_ratio_donut"}>
                <DonutChart data={this.state.riskRatioView} options={{
                    "title": "Risk ratio",
                    "resizable": false,
                    "donut": {
                        "center": {
                            "label": "Records"
                        }
                    },
                    "height": "400px",
                    "width": "600px",
                }}></DonutChart></div>)
        } else {
            this.viewAdapters.registerViewListener('/v1/jobs/' + this.jobDef.system.id + "/outputs/risk_ratio.json",
                (ratioView) => {
                    const risk = ratioView.series[0].values[0]
                    const notRisk = ratioView.series[0].values[1]
                    const riskRatioView = [
                        {
                            "group": "At risk",
                            "value": risk
                        },
                        {
                            "group": "Not at Risk",
                            "value": notRisk
                        },
                    ]
                    this.setState({riskRatioView: riskRatioView})
                })
            return (
                <div className={"mlp_loading_graph_holder_600_400"}><Loading description="loading risk ratio"
                                                                             withOverlay={false}/>
                </div>)
        }
    }

    _showCorrelation() {
        if (this.state.correlationView) {
            return (<div className={"mlp_churn_ratio_donut"}>
                <HeatmapChart
                    data={this.state.correlationView}
                    options={{
                        "title": "Risk factors correlation map",
                        "axes": {
                            "bottom": {
                                "title": "Factor",
                                "mapsTo": "x",
                                "scaleType": "labels"
                            },
                            "left": {
                                "title": "Factor",
                                "mapsTo": "y",
                                "scaleType": "labels"
                            }
                        },
                        "heatmap": {
                            "colorLegend": {
                                "title": "Percent correlation"
                            }
                        },
                        "experimental": true,
                        "width": "640px",
                        "height": "450px"
                    }}>
                </HeatmapChart></div>)
        } else {
            this.viewAdapters.registerViewListener('/v1/jobs/' + this.jobDef.system.id + "/outputs/correlations.json",
                (view) => {
                    let riskCorrelationView = []
                    const feats = view.headers
                    const data = view.data
                    const idx = feats.length - 1
                    for (let i = 0; i < feats.length; i++) {
                        for (let j = 0; j < feats.length; j++) {
                            riskCorrelationView.push({
                                "x": feats[i],
                                "y": feats[idx - j],
                                "value": data[i][idx - j]
                            })
                        }
                    }

                    this.setState({correlationView: riskCorrelationView})
                })
            return (
                <div className={"mlp_loading_graph_holder_600_400"}><Loading description="loading risk correlation"
                                                                             withOverlay={false}/>
                </div>)
        }
    }

    render() {
        if (this.props.meta.isJobFailed()) {
            return (
                <div className={"mlp_center"}><InlineLoading
                    description="This risk insight failed with this setup. This will ring a bell on our end."
                    status='error'/></div>
            )
        } else return (
            <StructuredListWrapper>
                <StructuredListBody>
                    <StructuredListRow>
                        <div className={"mlp_grid_1_2 mlp_chart_grid"}>
                            <div>{this._showRiskRatio()}</div>
                            <div>{this._showCorrelation()}</div>
                        </div>
                    </StructuredListRow>
                </StructuredListBody>
            </StructuredListWrapper>
        )
    }
}