import React from "react";
import { mlp_get, SERVER_API_URL, trimTo, uuid } from "../index";
import { Loading, Tag } from "@carbon/react";
import DatasetTable from "./DataTable";


export default class DataSample extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.renderSample = this.renderSample.bind(this);
        this.state = {
            [props.dataSet.system.id]: undefined
        };

    }

    renderSample() {
        const sampleData = this.state[this.props.dataSet.system.id]
        if (sampleData === undefined) {
            const pathToFetchSamples = SERVER_API_URL + '/v1/datasets/' + this.props.dataSet.system.id + "/outputs/sample.json"
            mlp_get(pathToFetchSamples)
                .then(sampleData => this.setState({
                    [this.props.dataSet.system.id]: sampleData
                }))
            return (
                <div className={"mlp_center"}><Loading description="Loading sample information" withOverlay={false} />
                </div>)
        } else if (sampleData.code !== undefined) {
            return (
                <Tag key={this.props.dataSet.system.id} type="blue" title={"unavailable"}>Unavailable sample data</Tag>)
        } else {
            const sampleHeadersData = sampleData.headers.map(header => {
                return {
                    id: "sample_header_" + uuid(),
                    key: header,
                    header: this._headerOf(header)
                }
            })

            const samplesData = sampleData.data.map(sample => {
                let item = { id: "sample_" + uuid() }
                let sampleIndex = 0
                sampleData.headers.map(header => {
                    item[header] = trimTo(15, sample[sampleIndex++])
                })
                return item;
            })
            return (
                <div className="mlp-dataset-sample-holder">
                    <DatasetTable renderToolbar={false} pageSize={5} rowsData={samplesData}
                        headersData={sampleHeadersData} />
                </div>)
        }
    }

    _headerOf(header) {
        if (this.props.headerOf !== undefined) {
            return this.props.headerOf(header)
        }
        return trimTo(15, header)
    }

    render() {
        return (<div className={"mlp_dataset_modal_holder"}>


            <div className="mlp-dataset-section">
                {this.renderSample()}
            </div>



        </div>)
    }
}