import React, { Fragment } from "react";
import './mlops.css'
import { Modal, TextInput } from "@carbon/react";


export default class AddLabel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            newLabel: "",
            model: this.props.model
        }

        this.newLabel = React.createRef();
    }

    ok = () => {
        this.props.addLabel(this.newLabel.current.value);
    }
    

    render() {
        return <Modal
            modalHeading="Enable model"
            open={this.props.open}
            size="xs"
            onRequestClose={this.props.close}
            onRequestSubmit={this.ok}
            primaryButtonText="Add"
            secondaryButtonText="Cancel"
        >

            <TextInput
                id="add_label"
                labelText="Label"
                type="text"
                ref={this.newLabel}
            />

        </Modal>
    }
}