import React from "react";
import MLPHeader from "../header/Header";
import MLPFooter from "../footer/Footer";

import './mlops.css'

import {Button, Tab, TabList, TabPanel, TabPanels, Tabs} from "@carbon/react";
import {isLoggedInUser, mlp_get, SERVER_API_URL} from "../index";
import ModelsPanel from "./ModelsPanel";

import {Notification} from "../components/Notifications"
import UploadModel from "./UploadModel";

export default class MLOpsPage extends React.Component {

    onDatasets() {
        return false;
    }

    render() {

        return (
            <div className="mlp_content_holder">
                <MLPHeader withAccount={true}
                           subCat={"Machine Learning operations"}/>

                <br/>
                <div className="mlp_center"><MLOps/></div>
                <MLPFooter/>
            </div>
        )
    }
}

class MLOps extends React.Component {

    constructor(props, context) {
        super(props);
        this.fetchModels = this.fetchModels.bind(this);
        this.displayUploadModelBtn = this.displayUploadModelBtn.bind(this);

        this.state = {
            currentModelsTabIdx: 0,
            showUploadModel: false,
            modelDeleted: false,
            modelUpdated: false,
            models: new Map(),
        };

        this.isUserLogged = isLoggedInUser();
        this.fetchModels();

    }

    showUploadDialog = (open) => {
        this.setState({
            showUploadModel: open,
        })
    }

    async fetchJobs() {
        const pathToFetch = SERVER_API_URL + '/v1/jobs'
        return mlp_get(pathToFetch, false)
            .then(jsonData => {
                let jobs = new Map()
                for (let job of jsonData.data) {
                    jobs.set(job.system.id, job.object)
                }
                return jobs
            })
    }

    fetchModels() {
        this.fetchJobs().then(jobs => {
            const pathToFetch = SERVER_API_URL + '/v1/models'
            mlp_get(pathToFetch, false)
                .then(jsonData => {
                    let models = new Map()
                    models.set("default", []);
                    models.set("testing", []);
                    models.set("prod", []);

                    for (let m of jsonData.data) {
                        let stage = m.object.mlops.stage;

                        if (!models.has(stage)) {
                            stage = m.object.mlops.stage = "default"
                        }
                        let list = models.get(stage);
                        list.push({
                            job: jobs.get(m.object.job_id),
                            model: m
                        });
                    }
                    this.setState({
                        models: models
                    })

                });
        })
    }


    modelDeleted() {
        this.setState({
                modelDeleted: true,
            }, () =>
                setTimeout(() => {
                    this.setState({
                        modelDeleted: false,
                    })
                }, 3000)
        );
    }

    modelUpdated() {
        this.setState({
                modelUpdated: true,
            }, () =>
                setTimeout(() => {
                    this.setState({
                        modelUpdated: false,
                    })
                }, 3000)
        );
    }

    getTabIndex = (stage) => {
        switch (stage) {
            case "testing":
                return 1
            case "prod":
                return 2;
            default:
                return 0;
        }
    }

    updateModelStage = (model, oldStage) => {
        let m = this.state.models;

        let oldList = m.get(oldStage);
        let filtered = oldList.filter(e => {
            return e.model.system.id !== model.system.id
        });

        m.set(oldStage, filtered)
        let list = m.get(model.object.mlops.stage)
        list.push({
            model: model
        })


        this.setState({
            models: m,
            currentModelsTabIdx: this.getTabIndex(model.object.mlops.stage)
        })

    }

    updateModel = (model) => {
        let map = this.state.models;
        let stage = model.object.mlops.stage;
        if (stage === "") {
            stage = "default"
        }
        let list = map.get(stage);


        let updatedList = list.map(m => {
            if (m.model.system.id === model.system.id) {
                return {
                    job: m.job,
                    model: model
                };
            } else {
                return m;
            }
        });
        map.set(stage, updatedList);
        this.setState({
            models: map
        })
    }

    deleteModel = (uid, stage) => {

        let ms = this.state.models.get(stage);
        this.state.models.set(stage, ms.filter(m => m.model.system.id !== uid));
        this.setState({});
        this.modelDeleted()
    }

    deleteModelFromSource = (modelState, sourceStage) => {
        let map = this.state.models;
        let sourceList = map.get(sourceStage);

        sourceList = sourceList.filter(m => {
            return m.model.system.id !== modelState.model.system.id
        });

        map.set(sourceStage, sourceList);
        this.setState({
            models: map
        })
    }

    insertModelToDest = (modelState, pos, destStage) => {
        let map = this.state.models;

        let destList = map.get(destStage);
        modelState.model.object.mlops.stage = destStage;
        destList.splice(pos, 0, modelState);
        map.set(destStage, destList);
        this.setState({
            models: map
        })


    }

    stageModels = (stage) => {

        let list = this.state.models.get(stage);
        if (list == undefined) {
            return []
        }
        return list;
    }

    modelCreated = () => {
        this.showUploadDialog(false);
        this.fetchModels()
    }

    handleTabChange = (evt) => {
        this.setState({
            currentModelsTabIdx: evt.selectedIndex
        })
    }

    displayUploadModelBtn(enabled) {
        this.setState({
            displayUploadModelBtn: enabled
        })
    }

    renderBody = () => {
        if (this.state.showUploadModel) {
            return <UploadModel
                open={this.state.showUploadModel}
                close={e => this.showUploadDialog(false)}
                onUpload={this.modelCreated}
            />
        }

        return <div className="mlp_ops_content">
            <div className="mlp_ops_tools">
                <div className="mlp_ops_upload_model">
                    <Button disabled={this.state.displayUploadModelBtn == false}
                            kind={"primary"} onClick={() => this.showUploadDialog(true)}>Upload model</Button>
                </div>
            </div>
            <div className="mlp_center">
                <div className="mlp_models_list">
                    <Tabs selectedIndex={this.state.currentModelsTabIdx} onChange={this.handleTabChange}>
                        <TabList aria-label="List of tabs">
                            <Tab key={0}>Default</Tab>
                            <Tab key={1}>Testing</Tab>
                            <Tab key={2}>Production</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <ModelsPanel targetKey="stage" stage="default" models={this.stageModels("default")}
                                             mlp_ops_parent={this}></ModelsPanel>
                            </TabPanel>
                            <TabPanel>
                                <ModelsPanel targetKey="stage" stage="testing" models={this.stageModels("testing")}
                                             mlp_ops_parent={this}></ModelsPanel>
                            </TabPanel>
                            <TabPanel>
                                <ModelsPanel targetKey="stage" stage="prod" models={this.stageModels("prod")}
                                             mlp_ops_parent={this}></ModelsPanel>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </div>
            </div>
        </div>
    }

    render() {
        return (<div className="mlp_ops_holder" id="content_holder">
            <Notification
                text="Model deleted."
                notification={this.state.modelDeleted}/>
            <Notification
                text="Model updated."
                notification={this.state.modelUpdated}/>
            {this.renderBody()}
        </div>)
    }
}