import React from "react";

import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from 'carbon-components-react/lib/components/StructuredList';

import MLPCase from "../components/Case";
import './main.css'
import { RenderDescription } from "../components/CaseDetails";

import MLPUseCases from "./UseCases";
import { isLoggedInUser } from "../index";
import MLPGoogleAnalytics from "../components/GA";


export default class MLPLanding extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.isLoggedInUser = isLoggedInUser()
        this.newScenarioClick = this.newScenarioClick.bind(this);
        this.state = {
            infoOn: 'churn',
            viewJobId: undefined,
            viewJobType: undefined
        };
    }

    newScenarioClick(scenario) {
        if (this.isLoggedInUser) {
            return () => {
                (new MLPGoogleAnalytics()).newScenario(scenario)
                this.showCaseDetails(scenario)
                switch (scenario) {
                    case "churn":
                        window.location.href = "/churn";
                        return true;
                    case "risk":
                        window.location.href = "/risk";
                        return true;
                    case "sentiments":
                        window.location.href = "/sentiments";
                        return true;
                    case "propensity":
                        window.location.href = "/propensity";
                        return true;
                    case "custom":
                        window.location.href = "/custom";
                        return true;
                    default:
                        window.location.href = "#";
                        return true;
                }
            }
        } else return undefined;
    }

    showCaseDetails(element) {
        return this.setState({ infoOn: element });
    }


    _showNewButton() {
        if (this.isLoggedInUser === false) {
            return (<div className={"mlp_center mlp_block mlp_padding_1rem"}>
                <h6 className={"mlp_color_blue"}>We kindly ask you to login if you want to create your own data insight
                    analysis or feel free to explore our Demo insight without logging in</h6>
            </div>)
        } else return (<div />)
    }

    viewJob = (id, type) => {
        this.setState({
            viewJobId: id,
            viewJobType: type
        })
    }

    render() {
        if (this.state.viewJobId !== undefined) {
            return <div className="mlp_main_use_case_holder">
                    <MLPUseCases
                        viewJobId={this.state.viewJobId}
                        viewJobType={this.state.viewJobType}
                        onJobView={this.viewJob} />
            </div>

        }
        return (
            <div className="mlp_home_holder" id={"content_holder"}>
                <StructuredListWrapper ariaLabel="Structured list">
                    <StructuredListBody>
                        <StructuredListRow>
                            <StructuredListCell>
                                <div className="mlp_main_cards_holder mlp_center">
                                    <MLPCase isFocus={true} actionButton={this.newScenarioClick('churn')}
                                        onClick={() => this.showCaseDetails('churn')}
                                        disabled={false} title={"Churn Analysis"}
                                        description={"It is easier to keep an existing customer than to gain a new one. Save your customers."} />
                                    <MLPCase disabled={false} title={"Risk Analysis"}
                                        actionButton={this.newScenarioClick('risk')}
                                        onClick={() => this.showCaseDetails('risk')}
                                        description={"Detecting risks will let you time for actions. Protect you and your customers."} />
                                    <MLPCase onClick={() => this.showCaseDetails('sentiments')}
                                        disabled={false} title={"Sentiments"}
                                        actionButton={this.newScenarioClick('sentiments')}
                                        description={"Determine emotional tone from your texts with the latest Natual Language Models."} />
                                    <MLPCase onClick={() => this.showCaseDetails('propensity')}
                                        disabled={true} title={"Propensity"} 
                                        description={"Use Propensity Modeling to make accurate forecasts on customers behavior."} />
                                    <MLPCase disabled={true} title={"Your Insight"}
                                        onClick={() => this.showCaseDetails('custom')}
                                        description={"Define your own custom insight leveraging machine learning algorithms."} />
                                </div>
                                {this._showNewButton()}
                            </StructuredListCell>
                        </StructuredListRow>
                        <div className="mlp_main_use_case_holder">
                            <MLPUseCases
                                onJobView={this.viewJob}
                            />
                        </div>
                        <div className={"mlp_main_details_holder"}>
                            <RenderDescription detailsAbout={this.state.infoOn}
                                className={"mlp_main_case_details"} />
                        </div>
                    </StructuredListBody>
                </StructuredListWrapper>
            </div>
        )
    }
}