import React from "react";
import "../churn/churn.css"

import "@carbon/charts/styles.css";

import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@carbon/react";
import MLPPredict from "../components/Predict";
import {ViewAdapter} from "../common/ViewAdapter";


export default class MLPRiskPredict extends React.Component {

    jobDef = this.props.meta.jobJson
    viewAdapters = new ViewAdapter()

    constructor(props, context) {
        super(props, context);
        this.viewAdapters.registerViewListener('/v1/models?object.job_id=' + this.jobDef.system.id, (view) => {
            this.props.meta.setModels(view.data.filter(model => model.object.job_id === this.jobDef.system.id))
            this.forceUpdate()
        },5000)
    }

    render() {
        if (this.props.view) {
            return <MLPPredict meta={this.props.meta}/>
        }
        return (
            <div className={"mlp_churn_tabs_holder"}>
                <Tabs>
                    <TabList aria-label="List of tabs" light={true} iconSize={"lg"}
                             className={"mlp_churn_tabs_list_holder"}>
                        <Tab disabled className={"mlp_churn_tab_holder"}>1. Setup</Tab>
                        <Tab disabled className={"mlp_churn_tab_holder"}>2. Run</Tab>
                        <Tab className={"mlp_churn_tab_holder"}>3. Predict</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel/>
                        <TabPanel/>
                        <TabPanel>
                            <MLPPredict meta={this.props.meta}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        )
    }
}