import React from "react";

import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from "carbon-components-react/lib/components/StructuredList";
import {LineChart, SimpleBarChart} from "@carbon/charts-react";
import "@carbon/charts/styles.css";
import {InlineLoading, Loading} from "@carbon/react";
import {ViewAdapter} from "../common/ViewAdapter";


export default class MLPDataInsights extends React.Component {

    roles = this.props.meta.getRoles()
    schema = this.props.meta.getSchemaFields()
    jobDef = this.props.meta.jobJson
    jobId = this.jobDef.system.id

    customersSize = this.props.meta.getCustomersSize()
    productsSize = this.props.meta.getProdsSize()

    viewAdapter = new ViewAdapter()

    constructor(props, context) {
        super(props, context);
        this.state = {
            dataSalesView: null,
            dataTopProductsView: null,
            dataActivityView: null,
            dataProdActivityView: null
        }
    }

    _showDataInsights() {
        return (
            <StructuredListWrapper className={"mlp_churn_run_insights"}>
                <StructuredListBody>
                    <StructuredListRow>
                        <StructuredListCell>Number of customers</StructuredListCell>
                        <StructuredListCell><p>{this.customersSize}</p></StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                        <StructuredListCell>Number of products</StructuredListCell>
                        <StructuredListCell><p>{this.productsSize}</p></StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                        <StructuredListCell>Time period</StructuredListCell>
                        <StructuredListCell>
                            <p>From {this.props.meta.timeFrom()} until {this.props.meta.timeUntil()}</p>
                        </StructuredListCell>
                    </StructuredListRow>
                </StructuredListBody>
            </StructuredListWrapper>
        )
    }

    _showSalesActivity() {
        if (this.state.dataSalesView) {
            return (<div className={"mlp_churn_run_chart mlp_churn_run_right_chart"}><LineChart
                data={this.state.dataSalesView}
                options={{
                    "title": "Sale activity",
                    "axes": {
                        "bottom": {
                            "title": "Observed on date",
                            "mapsTo": "date",
                            "scaleType": "time"
                        },
                        "left": {
                            "mapsTo": "value",
                            "title": "Sale volume",
                            "scaleType": "linear"
                        }
                    },
                    "curve": "curveMonotoneX",
                    "height": "400px",
                    "width": "600px",
                }}>
            </LineChart></div>)
        } else {
            this.viewAdapter.registerViewListener('/v1/jobs/' + this.jobId + "/outputs/time_series.json",
                (view) => {
                    let activities = []
                    const activitiesToShow = view.series[0].values.length > 20 ? 20 : view.series[0].values.length
                    for (let i = 0; i < activitiesToShow; i++) {
                        const activity1 = {
                            "group": "Number of products",
                            "date": view.series[0].values[i].toString() + "-01T00:00:00.000Z",
                            "value": view.series[3].values[i]
                        }
                        activities.push(activity1)
                    }
                    this.setState({dataSalesView: activities})
                })
            return (<div className={"mlp_loading_graph_holder_600_400"}>
                <Loading description="loading products activity" withOverlay={false}/>
            </div>)
        }
    }

    _showCustActivity() {
        if (this.state.dataActivityView) {
            return (<LineChart
                data={this.state.dataActivityView}
                options={{
                    "title": "Customers activity",
                    "axes": {
                        "bottom": {
                            "title": "Observed on date",
                            "mapsTo": "date",
                            "scaleType": "time"
                        },
                        "left": {
                            "mapsTo": "value",
                            "title": "Number of customers",
                            "scaleType": "linear"
                        }
                    },
                    "curve": "curveMonotoneX",
                    "height": "400px",
                    "width": "600px",
                }}>
            </LineChart>)
        } else {
            this.viewAdapter.registerViewListener('/v1/jobs/' + this.jobId + "/outputs/time_series.json",
                (view) => {
                    let activities = []
                    const activitiesToShow = view.series[0].values.length > 20 ? 20 : view.series[0].values.length
                    for (let i = 0; i < activitiesToShow; i++) {
                        const activity1 = {
                            "group": "Number of users",
                            "date": view.series[0].values[i].toString() + "-01T00:00:00.000Z",
                            "value": view.series[1].values[i]
                        }
                        activities.push(activity1)
                    }

                    this.setState({dataActivityView: activities})
                })
            return (<div className={"mlp_loading_graph_holder_600_400"}>
                <Loading description="loading customer activity" withOverlay={false}/>
            </div>)
        }
    }

    _showTopProducts() {
        if (this.state.dataTopProductsView) {
            return (
                <div className={"mlp_churn_run_chart mlp_churn_run_right_chart"}>
                    <SimpleBarChart
                        data={this.state.dataTopProductsView}
                        options={{
                            "title": "Top products",
                            "height": "400px",
                            "width": "600px",
                            "bars": {
                                "width": 20,
                                "maxWidth": 70
                            },
                            "axes": {
                                "left": {
                                    "mapsTo": "group",
                                    "title": "Product identifier",
                                    "scaleType": "labels"
                                },
                                "bottom": {
                                    "title": "Product volume",
                                    "mapsTo": "value"
                                }
                            },
                        }}></SimpleBarChart></div>
            )
        } else {
            this.viewAdapter.registerViewListener('/v1/jobs/' + this.jobId + "/outputs/top_products.json",
                (topView) => {
                    let topProducts = []
                    const productsToShow = topView.series[0].values.length > 10 ? 10 : topView.series[0].values.length
                    for (let i = 0; i < productsToShow; i++) {
                        const product = {
                            "group": topView.series[0].values[i].toString(),
                            "value": topView.series[1].values[i]
                        }
                        topProducts.push(product)
                    }
                    this.setState({dataTopProductsView: topProducts.reverse()})
                })
            return (<div className={"mlp_loading_graph_holder_600_400"}>
                <Loading description="loading churn ratio" withOverlay={false}/>
            </div>)
        }
    }

    _showProdActivity() {
        if (this.state.dataProdActivityView) {
            return (<div className={"mlp_churn_run_chart mlp_churn_run_right_chart"}><LineChart
                data={this.state.dataProdActivityView}
                options={{
                    "title": "Products activity",
                    "axes": {
                        "bottom": {
                            "title": "Observed on date",
                            "mapsTo": "date",
                            "scaleType": "time"
                        },
                        "left": {
                            "mapsTo": "value",
                            "title": "Number of products",
                            "scaleType": "linear"
                        }
                    },
                    "curve": "curveMonotoneX",
                    "height": "400px",
                    "width": "600px",
                }}>
            </LineChart></div>)
        } else {
            this.viewAdapter.registerViewListener('/v1/jobs/' + this.jobId + "/outputs/time_series.json",
                (view) => {
                    let activities = []
                    const activitiesToShow = view.series[0].values.length > 20 ? 20 : view.series[0].values.length
                    for (let i = 0; i < activitiesToShow; i++) {
                        const activity1 = {
                            "group": "Number of products",
                            "date": view.series[0].values[i].toString() + "-01T00:00:00.000Z",
                            "value": view.series[2].values[i]
                        }
                        activities.push(activity1)
                    }
                    this.setState({dataProdActivityView: activities})
                })
            return (<div className={"mlp_loading_graph_holder_600_400"}>
                <Loading description="loading products activity" withOverlay={false}/>
            </div>)
        }
    }


    render() {
        if (this.props.meta.isJobFailed()) {
            this.viewAdapters.setJobFailed();
            return (<div className={"mlp_center"}><InlineLoading
                description="This churn insight failed on this setup. This will ring a bell on our end."
                status='error'/></div>)
        } else return (
            <StructuredListWrapper>
                <StructuredListBody>
                    <StructuredListRow>
                        <StructuredListCell>
                            {this._showDataInsights()}
                        </StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>

                        <div className={"mlp_grid_1_2 mlp_chart_grid"}>
                            <div>{this._showSalesActivity()}</div>
                            <div>{this._showTopProducts()}</div>
                            <div>{this._showCustActivity()}</div>
                            <div>{this._showProdActivity()}</div>
                        </div>
                    </StructuredListRow>

                </StructuredListBody>
            </StructuredListWrapper>
        )
    }
}