import React from "react";
import { Modal, TextInput, Checkbox } from "@carbon/react";



export default class ShareTo extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sharing: this.props.sharing,
            perms: []
        }

        this.userName = React.createRef("");
        this.labels = this.props.labels
        this.names = this.props.names
        for (let i = 0; i < this.names.length; i++) {
            this.state.perms[i] = false
        }
    }

    ok = () => {
        let user = this.userName.current.value

        let perms = this.names.filter((n, i) => this.state.perms[i])

        this.props.save(this.state.sharing.id, {
            "user": user,
            "permissions": perms
        });
    }

    close = () => {
        this.props.close();
    }

    renderInput = () => {

        return <TextInput
            disabled={this.state.sharing.user !== ""}
            id="user_name"
            labelText="User name"
            type="text"
            ref={this.userName}
        />
    }

    renderPerm = (idx) => {
        return <Checkbox id={"perm-" + idx + "-" + this.state.sharing.id} labelText={this.labels[idx]} checked={this.state.perms[idx]}
            onChange={(_, { checked }) => {
                let p = this.state.perms
                p[idx] = !p[idx]
                this.setState({ perms: p })
            }} />
    }

    render() {
        let id = this.state.sharing.id
        if (id === undefined || id === null) {
            id = ""
        }
        return <Modal
            modalHeading={this.props.title}
            open={this.props.open}
            size="sm"
            onRequestClose={this.close}
            onRequestSubmit={this.ok}
            primaryButtonText="Ok"
            secondaryButtonText="Cancel"
        >
            <div className="mlp_edit_user">

                {this.renderInput()}

                <ul>
                    {
                        this.names.map((e, i) => {
                            return <li key={"li-" + i}>{this.renderPerm(i)}</li>
                        })
                    }
                </ul>
            </div>
        </Modal>
    }
}