import React from "react";
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper,
    StructuredListHead
} from "carbon-components-react/lib/components/StructuredList";
import {uuid} from "../index";

export default class MLPTopicComments extends React.Component {

    renderComments() {
        return this.props.topicData.docs.map(doc => {
            return (<StructuredListRow key={uuid()}>
                <StructuredListCell key={uuid()}>{doc}</StructuredListCell>
            </StructuredListRow>)
        })
    }

    render() {
        if (this.props.topicData == null) {
            return (<StructuredListWrapper ariaLabel="Structured list">
                <StructuredListBody className={"mlp_sent_middle_table"}>
                    AI creates topics from texts by analyzing the content, using BERT natural language processing and machine learning algorithms, to identify patterns, relationships, and common themes within the text data.
                    <br/><i>Click on each topic circle to see relevant comments.</i>
                </StructuredListBody>
            </StructuredListWrapper>)
        } else return (
            <StructuredListWrapper className={"mlp_sent_middle_table"} ariaLabel="Structured list">
                <StructuredListHead>
                    <StructuredListRow head>
                        <StructuredListCell head>Relevant comments for topic: <i className={"mlp_color_blue"}>{this.props.topicData.name}</i></StructuredListCell>
                    </StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                    {this.renderComments()}
                </StructuredListBody>
            </StructuredListWrapper>)
    }
}