import React from "react";

import { Accordion, AccordionItem, Tab, TabList, TabPanel, TabPanels, Tabs } from '@carbon/react';

import "../churn/churn.css"
import "@carbon/charts/styles.css";

import { InlineLoading } from "carbon-components-react";
import MLPSentimentsInsights from "./SentimentsInsights";
import MLPTopicsInsights from "./TopicsInsights";


export default class MLPSentimentsRun extends React.Component {

    componentWillUnmount() {
        this.viewAdapters.stop()
    }

    render() {
        if (this.props.meta.isJobFailed()) {
            this.viewAdapters.stop()
        }
        if (this.props.noTab) {
            return <div className={"mlp_churn_run_holder"}>
                {this.showRunContent()}
            </div>
        }
        return (
            <div className={"mlp_churn_tabs_holder"}>
                <Tabs>
                    <TabList aria-label="List of tabs" light={true} iconSize={"lg"}
                        className={"mlp_churn_tabs_list_holder"}>
                        <Tab disabled className={"mlp_churn_tab_holder"}>1. Setup</Tab>
                        <Tab className={"mlp_churn_tab_holder"}>2. Run</Tab>
                        {/*<Tab disabled className={"mlp_churn_tab_holder"}>3. Predict</Tab>*/}
                    </TabList>
                    <TabPanels>
                        <TabPanel />
                        <TabPanel>
                            <div className={"mlp_churn_run_holder"}>
                                {this.showRunContent()}
                            </div>
                        </TabPanel>
                        <TabPanel />
                    </TabPanels>
                </Tabs>
            </div>
        )
    }

    _sentimentsInsightsTitle() {
        if (this.props.meta.isJobRunning()) {
            return (<div className={"mlp_churn_accordion_progress"}><h5>Sentiments insights</h5>
                <div className={"mlp_right"}><InlineLoading description="Computing insights. This might take a while..."
                    status='active' /></div>
            </div>)
        } else return (<h5>Sentiments insights</h5>)
    }

    _topicsInsightsTitle() {
        if (this.props.meta.isJobRunning()) {
            return (<div className={"mlp_churn_accordion_progress"}><h5>Topics insights</h5>
                <div className={"mlp_right"}><InlineLoading
                    description="Computing topics insights. This might take a while..."
                    status='active' /></div>
            </div>)
        } else return (<h5>Topics insights</h5>)
    }

    showRunContent() {
        return (
            <Accordion>
                <AccordionItem className={"mlp_sent_accordion_insights"} title={this._sentimentsInsightsTitle()}
                    open={true}>
                    <MLPSentimentsInsights meta={this.props.meta} />
                </AccordionItem>
                <AccordionItem className={"mlp_sent_accordion_insights"} title={this._topicsInsightsTitle()}
                    open={true}>
                    <MLPTopicsInsights meta={this.props.meta} />
                </AccordionItem>
            </Accordion>
        )
    }
}

