import React from "react";
import {render} from 'react-dom';
import Transition from 'react-transition-group/Transition';
import {ToastNotification} from "@carbon/react";
const duration = 300;

export function versionInfo() {
    const info = process.env.REACT_APP_UI_VERSION;
    if (!info) {
        console.info("No version information present at build time.");
        return "0.0.0"
    }
    console.info(`version: ${info}`);
    return info;
}


export function displayRequiredAction(message) {
    const MsgWindow = () => (
        <div>
            <ToastNotification
                subtitle={message}
                timeout={5000}
                title="Action required"
            />
        </div>

    );
    return render(<MsgWindow/>, document.getElementById('notifications'));
}


export function displayWarnAction(message) {
    const MsgWindow = () => (
        <div>
            <ToastNotification
                subtitle={message}
                timeout={5000}
                kind="warning"
                title="Friendly reminder"
            />
        </div>

    );
    return render(<MsgWindow/>, document.getElementById('notifications'));
}



const defaultStyle = {
    position: 'fixed',
    top: '50',
    right: '-350px',
    zIndex: '1000'
};

const transitionStyles = {
    entered: {
        transform: 'translateX(-100%)',
        transition: `transform ${duration}ms ease-in-out`
    },
    exiting: {
        transform: 'translateX(100%)',
        transition: `transform ${duration}ms ease-in-out`
    },
    exited: {
        right: '-350px'
    }
};

export const Notification = ({text, notification}) => {
    return (
        <Transition in={notification} timeout={duration} unmountOnExit>
            {(state) => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }}>
                    <ToastNotification
                        title={text}
                        kind={'success'}
                        caption={""}
                    />
                </div>
            )}
        </Transition>
    );
}
