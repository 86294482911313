import React from "react";
import {Modal, TextInput} from "@carbon/react";
import './mlops.css'

export default class EnableModel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            modelPath: ""
        }
    }

    setModelPath = (path) => {
        this.setState({
            modelPath: path.target.value
        })
    }

    ok = () => {
        this.props.save({
            path: this.state.modelPath
        });
    }

    render() {

        return <Modal
            modalHeading="Enable model"
            open={this.props.open}
            size="xs"
            onRequestClose={this.props.close}
            onRequestSubmit={this.ok}
            primaryButtonText="Enable"
            secondaryButtonText="Cancel"
        >
            <div className="mlp_ops_createpredictor">
                <div className="text_field">
                    <TextInput
                        id="model_path"
                        labelText="Name"
                        type="text"
                        onChange={this.setModelPath}
                    />
                </div>

                <div className="text_field">
                    <div className="label">Prediction API link</div>
                    <span>
                        {window.location.protocol + "//" + window.location.host + "/v1/predictions/~/" + this.state.modelPath}
                    </span>
                </div>

            </div>
        </Modal>
    }
}