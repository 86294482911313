import React from "react";
import {ExpandableTile, TileAboveTheFoldContent, TileBelowTheFoldContent} from "carbon-components-react";


export default class MLPTextWithMore extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            extended: false
        }
        this.whatToSee = "[see more]"
    }

    handleClick(param) {
        this.whatToSee = (this.whatToSee === "[see more]") ? "[see less]" : "[see more]"
        this.setState({
            extended: !this.state.extended
        })
    }


    render() {
        return (
            <ExpandableTile
                tabIndex={0}
                tileCollapsedIconText="read more about this"
                tileExpandedIconText="see less about this"
                handleClick={(param) => this.handleClick(param)}>
                <TileAboveTheFoldContent>{this.props.above}
                    <div className={"mlp_right"}>{this.whatToSee}</div>
                </TileAboveTheFoldContent>
                <TileBelowTheFoldContent>{this.props.children}</TileBelowTheFoldContent>
            </ExpandableTile>)
    }
}