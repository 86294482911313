import React, { Fragment } from "react";

import './mlops.css'

import { Dropdown, Modal, TextArea, RadioButtonGroup, RadioButton } from "@carbon/react";

import { niceDate } from "../index";


export default class StageModel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            model: this.props.model
        }
        this.stage = this.state.model.object.mlops.stage;
    }

    ok = () => {
        this.props.save(this.state.model.system.id, this.stage);
    }

    selectStage = (e) => {
        this.stage = e
    }

    render() {
        return <Modal
            modalHeading={this.state.model.object.name}
            open={this.props.open}
            size="xs"
            onRequestClose={this.props.close}
            onRequestSubmit={this.ok}
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
        >

            <RadioButtonGroup
                id="stages"
                defaultSelected={this.state.model.object.mlops.stage}
                name={"radiongroup-" + this.state.model.system.id}
                orientation='vertical'
                onChange={this.selectStage}>

                <RadioButton
                    labelText="Default"
                    value="default"
                />

                <RadioButton
                    labelText="Testing"
                    value="testing"
                />

                <RadioButton
                    labelText="Production"
                    value="prod"
                />
            </RadioButtonGroup>

        </Modal>
    }
}