import React from "react";
import '../churn/churn.css'
import './risk.css'
import {Tab, TabList, TabPanel, TabPanels, Tabs, TextInput} from "@carbon/react";
import DataFields from "../datasets/DataFields";
import MLPTextWithMore from "../components/TextWithMore";

export default class MLPRiskSettings extends React.Component {

    allRoles = [
        "feature",
        "target",
        "ignore"
    ];

    riskFieldsDescription() {
        return (
            <div className={"mlp_churn_dataset_description"}>
                <MLPTextWithMore
                    above={<h6>Please provide some hints about your input data structure. These would be used for a
                        better
                        understanding of the data and to produce more accurate risk analysis results.</h6>}>
                    <div> Mandatory select the <b className={"mlp_text_bold"}>target</b>(risk) role for one of the
                        fields from the selected datasource.
                        <br/>The <b className={"mlp_text_bold"}>target</b> role should refer a data field with discrete
                        values (0 and 1).
                    </div>
                </MLPTextWithMore>
                <br/>
            </div>
        )
    }

    getJobName() {
        return this.props.jobName.current === null ? this.props.meta.jobName() : this.props.jobName.current.value
    }

    render() {

        return (
            <div className={"mlp_churn_tabs_holder"}>

                <Tabs>
                    <TabList aria-label="List of tabs" light={true} iconSize={"lg"}
                             className={"mlp_churn_tabs_list_holder"}>
                        <Tab className={"mlp_churn_tab_holder"}>1. Setup</Tab>
                        <Tab disabled className={"mlp_churn_tab_holder"}>2. Run</Tab>
                        <Tab disabled className={"mlp_churn_tab_holder"}>3. Predict</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <div className={"mlp_churn_setup_tab"}>
                                <div className={"mlp_padding_1rem"}>
                                    <TextInput
                                        readOnly={this.props.meta.jobId() !== undefined}
                                        className={"mlp_padding_1rem"}
                                        ref={this.props.jobName}
                                        labelText={this.props.meta.jobId() !== undefined ? "Risk job name" : "Please provide a name for this job"}
                                        helperText=""
                                        id={"risk_job_name"}
                                        invalidText="You must provide a name for Risk job"
                                        placeholder={this.getJobName()}/>
                                    <DataFields caseMeta={this.props.meta} roles={this.allRoles}
                                                   description={this.riskFieldsDescription()}/>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel/>
                        <TabPanel/>
                    </TabPanels>
                </Tabs>
            </div>
        )
    }
}