import React from "react";
import Link from 'carbon-components-react/lib/components/Link'

import './footer.css'
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListWrapper
} from "carbon-components-react/lib/components/StructuredList";
import {Button} from "@carbon/react";


export default class MLPFooter extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.terms = this.terms.bind(this);
    }

    showTerms() {
        const box = document.getElementById("content_holder")
        box.style.display = 'none';

        const tnc = document.getElementById("terms_and_cond")
        tnc.style.display = 'block';
    }

    accepted() {
        const tnc = document.getElementById("terms_and_cond")
        tnc.style.display = 'none';

        const box = document.getElementById("content_holder")
        box.style.display = 'block';
    }

    terms() {
        return (
            <div className={"mlp_terms_and_cond"}>
                <div id={"terms_and_cond"}>
                    <h3>Terms and Conditions</h3><br/>
                    <h4>Welcome to Deep Insights Platform!</h4>
                    <br/>
                    <h6> These terms and conditions outline the rules and regulations for the use of DataGrid Software's
                        Website, located at platform.datagrid.software.</h6>
                    <br/>
                    <h6> By accessing this website we assume you accept these terms and conditions. Do not continue to
                        use
                        Deep Insights Platform if you do not agree to take all of the terms and conditions stated on
                        this
                        page.</h6>
                    <br/>
                    <h6> The following terminology applies to these Terms and Conditions, Privacy Statement and
                        Disclaimer
                        Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this
                        website
                        and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and
                        "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and
                        ourselves.
                        All terms refer to the offer, acceptance and consideration of payment necessary to undertake the
                        process of our assistance to the Client in the most appropriate manner for the express purpose
                        of
                        meeting the Client's needs in respect of provision of the Company's stated services, in
                        accordance
                        with and subject to, prevailing law of Netherlands. Any use of the above terminology or other
                        words
                        in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and
                        therefore as referring to same.</h6>
                    <br/>
                    <h5> Cookies</h5>
                    <h6> We employ the use of cookies. By accessing Deep Insights Platform, you agreed to use cookies in
                        agreement
                        with the DataGrid Software's Privacy Policy.</h6>
                    <br/>
                    <h6> Most interactive websites use cookies to let us retrieve the user's details for each visit.
                        Cookies
                        are used by our website to enable the functionality of certain areas to make it easier for
                        people
                        visiting our website. Some of our affiliate/advertising partners may also use cookies.</h6>
                    <br/>
                    <h5> License</h5>
                    <h6> Unless otherwise stated, DataGrid Software and/or its licensors own the intellectual property
                        rights
                        for all material on Deep Insights Platform. All intellectual property rights are reserved. You
                        may access
                        this from Deep Insights Platform for your own personal use subjected to restrictions set in
                        these terms
                        and
                        conditions.</h6>
                    <br/>
                    <h5> You must not:</h5>
                    <br/>
                    <h6> Republish material from Deep Insights Platform</h6>
                    <h6> Sell, rent or sub-license material from Deep Insights Platform</h6>
                    <h6> Reproduce, duplicate or copy material from Deep Insights Platform</h6>
                    <h6> Redistribute content from Deep Insights Platform</h6>
                    <h6> This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the
                        help
                        of the Free Terms and Conditions Generator.</h6>
                    <br/>
                    <h6> Parts of this website offer an opportunity for users to post and exchange opinions and
                        information
                        in certain areas of the website. DataGrid Software does not filter, edit, publish or review
                        Comments
                        prior to their presence on the website. Comments do not reflect the views and opinions of
                        DataGrid
                        Software,its agents and/or affiliates. Comments reflect the views and opinions of the person who
                        post their views and opinions. To the extent permitted by applicable laws, DataGrid Software
                        shall
                        not be liable for the Comments or for any liability, damages or expenses caused and/or suffered
                        as a
                        result of any use of and/or posting of and/or appearance of the Comments on this website.</h6>
                    <br/>
                    <h6> DataGrid Software reserves the right to monitor all Comments and to remove any Comments which
                        can be
                        considered inappropriate, offensive or causes breach of these Terms and Conditions.</h6>
                    <br/>
                    <h5> You warrant and represent that:</h5>
                    <br/>
                    <h6> You are entitled to post the Comments on our website and have all necessary licenses and
                        consents to
                        do so;</h6>
                    <h6> The Comments do not invade any intellectual property right, including without limitation
                        copyright,
                        patent or trademark of any third party;</h6>
                    <h6> The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful
                        material which is an invasion of privacy</h6>
                    <h6> The Comments will not be used to solicit or promote business or custom or present commercial
                        activities or unlawful activity.</h6>
                    <h6> You hereby grant DataGrid Software a non-exclusive license to use, reproduce, edit and
                        authorize
                        others to use, reproduce and edit any of your Comments in any and all forms, formats or
                        media.</h6>
                    <br/>
                    <h5> Hyperlinking to our Content</h5>
                    <h6> The following organizations may link to our Website without prior written approval:</h6>
                    <br/>
                    <h6> Government agencies;</h6>
                    <h6> Search engines;</h6>
                    <h6> News organizations;</h6>
                    <h6> Online directory distributors may link to our Website in the same manner as they hyperlink to
                        the
                        Websites of other listed businesses; and</h6>
                    <h6> System wide Accredited Businesses except soliciting non-profit organizations, charity shopping
                        malls, and charity fundraising groups which may not hyperlink to our Web site.</h6>
                    <h6> These organizations may link to our home page, to publications or to other Website information
                        so long as the link: <br/>(a) is not in any way deceptive; <br/>(b) does not falsely imply
                        sponsorship,
                        endorsement or approval of the linking party and its products and/or services; and <br/>(c) fits
                        within
                        the context of the linking party's site.</h6>
                    <br/>
                    <h6> We may consider and approve other link requests from the following types of organizations:</h6>
                    <br/>
                    <h6> commonly-known consumer and/or business information sources;</h6>
                    <h6> dot.com community sites;</h6>
                    <h6> associations or other groups representing charities;</h6>
                    <h6> online directory distributors;</h6>
                    <h6> internet portals;</h6>
                    <h6> accounting, law and consulting firms; and</h6>
                    <h6> educational institutions and trade associations.</h6>
                    <h6> We will approve link requests from these organizations if we decide that: (a) the link would
                        not
                        make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does
                        not
                        have any negative records with us; (c) the benefit to us from the visibility of the hyperlink
                        compensates the absence of DataGrid Software; and (d) the link is in the context of general
                        resource
                        information.</h6>
                    <br/>
                    <h6> These organizations may link to our home page so long as the link: (a) is not in any way
                        deceptive;
                        (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its
                        products or services; and (c) fits within the context of the linking party's site.</h6>
                    <br/>
                    <h6> If you are one of the organizations listed in paragraph 2 above and are interested in linking
                        to
                        our
                        website, you must inform us by sending an e-mail to DataGrid Software. Please include your name,
                        your organization name, contact information as well as the URL of your site, a list of any URLs
                        from
                        which you intend to link to our Website, and a list of the URLs on our site to which you would
                        like
                        to link. Wait 2-3 weeks for a response.</h6>
                    <br/>
                    <h5> Approved organizations may hyperlink to our Website as follows:</h5>
                    <br/>
                    <h6> By use of our corporate name; or</h6>
                    <h6> By use of the uniform resource locator being linked to; or</h6>
                    <h6> By use of any other description of our Website being linked to that makes sense within the
                        context
                        and format of content on the linking party's site.</h6>
                    <h6> No use of DataGrid Software's logo or other artwork will be allowed for linking absent a
                        trademark
                        license agreement.</h6>
                    <br/>
                    <h6> iFrames</h6>
                    <h6> Without prior approval and written permission, you may not create frames around our Webpages
                        that
                        alter in any way the visual presentation or appearance of our Website.</h6>
                    <br/>
                    <h5> Content Liability</h5>
                    <h6> We shall not be hold responsible for any content that appears on your Website. You agree to
                        protect
                        and defend us against all claims that is rising on your Website. No link(s) should appear on any
                        Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise
                        violates, or advocates the infringement or other violation of, any third party rights.</h6>
                    <br/>
                    <h5> Your Privacy</h5>
                    <h6> Please read Privacy Policy</h6>
                    <br/>
                    <h5> Reservation of Rights</h5>
                    <h6> We reserve the right to request that you remove all links or any particular link to our
                        Website.
                        You
                        approve to immediately remove all links to our Website upon request. We also reserve the right
                        to
                        amen these terms and conditions and it's linking policy at any time. By continuously linking to
                        our
                        Website, you agree to be bound to and follow these linking terms and conditions.</h6>
                    <br/>
                    <h6> Removal of links from our website</h6>
                    <h6> If you find any link on our Website that is offensive for any reason, you are free to contact
                        and
                        inform us any moment. We will consider requests to remove links but we are not obligated to or
                        so or
                        to respond to you directly.</h6>
                    <br/>
                    <h6> We do not ensure that the information on this website is correct, we do not warrant its
                        completeness
                        or accuracy; nor do we promise to ensure that the website remains available or that the material
                        on
                        the website is kept up to date.</h6>
                    <br/>
                    <h5> Disclaimer</h5>
                    <h6> To the maximum extent permitted by applicable law, we exclude all representations, warranties
                        and
                        conditions relating to our website and the use of this website. Nothing in this disclaimer
                        will:</h6>
                    <br/>
                    <h6> limit or exclude our or your liability for death or personal injury;</h6>
                    <h6> limit or exclude our or your liability for fraud or fraudulent misrepresentation;</h6>
                    <h6> limit any of our or your liabilities in any way that is not permitted under applicable law;
                        or</h6>
                    <h6> exclude any of our or your liabilities that may not be excluded under applicable law.</h6>
                    <h6> The limitations and prohibitions of liability set in this Section and elsewhere in this
                        disclaimer:
                        (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the
                        disclaimer, including liabilities arising in contract, in tort and for breach of statutory
                        duty.</h6>
                    <br/>
                    <h6> As long as the website and the information and services on the website are provided free of
                        charge,
                        we will not be liable for any loss or damage of any nature.</h6>
                    <h6>
                    </h6>
                    <div className={"mlp_center mlp_padding_1rem"}>
                        <Button kind={"primary"} onClick={this.accepted}>Accept terms and conditions</Button>
                    </div>
                </div>

            </div>
        )
    }

    render() {
        return (
            <div>{this.terms()}
                <div className="mlp_footer_container mlp_center">
                    <StructuredListWrapper ariaLabel="Structured list">
                        <StructuredListBody>
                            <StructuredListCell>
                                <hr/>
                                <div className={"mlp_footer_holder"}>
                                    <Link href="https://www.datagridsoftware.com">Copyright DataGridSoftware</Link>|
                                    <Link onClick={this.showTerms} href="#">Terms and Conditions</Link>|
                                    <Link href="#">Contact: office@datagridsoftware.com</Link>
                                </div>
                            </StructuredListCell>
                        </StructuredListBody>
                    </StructuredListWrapper>
                </div>
            </div>)
    }
}