import React from "react";
import { FileUploader } from "carbon-components-react";
import { loggedInUserId, mlp_get_options, SERVER_API_URL } from "../index";

import './components.css'
import { FileUploaderItem } from "@carbon/react";

export default class MLPFileUploader extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.handleFilesChange = this.handleFilesChange.bind(this);
        this.state = {
            selectedFile: this.props.selectedFile ? this.props.selectedFile : null
        }
    }

    handleFilesChange(event) {
        let selectedFile = event.target.files[0]
        let formData = new FormData();
        formData.append(selectedFile.name, selectedFile);
        const pathToFetch = SERVER_API_URL + '/v1/data'
        mlp_get_options(pathToFetch, { method: "POST", body: formData })
            .then(result => {
                this.props.onUpload(selectedFile.name) //Note an upload of a same file name
                this.setState({
                    selectedFile: selectedFile.name
                })
            })

    }

    render() {
        if (this.state.selectedFile !== null) {
            return (<div className="cds--file__container mlp_dataset_upload_holder mlp_center"><FileUploaderItem
                name={this.state.selectedFile} status="complete" /></div>)
        } else {
            return (<div className="cds--file__container mlp_dataset_upload_holder mlp_center">
                <FileUploader
                    onChange={this.handleFilesChange}
                    accept={[
                        '.csv',
                        '.txt',
                        '.xls'
                    ]}
                    buttonKind="secondary"
                    buttonLabel="Upload file"
                    iconDescription="Clear file"
                    labelDescription="[.csv, .xls or .txt] files less then 100mb"
                    labelTitle=""

                />
            </div>)
        }
    }
}