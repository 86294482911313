import React from "react";

export function RenderDescription(props) {
    switch (props.detailsAbout) {
        case "churn":
            return <ChurnDetails/>
        case "risk":
            return <RiskAnalysis/>
        case "sentiments":
            return <Sentiments/>
        case "propensity":
            return <Propensity/>
        case "custom":
            return <CustomPSProblem/>
        default:
            return (<div className={"mlp_case_details"}><h3>Out of the box ML solutions</h3><br/><p>
                Check out our out of the box solutions for your business case. Should face a specific ML problem that is not cover please contact us so we can accommodate it on our platform.</p>
            </div>)

    }
}

export function ChurnDetails() {
    return (
        <div className={"mlp_case_details"}><h3>What is "Churn Analysis"?</h3><br/>
        <p>
            Churn analysis, also known as customer churn prediction or customer attrition analysis, is a process of examining customer behavior and identifying patterns that indicate the likelihood of customers discontinuing their relationship with a business or service. It involves analyzing historical data, such as customer interactions, purchase patterns, usage statistics, and demographics, to build predictive models that can estimate the probability of customer churn. The objective of churn analysis is to proactively identify at-risk customers, understand the underlying reasons for churn, and take appropriate measures to retain valuable customers, enhance customer loyalty, and minimize customer attrition. This analysis is commonly used in industries such as telecommunications, subscription services, e-commerce, and banking, where customer retention is crucial for business success.</p>
    </div>)
}

export function Sentiments() {
    return (<div className={"mlp_case_details"}><h3>What is "Sentiments"?</h3><br/>
        <p>Sentiment analysis in AI involves using machine learning to determine whether a piece of text expresses a positive, negative, or neutral sentiment. It's widely used for understanding public opinion, customer feedback, and brand perception by analyzing text data automatically.</p>
    </div>)

}

export function Propensity() {
    return (<div className={"mlp_case_details"}><h3>What is a Propensity?</h3><br/>
        <p>Propensity Modeling Techniques is a statistical method used to predict the Customer Behavior and chances of certain events happening in the future. This is a powerful tool for build robust propensity models and make accurate forecasts by predicting customers behavior.</p>
    </div>)

}

export function RiskAnalysis() {
    return (<div className={"mlp_case_details"}><h3>Analyze and detect risks</h3><br/><p>
        A Risk Analysis ML system is an application of machine learning techniques that assesses and evaluates potential risks associated with specific scenarios or decisions. It utilizes algorithms to interpret various data features to generate risk scores, predictions, or classifications. This system aids in identifying and mitigating potential risks, enabling informed decision-making and proactive risk management in various domains, such as finance, insurance, cybersecurity, and healthcare.</p>
    </div>)
}

export function CustomPSProblem() {
    return (<div className={"mlp_case_details"}><h3>Contact us for your specific business case</h3><br/><p>
        Check out our out of the box solutions for your business case. Should face a specific ML problem that is not cover please contact us so we can acomodate it on our platform.</p>
    </div>)
}