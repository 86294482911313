import React from "react";
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from 'carbon-components-react/lib/components/StructuredList';

import MLPCase from "../components/Case";
import MLPHeader from "../header/Header";
import MLPFooter from "../footer/Footer";
import {RenderDescription} from "../components/CaseDetails";

import './home.css'
import {Button} from "@carbon/react";
import {isLoggedInUser} from "../index";
import LocalLogin from "../components/LocalLogin";
import MLPGoogleLogin from "../components/GoogleLogin";

export default class MLPHomePage extends React.Component {

    render() {
        return (
            <div className="mlp_content_holder">
                <MLPHeader withAccount={true}/>
                <br/>
                <div className="mlp_center"><MLPHome/></div>
                <MLPFooter/>
            </div>
        )
    }
}

class MLPHome extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            infoOn: 'none'
        };
    }


    showCaseDetails(element) {
        this.setState({infoOn: element});
    }

    centralAction() {
        if (isLoggedInUser()) {
            return (<Button onClick={() => window.location = "/main"} iconDescription="Demo"
                            className={"mlp_home_explore_btn"}><p>Explore deep insights application</p></Button>)
        } else {
            return (window.LOCAL_LOGIN) ? (<LocalLogin/>) : (<MLPGoogleLogin/>)
        }

    }


    render() {
        return (
            <div className="mlp_home_holder" id={"content_holder"}>
                <StructuredListWrapper ariaLabel="Structured list">
                    <StructuredListBody>
                        <StructuredListRow>
                            <StructuredListCell>
                                <h3 className="mlp_size_xxlarge">Deep Insights Platform</h3><br/>
                                <h5>Get insights on your data and have AI drive your management
                                    decisions.</h5>
                                <p>
                                    Deep Insights connects your business to your data, and helps you to boost your
                                    business by
                                    leveraging the cutting edge Machine Learning algorithms,
                                    without the need of skills and infrastructure. This solution is specifically
                                    tailored
                                    for the businesses that whats to move towards a more smarter way of decision making,
                                    and a revolutionary
                                    way of looking at the data.</p>

                            </StructuredListCell>
                        </StructuredListRow>
                        <br/>

                        {this.centralAction()}

                        <StructuredListRow>
                            <StructuredListCell>
                                <h3 className="mlp_size_xxlarge mlp_no_padding">Business Insights</h3><br/>
                                <div className="mlp_home_cards_holder mlp_center">
                                    <MLPCase title={"Churn Analysis"} onClick={() => this.showCaseDetails('churn')}
                                             description={"It is easier to keep an existing customer than to gain a new one. Save the customers before they leave."}/>
                                    <MLPCase title={"Risk analysis"}
                                             onClick={() => this.showCaseDetails('risk')}
                                             description={"Detecting risks will let you time for actions. Protect you and your customers."}/>
                                    <MLPCase title={"Sentiments"}
                                             onClick={() => this.showCaseDetails('sentiments')}
                                             description={"Determine emotional tone from your texts with the latest Natual Language Models"}/>
                                    <MLPCase title={"Propensity"}
                                             onClick={() => this.showCaseDetails('propensity')}
                                             description={"Use Propensity Modeling to Predict Customer Behavior"}/>
                                    <MLPCase title={"Your Insight"} onClick={() => this.showCaseDetails('custom')}
                                             description={"Define your own custom insight leveraging machine learning algorithms."}/>
                                </div>
                            </StructuredListCell>
                        </StructuredListRow>
                        <StructuredListRow>
                            <RenderDescription detailsAbout={this.state.infoOn}/>
                        </StructuredListRow>
                    </StructuredListBody>
                </StructuredListWrapper>
            </div>
        )
    }
}