import React from "react";

import './churn.css'
import Information from "@carbon/icons-react/lib/information/16";
import {
    DatePicker,
    DatePickerInput,
    Dropdown,
    TableContainer,
    TextInput,
    Toggletip,
    ToggletipButton,
    ToggletipContent
} from '@carbon/react';
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from "carbon-components-react/lib/components/StructuredList";
import {queryParams} from "../index";

const timeIntervals = ["Days", "Months", "Years"];
const metricsList = ["F1 Score", "MSE", "RMSE"]

export default class MLPChurnSetup extends React.Component {

    _isPredefined() {
        const qParams = queryParams()
        return (qParams.id && qParams.id !== "")
    }

    render() {
        return (
            <div className={"mlp_churn_setup_table"}>
                <TableContainer title="Settings">
                    <StructuredListWrapper>
                        <StructuredListBody>
                            <StructuredListRow>
                                <StructuredListCell>
                                    <div className={"mlp_churn_setup_holder"}>
                                        <DatePicker dateFormat="m/d/y" datePickerType="single" onChange={(datePicked) => this.props.meta.onCutChange(datePicked[0].getTime())}>
                                            <DatePickerInput
                                                disabled={this._isPredefined()}
                                                id="churnCutTime"
                                                placeholder="mm/dd/yy"
                                                labelText="CUT TIME"/>
                                        </DatePicker>
                                    </div>
                                </StructuredListCell>
                                <StructuredListCell>
                                    <Toggletip align={"left"}>
                                        <ToggletipButton label="Additional information">
                                            <Information/>
                                        </ToggletipButton>
                                        <ToggletipContent>
                                            Time till data is being processed in ML algorithm
                                        </ToggletipContent>
                                    </Toggletip>
                                </StructuredListCell>
                            </StructuredListRow>
                            <StructuredListRow>
                                <StructuredListCell>
                                    <div className={"mlp_churn_setup_holder"}>
                                        <TextInput
                                            disabled={this._isPredefined()}
                                            ref={this.props.meta.onChurnNotSeenAfter}
                                            id="notSeenAfter"
                                            invalidText="A valid value is required"
                                            labelText="CHURNED IF NOT SEEN AFTER"
                                            placeholder="3"
                                        />
                                        <div className={"mlp_churn_setup_row"}>
                                            <Dropdown
                                                disabled={this._isPredefined()}
                                                onChange={e => this.props.meta.onChurnNotSeenAfterInterval(e.selectedItem)}
                                                ariaLabel="Interval"
                                                id="notSeenInterval"
                                                items={timeIntervals}
                                                label="Months"
                                                titleText="&nbsp;"
                                            />
                                        </div>
                                    </div>
                                </StructuredListCell>
                                <StructuredListCell>
                                    <Toggletip align={"left"}>
                                        <ToggletipButton label="Additional information">
                                            <Information/>
                                        </ToggletipButton>
                                        <ToggletipContent>
                                            Interval of time to consider a record as churn
                                        </ToggletipContent>
                                    </Toggletip>
                                </StructuredListCell>

                            </StructuredListRow>
                            <StructuredListRow>
                                <StructuredListCell>
                                    <div className={"mlp_churn_setup_holder"}>
                                        <Dropdown
                                            disabled={this._isPredefined()}
                                            onChange={e => this.props.meta.onRankedByMetric(e.selectedItem)}
                                            ariaLabel="Metrics"
                                            id="rankMetrics"
                                            items={metricsList}
                                            label="F1 Score"
                                            titleText="RANK MODELS BY METRIC"
                                        />
                                    </div>
                                </StructuredListCell>
                                <StructuredListCell>
                                    <Toggletip align={"left"}>
                                        <ToggletipButton label="Additional information">
                                            <Information/>
                                        </ToggletipButton>
                                        <ToggletipContent>
                                            ML metric that ranks the best models
                                        </ToggletipContent>
                                    </Toggletip>
                                </StructuredListCell>
                            </StructuredListRow>
                        </StructuredListBody>
                    </StructuredListWrapper>
                </TableContainer>
            </div>
        )
    }
}