import React from "react";
import { queryParams } from "../index";

import MLPHeader from "../header/Header";
import MLPFooter from "../footer/Footer";
import NewChurn from "./NewChurn";

export default class MLPChurnPage extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return <div className="mlp_content_holder">
            <MLPHeader withAccount={true} subCat={"Churn analysis"} />
            <div className="mlp_center">
                <NewChurn />
            </div>
            <MLPFooter />
        </div>
    }
}