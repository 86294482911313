import React from "react";

import './mlops.css'

import { isShared } from "../common/utils";
import { InlineLoading, Modal, Tag } from "@carbon/react";
import { OverflowMenu, OverflowMenuItem, } from "carbon-components-react";
import { BsCheckCircle, BsExclamationCircle, BsFillCaretDownFill, BsFillCaretUpFill, BsShare } from 'react-icons/bs';
import { mlp_delete, mlp_get, mlp_patch, mlp_post, niceDate, SERVER_API_URL, trimTo } from "../index";
import EnableModel from "./EnableModel";
import AddLabel from "./AddLabel";
import UpdateModel from "./UpdateModel";
import ApproveModel from "./ApproveModel";
import { getPermissions, getUser, retryWith } from "../common/utils.js";
import StageModel from "./StageModel";
import ShareTo from "../components/ShareTo";

export default class ModelCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showEditStage: false,
            showEditApproval: false,
            showAddLabel: false,
            showEnableModel: false,
            showDelete: false,
            showDisable: false,
            showShare: false
        }
    }

    // React hook to update state when props change
    static getDerivedStateFromProps(nextProps, prevState) {
        const res = prevState;
        res.model = nextProps.model;
        return res;
    }

    showShareDialog = (open) => {
        this.setState({
            showShare: open,
        })
    }

    showEditStageDialog = (open) => {
        this.setState({
            showEditStage: open,
        })
    }

    showApprovalDialog = (open) => {
        this.setState({
            showEditApproval: open,
        })
    }


    showDeleteDialog = (open) => {
        this.setState({
            showDelete: open,
        })
    }

    showDisableDialog = (open) => {
        this.setState({
            showDisable: open,
        })
    }

    showAddLabelDialog = (open) => {
        this.setState({
            showAddLabel: open,
        })
    }

    showEnableModelDialog = (open) => {
        this.setState({
            showEnableModel: open,
        })
    }



    cap = (string) => {
        if (string === undefined) {
            return ""
        }
        return string.charAt(0).toUpperCase() + string.slice(1) + " Analysis";
    }

    approvedIcon = () => {
        const mlops = this.props.model.object['mlops'];
        if (mlops !== undefined) {
            switch (mlops.approval) {
                case "approved":
                    return <BsCheckCircle style={{ color: 'green', fontSize: "1.2em" }} />
                case "rejected":
                    return <BsExclamationCircle style={{ color: 'red', fontSize: "1.2em" }} />
                default:
                    return <div />
            }
        }
        return <div />
    }

    saveLabels = (ls) => {
        const update = [
            { "op": "replace", "path": "/labels", "value": ls },
        ];
        mlp_patch(SERVER_API_URL + '/v1/models/' + this.props.model.system.id, update)
            .then(jsonData => {
                if (jsonData.object !== undefined) {
                    this.showAddLabelDialog(false);
                    this.props.mlp_ops_parent.updateModel(jsonData)
                }
            });
    }

    getLabels = () => {
        let labels = this.props.model.object.labels
        if (labels === undefined || labels === null) {
            return [];
        }
        return labels;
    }

    addLabel = (label) => {
        let ls = this.getLabels();
        ls.push(label);
        this.saveLabels(ls)
    }

    deleteLabel = (label) => {
        return (e) => {
            const labels = this.getLabels().filter(l => l !== label)
            this.saveLabels(labels)
        }
    }

    saveApproval = (obj) => {
        const update = [

            { "op": "replace", "path": "/mlops/approval", "value": obj.approval },
            { "op": "replace", "path": "/mlops/comment", "value": obj.comment },

        ];
        mlp_patch(SERVER_API_URL + '/v1/models/' + this.props.model.system.id, update)
            .then(jsonData => {
                if (jsonData.object !== undefined) {
                    this.showApprovalDialog(false);
                    this.props.mlp_ops_parent.updateModel(jsonData)
                }
            });


    }

    saveStage = (id, stage) => {
        const update = [
            { "op": "replace", "path": "/mlops/stage", "value": stage },
        ];
        mlp_patch(SERVER_API_URL + '/v1/models/' + id, update)
            .then(jsonData => {
                if (jsonData.object !== undefined) {
                    this.showEditStageDialog(false);
                    this.props.mlp_ops_parent.updateModel(jsonData, this.props.panel.props.stage)
                }
            });


    }

    saveSharing = (id, object) => {
        const payload = [
            object,
        ];
        mlp_post(SERVER_API_URL + '/v1/models/' + this.props.model.system.id + "/sharing", payload)
            .then(jsonData => {
                if (jsonData.object !== undefined) {
                    this.showShareDialog(false);
                    this.props.mlp_ops_parent.updateModelStage(jsonData, this.props.panel.props.stage)
                }
            });


    }

    saveModel = (obj) => {

        const update = [

            { "op": "replace", "path": "/name", "value": obj.name },
            { "op": "replace", "path": "/version", "value": obj.version },
            { "op": "replace", "path": "/description", "value": obj.description },
            { "op": "replace", "path": "/sharing", "value": obj.sharing },

        ];
        mlp_patch(SERVER_API_URL + '/v1/models/' + this.props.model.system.id, update)
            .then(jsonData => {
                if (jsonData.object !== undefined) {
                    this.props.hideEditModel();
                    this.props.mlp_ops_parent.updateModel(jsonData)
                }
            });

    }

    version = () => {
        const v = this.props.model.object.version;
        if (v === undefined || v === "") {
            return "0.0.1"
        }
        return v;
    }



    deleteModel = () => {

        mlp_delete(SERVER_API_URL + '/v1/models/' + this.props.model.system.id)
            .then(response => {
                this.showDeleteDialog(false);
                this.props.mlp_ops_parent.deleteModel(this.props.model.system.id, this.props.panel.props.stage);
            })
    }

    disableModel = () => {

        const update = [

            { "op": "replace", "path": "/prediction_info/enabled", "value": false },

        ];
        mlp_patch(SERVER_API_URL + '/v1/models/' + this.props.model.system.id, update)
            .then(jsonData => {
                if (jsonData.object !== undefined) {
                    this.showDisableDialog(false);
                    this.props.mlp_ops_parent.updateModel(jsonData)
                }
            })
            .then(async v => {
                return await retryWith(() =>
                    this.fetch("pending"), 3000, 5)
                    .catch(e => console.log(e))
            })
    }


    fetch = async (waitForStatus) => {
        return mlp_get(SERVER_API_URL + '/v1/models/' + this.props.model.system.id)
            .then(m => {
                if (m.system !== undefined) {
                    if (m.system.status !== waitForStatus) {
                        throw "not_ready"
                    }
                    this.props.mlp_ops_parent.updateModel(m)
                }
            })

    }

    enableModel = (obj) => {

        const update = [

            { "op": "replace", "path": "/prediction_info/enabled", "value": true },
            { "op": "replace", "path": "/prediction_info/path", "value": obj.path },

        ];
        mlp_patch(SERVER_API_URL + '/v1/models/' + this.props.model.system.id, update)
            .then(jsonData => {
                if (jsonData.object !== undefined) {
                    this.showEnableModelDialog(false);
                    this.props.mlp_ops_parent.updateModel(jsonData)
                }
                return 200
            })
            .then(async v => {
                return await retryWith(() =>
                    this.fetch("ready"), 3000, 3)
                    .catch(e => console.log(e))
            })


    }

    showEnablementDialog = () => {
        if (this.props.model.object.prediction_info.enabled) {
            this.showDisableDialog(true)
            return
        }

        this.showEnableModelDialog(true)
    }

    boxClass = () => {
        if (this.props.model.system.status === "ready") {
            return "mlp_ops_modelbox mlp_model_ready"
        }
        return "mlp_ops_modelbox"
    }

    waitForReady = () => {
        if (
            (this.props.model.object.prediction_info.enabled && this.props.model.system.status === "pending") ||
            (!this.props.model.object.prediction_info.enabled && this.props.model.system.status === "ready")
        ) {
            return <InlineLoading description="" />
        }
        return <div />
    }

    approveMenu = () => {
        let perms = getPermissions()
        let allowed = false

        if (isShared(this.props.model)) {
            allowed = this.props.model.object.sharing[0].permissions.includes("approve")
        } else {
            allowed = perms.admin || perms.approve_models
        }

        if (allowed) {
            return <OverflowMenuItem itemText="Approval" onClick={
                () => {
                    this.showApprovalDialog(true)
                }
            } />
        }

        return <OverflowMenuItem itemText="Approval" disabled />
    }

    stageMenu = () => {
        let perms = getPermissions()
        let allowed = false
        if (isShared(this.props.model)) {
            allowed = this.props.model.object.sharing[0].permissions.includes("move")
        } else {
            allowed = perms.admin || perms.move_models
        }


        if (allowed) {
            return <OverflowMenuItem itemText="Move" onClick={
                () => {
                    this.showEditStageDialog(true)
                }
            } />
        }

        return <OverflowMenuItem itemText="Move" disabled />
    }

    enableMenu = () => {
        let perms = getPermissions()
        let allowed = false
        if (isShared(this.props.model)) {
            allowed = this.props.model.object.sharing[0].permissions.includes("enable")
        } else {
            allowed = perms.admin || perms.enable_models
        }

        if (allowed) {
            return <OverflowMenuItem
                itemText={(this.props.model.object.prediction_info.enabled) ? "Disable" : "Enable"}
                onClick={
                    () => {
                        this.showEnablementDialog()
                    }
                } />
        }
        return <OverflowMenuItem
            disabled
            itemText={(this.props.model.object.prediction_info.enabled) ? "Disable" : "Enable"} />
    }

    manageMenu = () => {
        let perms = getPermissions()
        let allowed = false
        if (isShared(this.props.model)) {
            allowed = this.props.model.object.sharing[0].permissions.includes("manage")
        } else {
            allowed = perms.admin || perms.manage_models
        }

        if (allowed) {
            return <OverflowMenuItem itemText="Add label" onClick={
                () => {
                    this.showAddLabelDialog(true);
                }
            } />
        }
        return <OverflowMenuItem itemText="Add label" disabled />
    }

    deleteMenu = () => {
        let perms = getPermissions()
        let allowed = false
        if (isShared(this.props.model)) {
            allowed = this.props.model.object.sharing[0].permissions.includes("manage")
        } else {
            allowed = perms.admin || perms.manage_models
        }

        if (allowed) {
            return <OverflowMenuItem hasDivider isDelete itemText="Delete" onClick={
                () => {
                    this.showDeleteDialog(true);
                }
            } />
        }
        return <OverflowMenuItem disabled hasDivider isDelete itemText="Delete" />
    }

    shareMenu = () => {
        let perms = getPermissions()
        let allowed = false
        if (isShared(this.props.model)) {
            allowed = this.props.model.object.sharing[0].permissions.includes("manage")
        } else {
            allowed = perms.admin || perms.manage_models
        }

        if (allowed) {
            return <OverflowMenuItem itemText="Share to" onClick={
                () => {
                    this.showShareDialog(true);
                }
            } />
        }
        return <OverflowMenuItem disabled itemText="Delete" />
    }

    editModel = () => {
        return <UpdateModel
            model={this.props.model}
            job={this.props.job}
            close={e => this.props.hideEditModel()}
            saveModel={this.saveModel}
        />
    }

    renderShared = () => {
        if (isShared(this.props.model)) {
            return <BsShare className="mlp_ops_shared" />
        }
        return <div />
    }

    renderBody = () => {
        return (
            <React.Fragment>
                <div className="mlp_ops_meta">
                    {
                        (this.props.job !== undefined) ?
                            <div>{"Usecase : " + this.props.job.class}</div> : <div />
                    }
                    {
                        (this.props.job !== undefined) ?
                            <div>{"Job : " + this.props.job.name}</div> : <div />
                    }
                    {
                        (isShared(this.props.model)) ? <div>{"Shared by: " + this.props.model.system.author}</div> : <div />
                    }
                    <div>{niceDate(this.props.model.system.created_at)}</div>

                </div>

                <div className="mlp_ops_labels">
                    {
                        this.getLabels().map(l => {
                            return <Tag type="green"
                                size="md"
                                key={l}
                                onClick={this.deleteLabel(l)}
                                filter>{l}</Tag>
                        })
                    }
                </div>
            </React.Fragment>
        )
    };

    renderContent = () => {

        return <div className="mlp_ops_box_item_component">
            <div className={this.boxClass()}>
                <div className="mlp_ops_card_title">
                    <div className="mlp_ops_card_title-name">{trimTo(20, this.props.model.object.name)}</div>
                </div>

                <div className="mlp_ops_action_menu">
                    <OverflowMenu ariaLabel="overflow-menu">
                        <OverflowMenuItem itemText="Details" onClick={
                            () => {
                                this.props.showEditModel(this.editModel());
                            }
                        } />
                        {this.approveMenu()}
                        {this.stageMenu()}
                        {this.enableMenu()}
                        {this.manageMenu()}
                        {this.shareMenu()}
                        {this.deleteMenu()}

                    </OverflowMenu>
                </div>
                {this.renderShared()}

                <div className="mlp_ops_approval_icon">
                    {this.approvedIcon()}
                </div>
                <div className="mlp_ops_model_wait">
                    {this.waitForReady()}
                </div>
                {this.renderBody()}
            </div>
        </div>

    }

    render() {

        return <React.Fragment>
            {this.renderContent()}
            <ApproveModel
                open={this.state.showEditApproval}
                model={this.props.model}
                close={e => this.showApprovalDialog(false)}
                approve={this.saveApproval} />

            <Modal
                modalHeading={"Delete '" + this.props.model.object.name + "' model ?"}
                open={this.state.showDelete}
                size="xs"
                onRequestClose={e => this.showDeleteDialog(false)}
                onRequestSubmit={this.deleteModel}
                primaryButtonText="Yes"
                secondaryButtonText="No"
                alert={true}
                danger={true} />


            <Modal
                modalHeading={"Disable '" + this.props.model.object.name + "' model ?"}
                open={this.state.showDisable}
                size="xs"
                onRequestClose={e => this.showDisableDialog(false)}
                onRequestSubmit={this.disableModel}
                primaryButtonText="Yes"
                secondaryButtonText="No"
                alert={true}
                danger={true} />

            <AddLabel
                open={this.state.showAddLabel}
                close={() => this.showAddLabelDialog(false)}
                addLabel={this.addLabel}
                model={this.props.model} />

            <EnableModel
                open={this.state.showEnableModel}
                close={() => this.showEnableModelDialog(false)}
                save={this.enableModel}
                model={this.props.model} />

            <StageModel
                open={this.state.showEditStage}
                close={() => this.showEditStageDialog(false)}
                save={this.saveStage}
                model={this.props.model} />

            <ShareTo
                open={this.state.showShare}
                close={() => this.showShareDialog(false)}
                title="Share model"
                labels={["Manage", "Approve", "Move", "Enable"]}
                names={["manage", "approve", "move", "enable"]}
                sharing={
                    {
                        "id": this.props.model.system.id,
                        "user": "",
                        "permissions": []
                    }
                }
                save={this.saveSharing} />
        </React.Fragment>
    }
}