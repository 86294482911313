import React from "react";
import Datasets from "../datasets/DataSets";
import MLPTextWithMore from "../components/TextWithMore";

export default class MLPSentimentsDataSets extends React.Component {

    render() {
        return (
            <div className={"mlp_churn_dataset_description"}>
                <div className={"mlp_datasets_header"}>
                    <br />
                    <h3 className="mlp_size_xlarge mlp_no_padding">Choose or define a text data set for Sentiments analysis use case</h3>
                    <br /><br />
                    <div className={"mlp_center"}>
                        <div className={"mlp_left"}>
                            <MLPTextWithMore
                                above={<h6>You can select a predefined dataset or define your own dataset in order
                                    perform sentiment analysis. Expand to see how your data should look like in order to have
                                    a successful insight.</h6>}>
                                <p>In order to have a successful sentiments analysis you should have a text datasets with sentences on each line.
                                    <br />Each line will be a analyze in its own context for a sentiment determination</p>
                            </MLPTextWithMore>
                        </div>
                    </div>
                    <br />
                    <Datasets onDataSet={this.props.onDataSet} hideControls={true} />
                </div>
            </div>
        )
    }
}