import ReactGA from 'react-ga4';
import {loggedInUserId} from "../index";

export default class MLPGoogleAnalytics {

    async publishEvent(event) {
        if (!window.location.href.includes("localhost")) {
            ReactGA.event(event)
        }
    }

    trimGmail(email) {
        return email.replace("@gmail.com", "#")
    }

    newScenario(scenario) {
        return this.publishEvent({
            category: scenario,
            action: 'new_scenario',
            label: this.trimGmail(loggedInUserId())
        })
    }

    predict(scenario) {
        return this.publishEvent({
            category: scenario,
            action: 'predict',
            label: this.trimGmail(loggedInUserId())
        })
    }

    deploy(scenario) {
        return this.publishEvent({
            category: scenario,
            action: 'deploy',
            label: this.trimGmail(loggedInUserId())
        })
    }

    undeploy(scenario) {
        return this.publishEvent({
            category: scenario,
            action: 'undeploy',
            label: this.trimGmail(loggedInUserId())
        })
    }


    userLogin() {
        return this.publishEvent({
            label: this.trimGmail(loggedInUserId()),
            category: this.trimGmail(loggedInUserId()),
            action: 'user_login'
        })
    }

    manageDatasets() {
        return this.publishEvent({
            label: this.trimGmail(loggedInUserId()),
            category: this.trimGmail(loggedInUserId()),
            action: 'manage_datasets'
        })
    }

    viewScenario(scenario) {
        return this.publishEvent({
            label: this.trimGmail(loggedInUserId()),
            category: scenario,
            action: 'view_scenario'
        })
    }


}