import React from "react";
import ReactDOM from "react-dom";
import './components.css'


export default class MLPCard extends React.Component {

    componentDidMount() {
        if (this.props.isFocus) {
            if (this.props.hasOwnProperty('onClick')) {
                this.props.onClick();
            }
            ReactDOM.findDOMNode(this.refs.cardRef).focus({focusVisible: false, preventScroll: true});
        }
    }

    render() {
        return (
            <div ref="cardRef" tabIndex={0} className={"card " + this.props.styleClass} onClick={this.props.onClick}>
                <div className="card-body">
                    {this.props.children}
                </div>
            </div>
        )
    }
}