import {mlp_get, SERVER_API_URL} from "../index";

export class ViewAdapter {
    pendingCallBacks = new Set()
    stopQueue = false;
    REST_WAIT_MS = 10000

    abort() {
        this.stopQueue = true;
    }

    monitorJob(jobId, callback) {
        const pathToFetch = SERVER_API_URL + '/v1/jobs/' + jobId
        return mlp_get(pathToFetch)
            .then(async jsonData => {
                if (this.stopQueue) return;
                if (jsonData.system.status === "pending" || jsonData.system.status === "running") {
                    setTimeout(() => this.monitorJob(jobId), 5000)
                }
                callback(jsonData)
            })
    }

    registerViewListener(uri, callback, startDelay = 0, waitRetryMs = this.REST_WAIT_MS) {
        const _registerViewListener = (callback) => {
            if (this.stopQueue) return true;
            const pathToFetch = SERVER_API_URL + uri
            mlp_get(pathToFetch)
                .then(async jsonData => {
                    if (jsonData.status && jsonData.status == 404) throw new Error("Resource not ready")
                    this.pendingCallBacks.delete(uri)
                    try {
                        callback(jsonData)
                    } catch (error) {
                        console.log("Failed view adapter callback : " + uri, error)
                    }
                }).catch(error => setTimeout(() => _registerViewListener(callback), waitRetryMs))
            return true;
        }

        if (this.pendingCallBacks.has(uri)) {
            console.warn("There is already a listener for resource: " + uri)
            return false;
        }
        this.pendingCallBacks.add(uri);
        return setTimeout(() => _registerViewListener(callback), startDelay)
    }
}