import React from "react";

import { RiskMeta } from "./RiskMeta";
import { Accordion, AccordionItem, Button } from "@carbon/react";
import MLPRiskInsights from "./RiskInsights";
import MLPRiskML from "./ML";
import MLPPredict from "./Predict";
import CaseView from "../common/CaseView";
import Close from "@carbon/icons-react/lib/close/32";

export default class ViewRisk extends CaseView {

    meta = new RiskMeta()

    renderContent() {
        return <div className="mlp-risk-content">
            <div className="mlp-risk-section">
                <div className="mlp-pointer"><Close onClick={this.props.onClose}/></div>
            </div>
            <div className="mlp-sentiment-section">
                <h2>{
                    this.meta.jobJson.object.name
                }</h2>
            </div>
            <div className="mlp-risk-section">
                <Accordion>
                    <AccordionItem className={"mlp_risk_accordion_insights"} title={this.renderDatasetTitle()}
                        open={false}>
                        {this.renderDatasetInfo()}
                    </AccordionItem>
                    <AccordionItem className={"mlp_risk_accordion_insights"} title={"Report"}
                        open={true}>
                        <MLPRiskInsights meta={this.meta} />
                    </AccordionItem>
                    <AccordionItem className={"mlp_risk_accordion_insights"} title={"Machine learning models"}
                        open={false}>
                        <MLPRiskML meta={this.meta} />
                        <MLPPredict meta={this.meta} view={this.dataSourceId != undefined} />
                    </AccordionItem>
                </Accordion>
            </div>
            <div className="mlp-risk-section">
                {this.showSharingInfo()}
            </div>
        </div>
    }
}