import React from "react";

import MLPHeader from "../header/Header";
import MLPFooter from "../footer/Footer";

import './main.css'
import MLPLanding from "./Landing";
import {isLoggedInUser} from "../index";

export default class MLPAppPage extends React.Component {

    constructor(props, context) {
        super(props);
    }

    render() {
        if (!isLoggedInUser()) {
            window.location.href = "/"
            return (<div/>)
        }
        return (
            <div className="mlp_content_holder">
                <MLPHeader withAccount={true}/>
                <br/>
                <div className="mlp_center">
                    <MLPLanding/>
                </div>
                <MLPFooter/>
            </div>
        )
    }
}
