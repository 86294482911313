import React from "react";
import MLPHeader from "../header/Header";
import MLPFooter from "../footer/Footer";
import { isLoggedInUser, handleRestError, mlp_get, mlp_get_options, mlp_put, queryParams, SERVER_API_URL } from "../index";
import { Button, Loading } from "@carbon/react";
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from "carbon-components-react/lib/components/StructuredList";
import { displayRequiredAction, displayWarnAction } from "../components/Notifications";

import { SentimentsMeta } from "./SentimentsMeta";
import MLPSentimentsDataSets from "./DataSets";

import './sentiments.css'
import MLPSentimentsRun from "./SentimentsRun";
import MLPSentimentsPredict from "./SentimentsPredict";
import MLPSentimentsSetup from "./SentimentsSetup";

export default class NewSentiment extends React.Component {


    constructor(props) {
        super(props);
        this.dataSourceId = null;
        this.state = {
            wizardPage: 0,
        };

        this.back = this.back.bind(this);
        this.meta = new SentimentsMeta()
        
        this.setDataSet = this.setDataSet.bind(this);
        this.selectDataSet = this.selectDataSet.bind(this);
        this.continueWizard = this.continueWizard.bind(this);

        this.jobName = React.createRef();
        this._isMounted = true;
    }

    back() {
        const qParams = queryParams()
        if (this.state.wizardPage === 0) {
            window.location.href = "/main";
            return true;
        } else if (qParams.id && qParams.id !== "" && this.state.wizardPage === 1) {
            window.location.href = "/main";
            return true;
        } else {
            return this.setState({ wizardPage: this.state.wizardPage - 1 })
        }
    }

    setDataSet(dsId) {
        const pathToFetch = SERVER_API_URL + '/v1/datasets/' + dsId
        mlp_get(pathToFetch)
            .then(jsonData => {
                handleRestError(jsonData)
                this.meta.setDataSource(jsonData)
                this.dataSourceId = dsId
                this.setState({
                    wizardPage: 1
                }
                );
            });
    }



    showButtonsWizard() {
        if (this.state.wizardPage < 3) {
            return (<div className={"mlp_center mlp_churn_nav"}>
                <div><Button kind={"secondary"} onClick={this.back}><p>Back</p></Button></div>
                <Button kind={"primary"} onClick={this.continueWizard}><p>Continue</p></Button>
            </div>)
        } else {
            return (<div className={"mlp_center mlp_churn_nav"}>
                <div className={""}><Button kind={"secondary"} onClick={this.back}><p>Back</p></Button></div>
                <Button kind={"primary"} onClick={this.continueWizard}><p>Done</p></Button>
            </div>)
        }
    }

    continueWizard() {
        if (this.state.wizardPage === 0) {
            return (this.dataSourceId == null) ? displayRequiredAction("Please select a data set in order to continue") : this.setDataSet(this.dataSourceId)
        } else if (this.state.wizardPage === 1) {
            return this.startSentimentsJobIfNeeded()
        } else if (this.state.wizardPage === 2 && !this.meta.isJobFailed()) {
            //if (this.meta.isJobRunning()) {
            //    displayWarnAction("Please allow more time for this analysis in order to be able to use prediction")
            //    return false;
            // }
            return this.setState({ wizardPage: 3 });
        } else {
            window.location.href = "/main";
            return true;
        }
    }

    renderMLPSentiments() {
        return <div className="mlp-sentiment-content mlp_new_width" id={"content_holder"}>
            <StructuredListWrapper ariaLabel="Sentiments settings">
                <StructuredListBody>
                    <StructuredListRow>
                        <StructuredListCell>
                            {this.showSentimentsWizard()}
                        </StructuredListCell>
                    </StructuredListRow>
                    {this.showButtonsWizard()}
                </StructuredListBody>
            </StructuredListWrapper>
        </div>
    }

    selectDataSet(dsId) {
        this.dataSourceId = dsId
    }

    startSentimentsJobIfNeeded() {
        if (this.meta.jobJson === null) {//we need to start a new job
            if (!this.jobName.current || this.jobName.current.value === "") {
                return displayRequiredAction("Please provide a name for this job before you can continue")
            }

            const newJobDatasetId = this.meta.dataSourceJson.system.id
            const newSentimentsPayload = {
                "name": this.jobName.current.value,
                "class": "sentiments",
                "dataset_id": newJobDatasetId,
                "roles": []
            }

            const pathToFetch = SERVER_API_URL + '/v1/jobs'
            return mlp_get_options(pathToFetch, { method: 'POST', body: JSON.stringify(newSentimentsPayload) })
                .then(jsonData => {
                    this.meta.setJob(jsonData)
                    return jsonData.system.id
                }).then(jobId => this.monitorJob(jobId)).then(() => this.setState({ wizardPage: 2 }))
        } else {
            return this.monitorJob(this.meta.jobJson.system.id).then(() => this.setState({ wizardPage: 2 }))
        }
    }

    showSentimentsWizard() {
        if (this.state.wizardPage === 0) {
            return (<MLPSentimentsDataSets onDataSet={this.selectDataSet} />)
        } else if (this.state.wizardPage === 1) {
            return (<MLPSentimentsSetup dataset={this.dataSourceId} jobName={this.jobName} meta={this.meta} />)
        } else if (this.state.wizardPage === 2) {
            return (<MLPSentimentsRun meta={this.meta} />)
 //       } else if (this.state.wizardPage === 3) {
 //           return (<MLPSentimentsPredict meta={this.meta} />)
        } else {
            window.location.href = "/main";
            return true;
        }
    }

    componentDidMount() {
        this._ismounted = true;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    monitorJob(jobId) {
        if (this._ismounted) {
            const pathToFetch = SERVER_API_URL + '/v1/jobs/' + jobId
            return mlp_get(pathToFetch)
                .then(async jsonData => {
                    if (jsonData.system.status === "pending" || jsonData.system.status === "running") {
                        setTimeout(() => this.monitorJob(jobId), 5000)
                    }
                    this.meta.setJob(jsonData);
                    this.setState(this.state);
                })
        }
    }

    content = () => {
        const qParams = queryParams()
        if (qParams.id && qParams.id !== "" && this.state.wizardPage === 0) {
            const pathToFetch = SERVER_API_URL + '/v1/jobs/' + qParams.id
            mlp_get(pathToFetch)
                .then(jsonData => {
                    this.meta.setJob(jsonData)
                    return jsonData.object.dataset_id;
                }).then((dsId) => this.setDataSet(dsId))
            return (<div><Loading description="Loading sentiments information" withOverlay={false} /></div>);
        } else {
            return this.renderMLPSentiments()
        }
    }

    render() {
        if (!isLoggedInUser()) {
            window.location.href = "/main"
            return
        }

        return this.content()

    }
}