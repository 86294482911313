import React from "react";

import {Accordion, AccordionItem, Tab, TabList, TabPanel, TabPanels, Tabs} from '@carbon/react';

import "../churn/churn.css"
import "@carbon/charts/styles.css";

import {InlineLoading} from "carbon-components-react";
import MLPRiskInsights from "./RiskInsights";
import MLPRiskML from "./ML";

export default class MLPRiskRun extends React.Component {

    render() {
        return (
            <div className={"mlp_churn_tabs_holder"}>
                <Tabs>
                    <TabList aria-label="List of tabs" light={true} iconSize={"lg"}
                             className={"mlp_churn_tabs_list_holder"}>
                        <Tab disabled className={"mlp_churn_tab_holder"}>1. Setup</Tab>
                        <Tab className={"mlp_churn_tab_holder"}>2. Run</Tab>
                        <Tab disabled className={"mlp_churn_tab_holder"}>3. Predict</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel/>
                        <TabPanel>
                            <div className={"mlp_churn_run_holder"}>
                                {this.showRunContent()}
                            </div>
                        </TabPanel>
                        <TabPanel/>
                    </TabPanels>
                </Tabs>
            </div>
        )
    }

    _riskInsightsTitle() {
        if (this.props.meta.isJobRunning()) {
            return (<div className={"mlp_churn_accordion_progress"}><h5>Risk insights</h5>
                <div className={"mlp_right"}><InlineLoading description="Computing insights. This might take a while..."
                                                            status='active'/></div>
            </div>)
        } else return (<h5>Risk insights</h5>)
    }

    _mlInsightsTitle() {
        if (this.props.meta.isJobRunning()) {
            return (<div className={"mlp_churn_accordion_progress"}><h5>Machine Learning insights</h5>
                <div className={"mlp_right"}><InlineLoading description="Computing insights. This might take a while..."
                                                            status='active'/></div>
            </div>)
        } else return (<h5>Machine Learning insights</h5>)
    }

    showRunContent() {
        return (
                <Accordion>
                    <AccordionItem className={"mlp_risk_accordion_insights"} title={this._riskInsightsTitle()}
                                   open={true}>
                        <MLPRiskInsights meta={this.props.meta}/>
                    </AccordionItem>
                    <AccordionItem className={"mlp_risk_accordion_insights"} title={this._mlInsightsTitle()}
                                   open={false}>
                        <MLPRiskML meta={this.props.meta}/>
                    </AccordionItem>
                </Accordion>
        )
    }
}

