import React from "react";
import { Modal, TextInput, Checkbox } from "@carbon/react";

export default class EditUser extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.userName = React.createRef("");
    }

    ok = () => {
        let un = undefined
        if (this.userName.current !== null) {
            un = this.userName.current.value
        }
        this.props.saveUser(un);
    }

    renderInput = (user) => {
        if (user.id === undefined) {
            return <TextInput
                id="user_name"
                labelText="User name"
                type="text"
                defaultValue={user.user}
                ref={this.userName}
            />
        }

        return <TextInput
            disabled
            id="user_name"
            labelText="User name"
            type="text"
            defaultValue={user.user}
        />
    }

    checkPerm = (event, { checked, id }) => {
        this.props.setPerm(id, checked)
        this.setState({})
    }

    render() {
        let user = this.props.user();
        return <Modal
            modalHeading="User information"
            open={this.props.open}
            size="sm"
            onRequestClose={this.props.close}
            onRequestSubmit={this.ok}
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
        >
            <div className="mlp_edit_user">

               {this.renderInput(user)}

                <ul>
                    <li>
                        <Checkbox id="admin" labelText="Admin" checked={user.permissions.admin} 
                            onChange={this.checkPerm} 
                        />
                    </li>
                    <li>
                        <Checkbox id="manage_jobs" labelText="Manage jobs" checked={user.permissions.manage_jobs} onChange={this.checkPerm} />
                    </li>
                    <li>
                        <Checkbox id="manage_datasets" labelText="Manage Datasets" checked={user.permissions.manage_datasets} onChange={this.checkPerm} />
                    </li>
                    <li>
                        <Checkbox id="manage_models" labelText="Manage models" checked={user.permissions.manage_models} onChange={this.checkPerm} />
                    </li>
                    <li>
                        <Checkbox id="enable_models" labelText="Enable models" checked={user.permissions.enable_models} onChange={this.checkPerm} />
                    </li>
                    <li>
                        <Checkbox id="approve_models" labelText="Approve models" checked={user.permissions.approve_models} onChange={this.checkPerm} />
                    </li>
                    <li>
                        <Checkbox id="move_models" labelText="Movel models" checked={user.permissions.move_models} onChange={this.checkPerm} />
                    </li>
                    <li>
                        <Checkbox id="predict" labelText="Predict" checked={user.permissions.predict} onChange={this.checkPerm} />
                    </li>
                </ul>
            </div>
        </Modal>
    }
}