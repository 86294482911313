import React from "react";

import './datasets.css'
import MLPCard from "../components/Card";
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from "carbon-components-react/lib/components/StructuredList";
import { OverflowMenu, OverflowMenuItem, Tag } from "@carbon/react";
import { trimTo } from "../index";
import { CanManageShared, isShared } from "../common/utils";
import ShareKnowledge from "@carbon/icons-react/lib/share-knowledge/20";

export default class DatasetCard extends React.Component {

    isReadOnly = false

    _getTypeTag(dataset) {
        return (dataset.object.type === "s3" && dataset.object.parameters.uploaded_file) ? "file" : dataset.object.type;
    }

    displayTags(dataset) {
        const typeTag = this._getTypeTag(dataset)
        dataset.tags = dataset.object !== undefined ? [typeTag, dataset.system.status] : ["predefined"]
        if (isShared(dataset) && !CanManageShared(dataset)) {
            this.isReadOnly = true
        }

        return (dataset.tags === undefined) ? (<div />) :
            (<div className={"mlp_dataset_tags"}>
                {
                    this.sharedTag()
                }
                {dataset.tags.map((tag) => {
                    if (tag === "failed" || tag === "error") {
                        return (<div className="mlp-ds-tag" key={tag}><Tag key={dataset.system.id + tag} type="red" title={tag}>{tag}</Tag></div>)
                    } else if (tag === "ready") {
                        return (<div className="mlp-ds-tag" key={tag}><Tag key={dataset.system.id + tag} type="green" title={tag}>{tag}</Tag></div>)
                    } else {
                        return (<div className="mlp-ds-tag" key={tag}>
                            <Tag key={dataset.system.id + tag} type="blue" title={tag}>{tag}</Tag>
                        </div>)
                    }
                })}
                <div className={"mlp_data_card_menu"}>
                    <OverflowMenu ariaLabel={"Options"}>
                        <OverflowMenuItem itemText="View" onClick={() => {
                            if (this.props.onView !== undefined) {
                                return this.props.onView(this.props.dataset)
                            } else return true;
                        }} />
                        <OverflowMenuItem itemText="Share to" disabled={this.isReadOnly} onClick={() => {
                            if (this.props.onEdit !== undefined) {
                                return this.props.onShare(this.props.dataset)
                            } else return true;
                        }} />
                        <OverflowMenuItem itemText="Delete" hasDivider isDelete disabled={this.isReadOnly} onClick={() => {
                            if (this.props.onEdit !== undefined) {
                                return this.props.onDelete(this.props.dataset)
                            } else return true;
                        }} />
                    </OverflowMenu></div>
            </div>)
    }

    sharedTag = () => {
        if (isShared(this.props.dataset)) {
            return <div className="mlp-ds-tag">

                <div className="mlp-dataset-shared-icon">
                    <ShareKnowledge />
                </div>
            </div >
        }
        return <span />
    }
    render() {
        const disabled = this.props.dataset.system.status === "running" || this.props.dataset.system.status === "pending"
            || this.props.dataset.system.status === "error" || this.props.dataset.system.status === "failed";

        const caseStyle = disabled ? "mlp_card_info mlp_dataset_card mlp_card_disabled" : "mlp_card_info mlp_dataset_card";
        return (
            <MLPCard styleClass={caseStyle} onClick={() => this.props.onClick(this.props.dataset.system.id)}>
                {this.displayTags(this.props.dataset)}

                <div className="mlp-dataset-card-summary mlp-dataset-card-name">
                    {
                        trimTo(21, this.props.dataset.object.name)
                    }
                </div>
                <div className="mlp-dataset-card-summary mlp-dataset-card-ts">
                    {
                        this.props.dataset.system.last_modified
                    }
                </div>

            </MLPCard>
        )
    }
}

