import React from "react";
import MLPHeader from "../header/Header";
import MLPFooter from "../footer/Footer";
import {RiskMeta} from "./RiskMeta";
import {isLoggedInUser, handleRestError, mlp_get, mlp_get_options, queryParams, SERVER_API_URL} from "../index";
import {Button, Loading} from "@carbon/react";
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from "carbon-components-react/lib/components/StructuredList";
import {displayRequiredAction, displayWarnAction} from "../components/Notifications";
import MLPRiskDataSets from "./DataSets";
import MLPRiskSettings from "./RiskSettings";
import MLPRiskRun from "./Run";
import MLPRiskPredict from "./Predict";

export default class NewRisk extends React.Component {


    constructor(props) {
        super(props);
        this.dataSourceId = null;
        this.state = {
            wizardPage: 0
        };

        this.riskMeta = new RiskMeta()
        this.back = this.back.bind(this);
        this.showRisk = this.showRisk.bind(this);
        this.setDataSet = this.setDataSet.bind(this);
        this.selectDataSet = this.selectDataSet.bind(this);
        this.continueWizard = this.continueWizard.bind(this);

        this.jobName = React.createRef();
        this._isMount = true;
    }

    back() {
        const qParams = queryParams()
        if (this.state.wizardPage === 0) {
            window.location.href = "/main";
            return true;
        } else if (qParams.id && qParams.id !== "" && this.state.wizardPage === 1) {
            window.location.href = "/main";
            return true;
        } else {
            return this.setState({wizardPage: this.state.wizardPage - 1})
        }
    }

    setDataSet(dsId) {
        const pathToFetch = SERVER_API_URL + '/v1/datasets/' + dsId
        mlp_get(pathToFetch)
            .then(jsonData => {
                handleRestError(jsonData)
                this.riskMeta.setDataSource(jsonData)
                this.dataSourceId = dsId
                this.setState({
                        wizardPage: 1,
                    }
                );
            });
    }

    showRisk() {
        const qParams = queryParams()
        if (qParams.id && qParams.id !== "" && this.state.wizardPage === 0) {
            const pathToFetch = SERVER_API_URL + '/v1/jobs/' + qParams.id
            mlp_get(pathToFetch)
                .then(jsonData => {
                    this.riskMeta.setJob(jsonData)
                    return jsonData.object.dataset_id;
                }).then((dsId) => this.setDataSet(dsId))
            return (<div className="mlp-risk-content"><Loading description="Loading risk information" withOverlay={false}/></div>);
        } else {
            return this.renderMLPRisk()
        }
    }

    showButtonsWizard() {
        if (this.state.wizardPage < 3) {
            return (<div className={"mlp_center mlp_churn_nav"}>
                <div><Button kind={"secondary"} onClick={this.back}><p>Back</p></Button></div>
                <Button kind={"primary"} onClick={this.continueWizard}><p>Continue</p></Button>
            </div>)
        } else {
            return (<div className={"mlp_center mlp_churn_nav"}>
                <div className={""}><Button kind={"secondary"} onClick={this.back}><p>Back</p></Button></div>
                <Button kind={"primary"} onClick={this.continueWizard}><p>Done</p></Button>
            </div>)
        }
    }

    continueWizard() {
        if (this.state.wizardPage === 0) {
            return (this.dataSourceId == null) ? displayRequiredAction("Please select a data set in order to continue") : this.setDataSet(this.dataSourceId)
        } else if (this.state.wizardPage === 1) {
            return this.startRiskJobIfNeeded()
        } else if (this.state.wizardPage === 2 && !this.riskMeta.isJobFailed()) {
            if (this.riskMeta.isJobRunning()) {
                displayWarnAction("Please allow more time for this risk analysis in order to be able to use predict")
                return false;
            }
            return this.setState({wizardPage: 3});
        } else {
            window.location.href = "/main";
            return true;
        }
    }

    renderMLPRisk() {
        return (
            <div className="mlp-risk-content mlp_new_width" id={"content_holder"}>
                <StructuredListWrapper ariaLabel="Risk settings">
                    <StructuredListBody>
                        <StructuredListRow>
                            <StructuredListCell>
                                <br/>
                                {this.showRiskWizard()}
                            </StructuredListCell>
                        </StructuredListRow>
                        {this.showButtonsWizard()}
                    </StructuredListBody>
                </StructuredListWrapper>
            </div>
        )
    }

    selectDataSet(dsId) {
        this.dataSourceId = dsId
    }

    startRiskJobIfNeeded() {
        if (this.riskMeta.jobJson === null) {//we need to start a new risk
            const rolesValidation = this.riskMeta.validateRoles()
            if (!rolesValidation.isValid) {
                return displayRequiredAction(rolesValidation.message)
            } else if (this.jobName.current.value === "") {
                return displayRequiredAction("Please provide a name for this job before you can continue")
            }

            const newJobRoles = this.riskMeta.getRoles()
            const newJobDatasetId = this.riskMeta.dataSourceJson.system.id
            const newRiskPayload = {
                "name": this.jobName.current.value,
                "class": "risk",
                "dataset_id": newJobDatasetId,
                "roles": newJobRoles
            }

            const pathToFetch = SERVER_API_URL + '/v1/jobs'
            return mlp_get_options(pathToFetch, {method: 'POST', body: JSON.stringify(newRiskPayload)})
                .then(jsonData => {
                    this.riskMeta.setJob(jsonData)
                    return jsonData.system.id
                }).then(jobId => this.monitorJob(jobId)).then(() => this.setState({wizardPage: 2}))
        } else {
            return this.monitorJob(this.riskMeta.jobJson.system.id).then(() => this.setState({wizardPage: 2}))
        }
    }

    showRiskWizard() {
        if (this.state.wizardPage === 0) {
            return (<MLPRiskDataSets onDataSet={this.selectDataSet}/>)
        } else if (this.state.wizardPage === 1) {
            return (<MLPRiskSettings meta={this.riskMeta} jobName={this.jobName}/>)
        } else if (this.state.wizardPage === 2) {
            return (<MLPRiskRun meta={this.riskMeta}/>)
        } else if (this.state.wizardPage === 3) {
            return (<MLPRiskPredict meta={this.riskMeta}/>)
        } else {
            window.location.href = "/main";
            return true;
        }
    }

    componentDidMount() {
        this._ismounted = true;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    monitorJob(jobId) {
        if (this._ismounted) {
            const pathToFetch = SERVER_API_URL + '/v1/jobs/' + jobId
            return mlp_get(pathToFetch)
                .then(async jsonData => {
                    if (jsonData.system.status === "pending" || jsonData.system.status === "running") {
                        setTimeout(() => this.monitorJob(jobId), 5000)
                    }
                    this.riskMeta.setJob(jsonData);
                    this.setState(this.state);
                })
        }
    }

    render() {
        if (!isLoggedInUser()) {
            window.location.href = "/"
        }
        return this.showRisk()
    }
}