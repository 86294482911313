import React from "react";
import { Accordion, AccordionItem, Button } from "@carbon/react";
import { SentimentsMeta } from "./SentimentsMeta";

import './sentiments.css'
import MLPSentimentsInsights from "./SentimentsInsights";
import MLPTopicsInsights from "./TopicsInsights";
import CaseView from "../common/CaseView";
import DataFields from "../datasets/DataFields";
import Close from "@carbon/icons-react/lib/close/32";

export default class ViewSentiment extends CaseView {

    meta = new SentimentsMeta()

    renderContent() {
        return <div className="mlp_sentiment_holder" id={"content_holder"}>

            <div className="mlp-sentiment-section">
                <div className="mlp-pointer"><Close onClick={this.props.onClose}/></div>
            </div>
            <div className="mlp-sentiment-section">
                <h2>{
                    this.meta.jobJson.object.name
                }</h2>
            </div>
            <div className="mlp-sentiment-section">
                <Accordion>
                    <AccordionItem className={"mlp_sent_accordion_insights"}
                        title={this.renderDatasetTitle()}
                        open={false}>
                        {
                            this.renderDatasetInfo()
                        }
                    </AccordionItem>
                    <AccordionItem className={"mlp_sent_accordion_insights"}
                        title={"Report"}
                        open={true}>
                        <MLPSentimentsInsights meta={this.meta} />
                    </AccordionItem>
                    <AccordionItem className={"mlp_sent_accordion_insights"} title={"Topics"}
                        open={true}>
                        <MLPTopicsInsights meta={this.meta} />
                    </AccordionItem>
                </Accordion>
            </div>
            <div className="mlp-sentiment-section">
                {this.showSharingInfo()}
            </div>

        </div>
    }
}