import React from "react";
import {
    StructuredListBody,
    StructuredListRow,
    StructuredListWrapper
} from "carbon-components-react/lib/components/StructuredList";
import "@carbon/charts/styles.css";
import {InlineLoading, Loading} from "@carbon/react";
import './sentiments.css'
import {CirclePackChart, WordCloudChart} from "@carbon/charts-react";

import DatasetTable from "../datasets/DataTable";
import MLPTopicComments from "./TopicComments";
import {trimTo} from "../index";
import {ViewAdapter} from "../common/ViewAdapter";

//https://charts.carbondesignsystem.com/react/?path=/story/simple-charts-donut--donut

export default class MLPTopicsInsights extends React.Component {

    jobDef = this.props.meta.jobJson
    jobId = this.jobDef.system.id
    viewAdapters = new ViewAdapter()

    hasTopicListener = false

    constructor(props, context) {
        super(props, context);
        this.state = {
            topicsView: null,
            topicWords: null,
            currentTopicData: null
        }
    }

    _onTopicCircle(data) {
        if (data.id !== undefined) {
            this.setState({
                currentTopicData: data
            })
        }
    }

    _showTopics() {
        if (this.state.topicsView && !this.props.meta.isJobRunning()) {
            const setTopicsRef = (drawer) => {
                if (drawer != null && !this.hasTopicListener) {
                    this.hasTopicListener = true
                    drawer.chart.services.events.addEventListener("circle-leaf-click", e => {
                        this._onTopicCircle(e.detail.datum.data);
                    })
                }
            }

            return (<CirclePackChart
                data={this.state.topicsView}
                ref={setTopicsRef}
                options={{
                    "title": "Topics detection - from texts",
                    "resizable": true,
                    "height": "600px",
                    "width": "800px",
                    "canvasZoom": {
                        "enabled": true
                    }
                }}
            ></CirclePackChart>)
        } else {
            this.viewAdapters.registerViewListener('/v1/jobs/' + this.jobId + "/outputs/output_topic_chart4.json",
                (view) => {
                    const topics = view.map(topic => {
                        topic.children = topic.children.map(child => {
                            child.value = Math.round(child.value * Math.random())
                            return child
                        })
                        return topic
                    })
                    this.setState({topicsView: topics})
                }
            )
            return (
                <div className={"mlp_loading_graph_holder_600_400"}><Loading description="loading sentiments topics"
                                                                             withOverlay={false}/>
                </div>)
        }
    }

    _showTopicsTexts() {
        if (this.state.topicWords && !this.props.meta.isJobRunning()) {
            return (
                <WordCloudChart
                    data={this.state.topicWords}
                    options={{
                        "title": "Topics detection - from texts",
                        "resizable": true,
                        "height": "400px",
                        "width": "600px",
                        "canvasZoom": {
                            "enabled": true
                        }
                    }}
                ></ WordCloudChart>)
        } else {
            this.viewAdapters.registerViewListener('/v1/jobs/' + this.jobId + "/outputs/output_topic_chart5.json",
                (view) => this.setState({topicWords: view}))
            return (
                <div className={"mlp_loading_graph_holder_600_400"}><Loading
                    description="loading sentiments topics words"
                    withOverlay={false}/>
                </div>)
        }
    }

    _showTopicsComments() {
        return (<MLPTopicComments topicData={this.state.currentTopicData}/>)
    }

    _showTopicsDescription() {
        if (this.state.topicsView && !this.props.meta.isJobRunning()) {
            const topicsSummary = this.state.topicsView.map(topic => {
                const words = trimTo(45, topic.children.map(child => child.name).toString())
                return {
                    id: "tid_" + topic.name,
                    topic_name: trimTo(20, topic.name),
                    wordsList: words,
                    size: topic.count
                }
            })
            const currentTopicHeader = [
                {id: "id_t_Topic", key: "topic_name", header: "Topic"},
                {id: "id_t_Desc", key: "wordsList", header: "Relevant words"},
                {id: "id_t_Size", key: "size", header: "Size"}
            ]
            return (
                <DatasetTable hasPagination={false} renderToolbar={false} isSortable={false}
                              rowsData={topicsSummary}
                              headersData={currentTopicHeader}/>)
        } else {
            return (
                <div className={"mlp_loading_graph_holder_600_400"}><Loading description="loading sentiments topics"
                                                                             withOverlay={false}/>
                </div>)
        }
    }


    render() {
        if (this.props.meta.isJobFailed()) {
            return (
                <div className={"mlp_center"}><InlineLoading
                    description="This sentiments insight failed with this setup. This will ring a bell on our end."
                    status='error'/></div>
            )
        } else return (
            <StructuredListWrapper>
                <StructuredListBody>
                    <StructuredListRow>
                        <div className={"mlp_sent_grid_1 mlp_chart_grid"}>
                            {this._showTopics()}
                            {this._showTopicsComments()}
                        </div>
                    </StructuredListRow>
                    <StructuredListRow>
                        <div className={"mlp_grid_1_2 mlp_chart_grid"}>
                            <div>{this._showTopicsDescription()}</div>
                            <div>{this._showTopicsTexts()}</div>
                        </div>
                    </StructuredListRow>
                </StructuredListBody>
            </StructuredListWrapper>
        )
    }
}
