import React from "react";

import { mlp_put, SERVER_API_URL } from "../index";
import { Button } from "@carbon/react";

import SharingInfo from "./SharingInfo";

export default class JobSharing extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            ready: false
        };

    }

    permUpdated = (sharing) => {
        this.props.meta.setSharing(this.toAPISharing(sharing))
    }

    toAPISharing = (sharing) => {
        return sharing.map(s => {
            if (s.perms === null) {
                s.perms = [false, false]
            }
            let perms = ["manage", "read"].filter((n, i) => s.perms[i])
            return {
                "user": s.user,
                "permissions": perms
            }
        })
    }

    saveSharing = () => {
        mlp_put(SERVER_API_URL + '/v1/jobs/' + this.props.meta.jobId() + "/sharing", this.props.meta.getSharing())
            .then(jsonData => {
                if (jsonData.object !== undefined) {
                    this.setState({})
                }
            });
    }



    render() {
        let sharing = this.props.meta.getSharing()
        if (sharing.length === 0) {
            return <div className="mlp-sharing"></div>
        }
        return <div className="mlp-sharing">
            <h4>Sharing</h4>
            <SharingInfo
                className="mlp-sharing-box"
                system={this.props.meta.jobJson.system}
                sharing={this.props.meta.jobJson.object.sharing}
                permissionsLabels={["Manage", "Read"]}
                permissions={["manage", "read"]}
                onUpdate={this.permUpdated} />
            <Button kind="secondary" onClick={this.saveSharing}>Save sharing</Button>
        </div>
    }
}