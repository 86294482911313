import React from "react";
import MLPHeader from "../header/Header";
import MLPFooter from "../footer/Footer";

import './users.css'

import { isLoggedInUser, mlp_post, mlp_get, mlp_delete, SERVER_API_URL, mlp_patch } from "../index";
import { BsCheckCircle, BsExclamationCircle, BsList } from 'react-icons/bs';
import { OverflowMenu, OverflowMenuItem, } from "carbon-components-react";
import { Notification } from "../components/Notifications"
import {
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableSelectRow,
    TableSelectAll,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Checkbox,
    Button,
    Link,
    TextInput,
    ToastNotification
} from "@carbon/react";
import TrashCan from "@carbon/icons-react/lib/trash-can/20";
import EditUser from "./edituser";


export default class UsersPage extends React.Component {

    render() {
        return (
            <div className="mlp_content_holder">
                <MLPHeader withAccount={true} subCat={"Users management"} />
                <br />
                <div className="mlp_center"><Users /></div>
                <MLPFooter />
            </div>
        )
    }
}

class Users extends React.Component {
    constructor(props, context) {
        super(props);
        this.state = {
            userDeleted: false,
            userSaved: false,
            users: [],
            showEditUser: false
        };

        this.searchUser = React.createRef("");
        this.editingUser = {
            "id": undefined,
            "user": "",
            "permissions": {
                "admin": false,
                "manage_jobs": false,
                "manage_datasets": false,
                "manage_models": false,
                "enable_models": false,
                "approve_models": false,
                "move_models": false,
                "predict": false,
            }
        };

        this.fetchUsers();

        this.headerData = [
            {
                header: "User",
                key: "user"
            },
            {
                header: "Admin",
                key: "admin"
            },
            {
                header: "Manage jobs",
                key: "manage_jobs"
            },
            {
                header: "Manage datasets",
                key: "manage_datasets"
            },
            {
                header: "Manage models",
                key: "manage_models"
            },
            {
                header: "Enable models",
                key: "enable_models"
            },
            {
                header: "Approve models",
                key: "approve_models"
            },
            {
                header: "Move_models",
                key: "move_models"
            },
            {
                header: "Predict",
                key: "predict"
            },
        ]


    }
    showUserDialog = (open) => {
        this.setState({
            showEditUser: open,
        })
    }

    saveUser = (userName) => {
        let obj = this.editingUser;

        if (userName !== undefined) {
            obj.user = userName;
            mlp_post(SERVER_API_URL + '/v1/users', obj)
                .then(jsonData => {
                    if (jsonData.object !== undefined) {
                        this.showUserDialog(false);
                        this.fetchUsers();
                        this.userSaveAck();
                    }
                });
        } else {
            const update = [
                { "op": "replace", "path": "/permissions/admin", "value": obj.permissions.admin },
                { "op": "replace", "path": "/permissions/manage_jobs", "value": obj.permissions.manage_jobs },
                { "op": "replace", "path": "/permissions/manage_datasets", "value": obj.permissions.manage_datasets },
                { "op": "replace", "path": "/permissions/manage_models", "value": obj.permissions.manage_models },
                { "op": "replace", "path": "/permissions/enable_models", "value": obj.permissions.enable_models },
                { "op": "replace", "path": "/permissions/approve_models", "value": obj.permissions.approve_models },
                { "op": "replace", "path": "/permissions/move_models", "value": obj.permissions.move_models },
                { "op": "replace", "path": "/permissions/predict", "value": obj.permissions.predict },
            ];
            mlp_patch(SERVER_API_URL + '/v1/users/' + obj.id, update)
                .then(jsonData => {
                    if (jsonData.object !== undefined) {
                        this.showUserDialog(false);
                        this.fetchUsers();
                        this.userSaveAck();
                    }
                }); 
        }
    }

    showPerm = (value) => {
        if (value) {
            return <BsCheckCircle style={{ color: 'green', fontSize: "1.6em" }} />
        }
        return <div />
    }
    cellContent(row, cell) {
        if (cell.id.endsWith(":user")) {
            return <TableCell key={cell.id}>
                {cell.value}
            </TableCell>
        }

        return <TableCell key={cell.id} >
            <div className="mlp_users_checkbox">
                {
                    this.showPerm(cell.value)
                }
            </div>
        </TableCell>
    }

    fetchUsers = () => {
        const pathToFetch = SERVER_API_URL + '/v1/users?most_recents=true'
        mlp_get(pathToFetch, false)
            .then(jsonData => {
                let users = []

                for (let m of jsonData.data) {
                    let u = {
                        "id": m.system.id,
                        "user": m.object.user
                    }

                    Object.keys(m.object.permissions).forEach(k => {
                        u[k] = m.object.permissions[k]
                    })

                    users.push(u)
                }

                this.setState({
                    users: users
                })

            });
    }

    addNewUser = () => {
        this.editingUser = {
            "id": undefined,
            "user": "",
            "permissions": {
                "admin": false,
                "manage_jobs": false,
                "manage_datasets": false,
                "manage_models": false,
                "enable_models": false,
                "approve_models": false,
                "move_models": false,
                "predict": false,
            }
        }
        this.showUserDialog(true)
    }

    onDelete = (rows) => {
        if (rows.length === 0) {
            return
        }

        let qs = "ids="
        rows.forEach(r => {
            qs += r.id + ","
        })
        qs = qs.substring(0, qs.length - 1);


        const pathToFetch = SERVER_API_URL + '/v1/users?' + qs

        mlp_delete(pathToFetch)
            .then(response => {
                this.fetchUsers()
                this.userDeletedAck()
            });
    }

    renderContent = () => {
        return <DataTable
            rows={this.state.users}
            headers={this.headerData}
            isSortable>
            {({ rows, headers, getHeaderProps, getRowProps, getTableProps, getSelectionProps, selectedRows, onInputChange }) => (
                <TableContainer title={this.props.tableTitle ? this.props.tableTitle : ""}>
                    <TableToolbar>
                        <TableToolbarContent>
                            <TableToolbarSearch onChange={onInputChange} />
                            <div className="mlp_delete_selected">
                                <Link onClick={() => this.onDelete(selectedRows)}><TrashCan /></Link>
                            </div>
                            <Button small="true" kind="primary" onClick={this.addNewUser}>
                                New user
                            </Button>
                        </TableToolbarContent>
                    </TableToolbar>

                    <Table {...getTableProps()}>
                        <TableHead>

                            <TableRow>
                                <TableSelectAll {...getSelectionProps({})} />
                                {headers.map((header) => (
                                    <TableHeader {...getHeaderProps({ header })}>
                                        {header.header}
                                    </TableHeader>
                                ))}
                                <TableHeader>Action</TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.id}>
                                    <TableSelectRow {...getSelectionProps({ row })} />
                                    {row.cells.map((cell) => {
                                        return this.cellContent(row, cell)
                                    })}
                                    <TableCell>
                                        <OverflowMenu ariaLabel="overflow-menu">
                                            <OverflowMenuItem itemText="Edit" onClick={
                                                () => {
                                                    this.editingUser = {
                                                        "id": row.id,
                                                        "user": row.cells[0].value,
                                                        "permissions": {
                                                            "admin": row.cells[1].value,
                                                            "manage_jobs": row.cells[2].value,
                                                            "manage_datasets": row.cells[3].value,
                                                            "manage_models": row.cells[4].value,
                                                            "enable_models": row.cells[5].value,
                                                            "approve_models": row.cells[6].value,
                                                            "move_models": row.cells[7].value,
                                                            "predict": row.cells[8].value,
                                                        }
                                                    }

                                                    this.showUserDialog(true)
                                                }
                                            } />
                                        </OverflowMenu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </DataTable>

    }

    userSaveAck() {
        this.setState({
            userSaved: true,
        }, () =>
            setTimeout(() => {
                this.setState({
                    userSaved: false,
                })
            }, 3000)
        );
    }

    getEditUser = () => {
        return this.editingUser
    }

    setEditPermission = (id, checked) => {
        this.editingUser.permissions[id] = checked
    }

    userDeletedAck() {
        this.setState({
            userDeleted: true,
        }, () =>
            setTimeout(() => {
                this.setState({
                    userDeleted: false,
                })
            }, 3000)
        );
    }
    render() {
        return <div className="mlp_users_holder" id={"content_holder"}>
            <Notification
                text="User(s) deleted"
                notification={this.state.userDeleted}
            />
            <Notification
                text="User saved"
                notification={this.state.userSaved}
            />

            <div className="mlp_users_content">
                {this.renderContent()}
            </div>

            <EditUser
                open={this.state.showEditUser}
                close={() => this.showUserDialog(false)}
                saveUser={this.saveUser}
                user={this.getEditUser}
                setPerm={this.setEditPermission}
            />

        </div>


    }
}