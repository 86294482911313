import React from "react";

import './datasets.css'
import { TextArea, Modal, Button, Loading, Toggletip, ToggletipButton, ToggletipContent } from "@carbon/react";
import DatasetCard from "./DataSetCard";
import MLPCard from "../components/Card";
import DatasetDetails from "./DataSetDetails";
import { isLoggedInUser, mlp_put, mlp_post, mlp_get, mlp_get_options, SERVER_API_URL, trimTo, valueOrNA } from "../index";
import DataSample from "./DataSample";
import Information from "@carbon/icons-react/lib/information/16";
import Check from "../components/Check.js";
import ShareTo from "../components/ShareTo";
import SharingInfo from "../components/SharingInfo";
import Close from "@carbon/icons-react/lib/close/32";
import DatasetSchemaInfo from "./DataSetSchemaInfo";

export default class Datasets extends React.Component {

    constructor(props, context) {
        super(props, context);
        this._isMounted = false;
        this.state = {
            newWizard: false,
            editDataSet: undefined,
            dataSets: undefined,
            infoOn: undefined,
            deleteDs: undefined,
            shareDs: undefined,
        };
        this.useDataSetClick = this.useDataSetClick.bind(this);
        this.fetchDataSets = this.fetchDataSets.bind(this);
        this.onDatasetChange = this.onDatasetChange.bind(this);
        this.postCreate = this.postCreate.bind(this);
        this.onEditDataSet = this.onEditDataSet.bind(this);
        this.onViewDataSet = this.onViewDataSet.bind(this);
        this.deleteDataset = this.deleteDataset.bind(this);
        this._sampleHeader = this._sampleHeader.bind(this)

        this.sharing = []
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    useDataSetClick(dsId) {
        if (this.state.editDataSet === undefined) {
            const pathToFetch = SERVER_API_URL + '/v1/datasets/' + dsId
            mlp_get(pathToFetch)
                .then(async jsonData => {
                    if (this.props.onDataSet) this.props.onDataSet(dsId);
                });
        }
    }

    onDatasetChange() {
        this.setState({ dataSets: undefined });// rendering progress
        this.fetchDataSets()
    }

    deleteDataset() {
        const pathToFetch = SERVER_API_URL + '/v1/datasets/' + this.state.deleteDs.system.id
        mlp_get_options(pathToFetch, { method: 'DELETE' }).then(this.fetchDataSets);
    }

    fetchDataSets() {
        const pathToFetch = SERVER_API_URL + '/v1/datasets'
        mlp_get(pathToFetch)
            .then(async jsonData => {
                this.setState({
                    dataSets: jsonData.data,
                    deleteDs: undefined,
                    infoOn: undefined
                });
            });
    }

    _sampleHeader(header) {
        if (this.state.infoOn.object.schema.fields !== null) {
            const fieldInfo = this.state.infoOn.object.schema.fields.find(field => field.name === header)
            return (
                <div className={"mlp_grid_1_2"}>{trimTo(15, header)}&nbsp;
                    <Toggletip align={"bottom"}>
                        <ToggletipButton label="Additional information">
                            <Information />
                        </ToggletipButton>
                        <ToggletipContent>
                            <div className={"mlp_grid_1_2 mlp_grid_1_2_nogap mlp_10_width"}>
                                <b>Field Type:</b><b className={"mlp_text_bold"}>{valueOrNA(fieldInfo.data_type)}</b>
                                <b>Count:</b><b
                                    className={"mlp_text_bold"}>{valueOrNA(fieldInfo.summary_stats.count)}</b>
                                <b>Distinct:</b><b
                                    className={"mlp_text_bold"}>{valueOrNA(fieldInfo.summary_stats.count_unique)}</b>
                                <b>Missing:</b><b
                                    className={"mlp_text_bold"}>{valueOrNA(fieldInfo.summary_stats.count_missing)}</b>
                                <b>Max:</b><b className={"mlp_text_bold"}>{valueOrNA(fieldInfo.summary_stats.max)}</b>
                                <b>Min:</b><b className={"mlp_text_bold"}>{valueOrNA(fieldInfo.summary_stats.min)}</b>
                            </div>
                        </ToggletipContent>
                    </Toggletip>
                </div>)
        } else {
            return undefined;
        }
    }

    close = () => {
        this.setState({
            infoOn: undefined
        })
    }
    toAPISharing = (sharing) => {
        return sharing.map(s => {
            if (s.perms === null) {
                s.perms = [false, false]
            }
            let perms = ["manage", "read"].filter((n, i) => s.perms[i])
            return {
                "user": s.user,
                "permissions": perms
            }
        })
    }

    permUpdated = (sharing) => {
        this.sharing = this.toAPISharing(sharing)
    }

    save = () => {
        mlp_put(SERVER_API_URL + '/v1/datasets/' + this.state.infoOn.system.id + "/sharing", this.sharing)
            .then(jsonData => {
                if (jsonData.object !== undefined) {
                    this.setState({})
                }
            });
    }

    renderBack = () => {
        return <div className="mlp-dataset-section">
            <div className="mlp-pointer"><Close onClick={this.close} /></div>
        </div>
    }

    renderSharing = () => {
        if (this.props.hideControls ||
            this.state.infoOn.object.sharing === null ||
            this.state.infoOn.object.sharing.length === 0) {
            return <div />
        }
        return <div className="mlp-dataset-section-fit">
            <h4>Sharing</h4>
            <SharingInfo
                className="mlp-dataset-sharing-box"
                system={this.state.infoOn.system}
                sharing={this.state.infoOn.object.sharing}
                permissionsLabels={["Manage", "Read"]}
                permissions={["manage", "read"]}
                onUpdate={this.permUpdated} />
            <Button kind="secondary" onClick={this.save}>Save</Button>
        </div>
    }
    _renderInfoOn() {
        if (this.state.infoOn !== undefined) {
            return (
                <div className="mlp-dataset-view">
                    {
                        this.renderBack()
                    }
                    <div className="mlp-dataset-section">
                        <h4>Dataset <b className={"mlp_text_bold"}>{trimTo(30, this.state.infoOn.object.name)}</b>
                        </h4>
                    </div>
                    <div className="mlp-dataset-section">
                        <b className={"mlp_text_bold"}>Author:  </b><span>{trimTo(30, this.state.infoOn.system.author)}</span>
                    </div>
                    <DataSample
                        onClose={this.close}
                        dataSet={this.state.infoOn}
                        headerOf={(header) => this._sampleHeader(header)} />

                    <div className="mlp-dataset-section">

                        <h4>Description</h4>
                        <div className="mlp-dataset-field-holder">
                            <TextArea
                                id="ds_desc"
                                labelText=""
                                type="text"
                                disabled={this.props.hideControls}
                                defaultValue={this.state.infoOn.object.description}
                            />
                        </div>

                    </div>
                    <DatasetSchemaInfo
                        dataSet={this.state.infoOn}
                        hideControls={true} />
                    {this.renderSharing()}
                </div>
            )
        } else return (<div />)
    }

    shareDataset = (dataset) => {

    }

    showDataSets() {
        if (this.state.infoOn !== undefined) {
            return <div />
        }
        if (this.state.dataSets == undefined) {
            this.fetchDataSets();
            return (<div className={"mlp_center"}>
                <Loading description="loading datasets" withOverlay={true} />
            </div>)
        } else {
            let pending = false;
            return (<div className="mlp_datasets_container">
                {
                    this.state.dataSets.map((dataset) => {
                        if (!pending && (dataset.system.status === "pending" || dataset.system.status === "running")) {
                            setTimeout(() => {
                                if (this._isMounted) this.fetchDataSets()
                            }, 5000)
                            pending = true;
                        }
                        return (<DatasetCard dataset={dataset} key={dataset.system.id}
                            onClick={this.useDataSetClick}
                            onDelete={(ds) => {
                                this.setState({ deleteDs: ds })
                            }}
                            onShare={(ds) => {
                                this.setState({ shareDs: ds })
                            }}
                            onView={this.onViewDataSet}
                            onEdit={this.onEditDataSet} />)
                    })
                }
            </div>)
        }
    }

    postCreate(response) {
        this.setState({
            newWizard: false,
            editDataSet: undefined,
            dataSets: undefined
        })
    }

    onViewDataSet(dataset) {
        this.setState({
            infoOn: dataset,
        })
    }
    onEditDataSet(dataset) {
        this.setState({
            newWizard: true,
            editDataSet: dataset
        })
    }

    _renderNewDataset() {
        if (this.state.infoOn !== undefined) {
            return <div />
        }
        if (this.state.newWizard === true) {
            if (this.props.onNewDataset !== undefined) {
                this.props.onNewDataset()
            }
            return (<MLPCard styleClass="mlp_center mlp_card_info mlp_new_datasource_card mlp_card_info_nohelp">
                <DatasetDetails editDataSet={this.state.editDataSet}
                    postCreate={this.postCreate}></DatasetDetails>
            </MLPCard>)
        } else if (isLoggedInUser()) {
            return (
                <Check perms="manage_datasets">
                    <div className={"mlp_new_ds_button"}>
                        <Button kind={"primary"} onClick={() => {
                            this.setState({
                                newWizard: true,
                                infoOn: undefined
                            })
                        }}><p>Define new dataset</p></Button>
                    </div>
                </Check>
            )
        } else return (<br />)
    }

    saveSharing = (id, object) => {
        const payload = [
            object,
        ];
        mlp_post(SERVER_API_URL + '/v1/datasets/' + id + "/sharing", payload)
            .then(jsonData => {
                if (jsonData.object !== undefined) {
                    this.setState({ shareDs: undefined })
                }
            });


    }

    renderShareTo = () => {
        if (this.state.shareDs !== undefined) {
            return <ShareTo
                open={true}
                close={() => this.setState({ shareDs: undefined })}
                title={"Share dataset " + this.state.shareDs.object.name}
                labels={["Manage", "Read"]}
                names={["manage", "read"]}
                sharing={
                    {
                        "id": this.state.shareDs.system.id,
                        "user": "",
                        "permissions": []
                    }
                }
                save={this.saveSharing}
            />
        }
        return <div />
    }

    render() {
        return (
            <div className={"mlp_datasets_body"}>
                {this._renderNewDataset()}
                {this.showDataSets()}
                {this._renderInfoOn()}

                <Modal
                    modalHeading={"Are you sure you want to delete " + trimTo(16, this.state.deleteDs != undefined ? this.state.deleteDs.object.name : "this") + " dataset?"}
                    open={this.state.deleteDs !== undefined}
                    size="xs"
                    onRequestClose={e => this.setState({ deleteDs: undefined })}
                    onRequestSubmit={this.deleteDataset}
                    primaryButtonText="Yes"
                    secondaryButtonText="No"
                    alert={true}
                    danger={true}
                ></Modal>

                {
                    this.renderShareTo()
                }

            </div>
        )
    }
}