import React from "react";
import './churn.css'

import {Tab, TabList, TabPanel, TabPanels, Tabs, TextInput} from "@carbon/react";
import DataFields from "../datasets/DataFields";
import MLPChurnSetup from "./Setup";
import MLPTextWithMore from "../components/TextWithMore";

export default class MLPChurnSettings extends React.Component {

    allRoles = [
        "customer_feature",
        "ignore",
        "customer_id",
        "product",
        "price",
        "discount",
        "quantity",
        "transaction_id",
        "transaction_time"
    ];

    churnFieldsDescription() {
        return (
            <div className={"mlp_churn_dataset_description"}>
                <MLPTextWithMore above={
                    <h6>Please provide some hints about your input data structure. These would be used for a better
                        understanding of the data and to produce more accurate churn analysis results.</h6>
                }>
                    <div>Select at least the following roles of the fields from the selected datasource:
                        <br/>
                        <li>[customer_id]<b className={"mlp_italic_text"}> - Customer Identifier</b> - field used to
                            identify customers - one
                        </li>
                        <li>[customer_feature]<b className={"mlp_italic_text"}> - Customer Feature</b> - feature
                            role(default) - zero or more
                        </li>
                        <li>[product]<b className={"mlp_italic_text"}> - Product</b> - field to identify the product
                            role - one
                        </li>
                        <li>[price]<b className={"mlp_italic_text"}> - Price</b> - field to identify the product price
                            role - one
                        </li>
                        <li>[discount]<b className={"mlp_italic_text"}> - Discount</b> - field to identify the product
                            discount role (if applicable) - zero or more
                        </li>
                        <li>[quantity]<b className={"mlp_italic_text"}> - Quantity</b> - field to identify the quantity
                            purchased - zero or more
                        </li>
                        <li>[transaction_id]<b className={"mlp_italic_text"}> - Transaction Identifier</b> - field used
                            to identify
                            customers purchase transactions - one
                        </li>
                        <li>[transaction_time]<b className={"mlp_italic_text"}> - Transaction Time</b> - field used to
                            identify
                            transaction time - one
                        </li>
                        <li>[ignore]<b className={"mlp_italic_text"}> - Ignore</b> - field to be ignored - zero or more
                        </li>
                    </div>
                </MLPTextWithMore>
                <br/>
            </div>
        )
    }

    getJobName() {
        return this.props.jobName.current === null ? this.props.meta.jobName() : this.props.jobName.current.value
    }

    render() {

        return (
            <div className={"mlp_churn_tabs_holder"}>

                <Tabs>
                    <TabList aria-label="List of tabs" light={true} iconSize={"lg"}
                             className={"mlp_churn_tabs_list_holder"}>
                        <Tab className={"mlp_churn_tab_holder"}>1. Setup</Tab>
                        <Tab disabled className={"mlp_churn_tab_holder"}>2. Run</Tab>
                        <Tab disabled className={"mlp_churn_tab_holder"}>3. Predict</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <div className={"mlp_churn_setup_tab"}>
                                <div className={"mlp_padding_1rem"}>
                                    <TextInput
                                        readOnly={this.props.meta.jobId() !== undefined}
                                        className={"mlp_padding_1rem"}
                                        ref={this.props.jobName}
                                        labelText={this.props.meta.jobId() !== undefined ? "Churn job name" : "Please provide a name for this job"}
                                        helperText=""
                                        id={"churn_job_name"}
                                        invalidText="You must provide a name for Churn job"
                                        placeholder={this.getJobName()}/>
                                    <DataFields caseMeta={this.props.meta} roles={this.allRoles}
                                                   rolesExplanation={"See the above information for mandatory roles selection."}
                                                   description={this.churnFieldsDescription()}/></div>
                                    <MLPChurnSetup meta={this.props.meta}/>

                            </div>
                        </TabPanel>
                        <TabPanel/>
                        <TabPanel/>
                    </TabPanels>
                </Tabs>
            </div>
        )
    }
}