import React from "react";
import {Dropdown} from "carbon-components-react";
import {Button, TextInput} from "@carbon/react";
import {mlp_get_options, SERVER_API_URL} from "../index";
import {displayRequiredAction} from "../components/Notifications";
import MLPFileUploader from "../components/Uploader";


export default class DatasetDetails extends React.Component {

    fileNamePath = null

    constructor(props, context) {
        super(props, context);
        this.state = {
            dsType: null
        };
        this.createNewDataset = this.createNewDataset.bind(this);
        this.onUpload = this.onUpload.bind(this);

        this.ref_s3_access_key_id = React.createRef();
        this.ref_s3_secret_access_key = React.createRef();
        this.ref_s3_endpoint_url = React.createRef();
        this.ref_s3_bucket = React.createRef();
        this.ref_s3_file = React.createRef();
        this.ref_s3_region = React.createRef();
        this.ref_dataset_name = React.createRef();
        this.ref_dataset_description = React.createRef();
        this.ref_type = React.createRef();
    }

    onUpload(fileNamePath) {
        this.fileNamePath = fileNamePath;
    }

    datasetParameters() {
        if (this.state.dsType === "s3") {
            return {
                "access_key_id": this.ref_s3_access_key_id.current.value,
                "bucket": this.ref_s3_bucket.current.value,
                "file": this.ref_s3_file.current.value,
                "secret_access_key": this.ref_s3_secret_access_key.current.value,
                "region_name": this.ref_s3_region,
                "endpoint_url": this.ref_s3_endpoint_url,
            }
        } else {
            const path = this.fileNamePath
            return {
                "file": path,
                "uploaded_file": path.substr(path.lastIndexOf('/') + 1)
            }
        }
    }

    createNewDataset() {
        if (this.ref_dataset_name.current.value === null) {
            displayRequiredAction("Please fill in dataset name");
            return false;
        }

        if (this.state.dsType === null) {
            displayRequiredAction("You must select the data type");
            return false;
        }

        if (this.state.dsType === "s3") {
            if (this.ref_s3_access_key_id.current.value === "" || this.ref_s3_bucket.current.value === "" ||
                this.ref_s3_file.current.value === "" || this.ref_s3_secret_access_key.current.value === "") {
                displayRequiredAction("Please fill in all S3 input parameters");
                return false;
            }
        } else if (this.state.dsType === "file" && this.fileNamePath === null) {
            displayRequiredAction("Please upload a file in order to create a dataset");
            return false;
        }

        const createPayload = {
            "name": this.ref_dataset_name.current.value,
            "description": this.ref_dataset_description.current.value,
            "type": this.state.dsType,
            "parameters": this.datasetParameters()
        }

        const pathToFetch = SERVER_API_URL + '/v1/datasets'
        mlp_get_options(pathToFetch, {method: 'POST', body: JSON.stringify(createPayload)})
            .then(resp => this.props.postCreate(resp))
        return true;
    }

    labelOf(field) {
        if (this.props.editDataSet == null) {
            return "";
        }
        switch (field) {
            case "s3_access_key_id":
                return (this.props.editDataSet.object.parameters.access_key_id || "")
            case "s3_secret_access_key":
                return (this.props.editDataSet.object.parameters.secret_access_key || "")
            case "s3_endpoint_url":
                return (this.props.editDataSet.object.parameters.endpoint_url || "")
            case "s3_region":
                return (this.props.editDataSet.object.parameters.region || "")
            case "s3_bucket":
                return (this.props.editDataSet.object.parameters.bucket || "")
            case "s3_file":
                return (this.props.editDataSet.object.parameters.file || "")
            case "s3_region":
                return (this.props.editDataSet.object.parameters.region || "")
            case "dataset_name":
                return (this.props.editDataSet.object.name || "")
            case "dataset_description":
                return (this.props.editDataSet.object.description || "")

            default:
                return ""
        }
    }

    inputByDataSetType() {
        switch (this.state.dsType) {
            case "s3":
                return (<div className="mlp_dataset_upload_holder">
                    <TextInput
                        ref={this.ref_s3_access_key_id}
                        labelText=""
                        defaultValue={this.labelOf("s3_access_key_id")}
                        helperText="Cloud Storage (S3) access_key_id"
                        id={"s3_access_key_id"}
                        invalidText="Invalid s3 input"
                        placeholder={"s3_access_key_id"}/>
                    <TextInput
                        ref={this.ref_s3_secret_access_key}
                        labelText=""
                        defaultValue={this.labelOf("s3_secret_access_key")}
                        helperText="Cloud Storage (S3) secret_access_key"
                        id={"s3_secret_access_key"}
                        invalidText="Invalid s3 input"
                        placeholder={"secret_access_key"}/>
                    <TextInput
                        ref={this.ref_s3_endpoint_url}
                        labelText=""
                        defaultValue={this.labelOf("s3_endpoint_url")}
                        helperText="(Optional) Cloud Storage (S3) endpoint URL"
                        id={"s3_endpoint_url"}
                        invalidText="Invalid s3 input"
                        placeholder={"endpoint_url"}/>
                    <TextInput
                        ref={this.ref_s3_region}
                        labelText=""
                        defaultValue={this.labelOf("s3_region")}
                        helperText="(Optional) Cloud Storage (S3) Region Name"
                        id={"s3_region"}
                        invalidText="Invalid s3 input"
                        placeholder={"region"}/>
                    <TextInput
                        ref={this.ref_s3_bucket}
                        labelText=""
                        defaultValue={this.labelOf("s3_bucket")}
                        helperText="Cloud Storage (S3) bucket"
                        id={"s3_bucket"}
                        invalidText="Invalid s3 input"
                        placeholder={"bucket"}/>
                    <TextInput
                        ref={this.ref_s3_file}
                        labelText=""
                        defaultValue={this.labelOf("s3_file")}
                        helperText="Cloud Storage (S3) file (CSV format)"
                        id={"s3_file"}
                        invalidText="Invalid s3 input"
                        placeholder={"file"}/>
                </div>)
            case "file":
                return (<MLPFileUploader onUpload={this.onUpload}/>);
            default:
                return <div style={{marginBottom: '1.3rem'}}><br/></div>
        }
    }

    _getRealDs(dataset) {
        return (dataset.object.type === "s3" && dataset.object.parameters.uploaded_file) ? "file" : dataset.object.type;
    }

    dataTypeSelection() {
        if (!this.props.editDataSet) {
            return undefined;
        }
        let selectedItem;
        let dsType = this._getRealDs(this.props.editDataSet)
        switch (dsType) {
            case "file":
                selectedItem = {
                    "id": "file",
                    "label": "Uploaded File",
                    "value": "file",
                }
                break;
            case "s3":
                selectedItem = {
                    "id": "s3",
                    "label": "Cloud Storage (S3) bucket",
                    "value": "s3",
                }
                break;
            default:
                selectedItem = undefined;
        }

        if (selectedItem !== undefined && this.state.dsType === null) {
            this.setState({dsType: selectedItem.value})
        }
        return selectedItem;
    }

    render() {
        const actionText = this.props.editDataSet == null ? "Create dataset" : "Update dataset"
        return (
            <div className={"mlp_dataset_modal_holder"}>
                <br/>
                <div>
                    <TextInput
                        labelText={""}
                        ref={this.ref_dataset_name}
                        helperText="dataset name (max 8 characters)"
                        defaultValue={this.labelOf("dataset_name")}
                        id={"dataset_name"}
                        invalidText="Invalid dataset name"
                        placeholder={"Your dataset name"}/><br/>

                </div>
                <div>

                    <TextInput
                        ref={this.ref_dataset_description}
                        labelText={""}
                        helperText="optional description (max 16 characters)"
                        defaultValue={this.labelOf("dataset_description")}
                        id={"dataset_description"}
                        invalidText="Invalid dataset description"
                        placeholder={"Your dataset description"}/><br/>
                </div>
                <div className={"mlp_dataset_drop_holder"}>
                    <Dropdown
                        ariaLabel="Dataset type"
                        id="dataset_type"
                        initialSelectedItem={this.dataTypeSelection()}
                        items={[
                            {
                                "id": "file",
                                "label": "Uploaded File",
                                "value": "file",
                            },
                            {
                                "id": "s3",
                                "label": "Cloud Storage (S3) bucket",
                                "value": "s3",
                            },
                        ]}
                        label="Select dataset type"
                        titleText="Dataset type"
                        onChange={(selection) => this.setState({dsType: selection.selectedItem.value})}
                    />
                </div>
                <br/><br/>
                {this.inputByDataSetType()}
                <br/><br/>
                <div className={"mlp_datasets_actions"}>
                    <Button size='sm' onClick={this.props.postCreate}><p>Cancel</p></Button>&nbsp;&nbsp;&nbsp;
                    <Button size='sm' kind={"secondary"} onClick={this.createNewDataset}><p>{actionText}</p></Button>
                </div>
            </div>)
    }
}
