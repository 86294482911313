import React from "react";
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from 'carbon-components-react/lib/components/StructuredList';

import './churn.css'
import MLPChurnDataSets from "./DataSets";
import MLPChurnSettings from "./Settings";
import {Button, Loading} from "@carbon/react";
import {displayRequiredAction, displayWarnAction} from "../components/Notifications";
import MLPChurnRun from "./Run";
import MLPChurnPredict from "./Predict";
import {handleRestError, mlp_get, mlp_get_options, queryParams, SERVER_API_URL} from "../index";
import {ChurnMeta} from "./ChurnMeta";

export default class NewChurn extends React.Component {

    constructor(props) {
        super(props);
        this.dataSourceId = null;
        this.state = {
            wizardPage: 0
        };

        this.meta = new ChurnMeta()
        this.back = this.back.bind(this);
        this.showChurn = this.showChurn.bind(this);
        this.setDataSet = this.setDataSet.bind(this);
        this.selectDataSet = this.selectDataSet.bind(this);
        this.continueWizard = this.continueWizard.bind(this);
        this.jobName = React.createRef();
    }

    componentDidMount() {
        this._ismounted = true;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    back() {
        const qParams = queryParams()
        if (this.state.wizardPage === 0) {
            window.location.href = "/main";
            return true;
        } else if (qParams.id && qParams.id !== "" && this.state.wizardPage === 1) {
            window.location.href = "/main";
            return true;
        } else {
            return this.setState({wizardPage: this.state.wizardPage - 1})
        }
    }


    showChurn() {
        const qParams = queryParams()
        if (qParams.id && qParams.id !== "" && this.state.wizardPage === 0) {
            const pathToFetch = SERVER_API_URL + '/v1/jobs/' + qParams.id
            mlp_get(pathToFetch)
                .then(jsonData => {
                    this.meta.setJob(jsonData)
                    return jsonData.object.dataset_id;
                }).then((dsId) => this.setDataSet(dsId))
            return (<div><br/><br/><br/><Loading description="Getting churn information" withOverlay={false}/></div>);
        } else {
            return this.renderMLPChurn()
        }
    }

    setDataSet(dsId) {
        const pathToFetch = SERVER_API_URL + '/v1/datasets/' + dsId
        mlp_get(pathToFetch)
            .then(jsonData => {
                handleRestError(jsonData)
                this.meta.setDataSource(jsonData)
                this.dataSourceId = dsId
                this.setState({
                        wizardPage: 1,
                    }
                );

            });
    }


    selectDataSet(dsId) {
        this.dataSourceId = dsId
    }


    showChurnWizard() {
        if (this.state.wizardPage === 0) {
            return (<MLPChurnDataSets onDataSet={this.selectDataSet}/>)
        } else if (this.state.wizardPage === 1) {
            return (<MLPChurnSettings meta={this.meta} jobName={this.jobName}/>)
        } else if (this.state.wizardPage === 2) {
            return (<MLPChurnRun meta={this.meta}></MLPChurnRun>)
        } else if (this.state.wizardPage === 3) {
            return (<MLPChurnPredict meta={this.meta}></MLPChurnPredict>)
        } else {
            window.location.href = "/main";
            return true;
        }
    }

    monitorJob(jobId) {
        if (this._ismounted) {
            const pathToFetch = SERVER_API_URL + '/v1/jobs/' + jobId
            return mlp_get(pathToFetch)
                .then(async jsonData => {
                    if (jsonData.system.status === "pending" || jsonData.system.status === "running") {
                        setTimeout(() => this.monitorJob(jobId), 5000)
                    }
                    this.meta.setJob(jsonData);
                    this.setState(this.state);
                })
        }
    }

    startChurnJobIfNeeded() {
        if (this.meta.jobJson === null) {//we need to start a new churn
            const rolesValidation = this.meta.validateRoles()
            if (!rolesValidation.isValid) {
                return displayRequiredAction(rolesValidation.message)
            } else if (this.jobName.current.value === "") {
                return displayRequiredAction("Please provide a name for this job before you can continue")
            }
            console.log(this.jobName.current.value)

            const newJobRoles = this.meta.getRoles()
            const newJobDatasetId = this.meta.dataSourceJson.system.id
            const newChurnPayload = {
                "name": this.jobName.current.value,
                "class": "customer-churn",
                "dataset_id": newJobDatasetId,
                "roles": newJobRoles
            }

            const pathToFetch = SERVER_API_URL + '/v1/jobs'
            return mlp_get_options(pathToFetch, {method: 'POST', body: JSON.stringify(newChurnPayload)})
                .then(jsonData => {
                    this.meta.setJob(jsonData)
                    return jsonData.system.id
                }).then(jobId => this.monitorJob(jobId)).then(() => this.setState({wizardPage: 2}))
        } else {
            return this.monitorJob(this.meta.jobJson.system.id).then(() => this.setState({wizardPage: 2}))
        }
    }

    showButtonsWizard() {
        if (this.state.wizardPage < 3) {
            return (<div className={"mlp_center mlp_churn_nav"}>
                <div><Button kind={"secondary"} onClick={this.back}><p>Back</p></Button></div>
                <Button kind={"primary"} onClick={this.continueWizard}><p>Continue</p></Button>
            </div>)
        } else {
            return (<div className={"mlp_center mlp_churn_nav"}>
                <div className={""}><Button kind={"secondary"} onClick={this.back}><p>Back</p></Button></div>
                <Button kind={"primary"} onClick={this.continueWizard}><p>Done</p></Button>
            </div>)
        }
    }

    continueWizard() {
        if (this.state.wizardPage === 0) {
            return (this.dataSourceId == null) ? displayRequiredAction("Please select a data set in order to continue") : this.setDataSet(this.dataSourceId)
        } else if (this.state.wizardPage === 1) {
            return this.startChurnJobIfNeeded()
        } else if (this.state.wizardPage === 2 && !this.meta.isJobFailed()) {
            if (this.meta.isJobRunning()) {
                displayWarnAction("Please allow more time for this churn analysis in order to be able to use predict")
                return false;
            }
            return this.setState({wizardPage: 3});
        } else {
            window.location.href = "/main";
            return true;
        }
    }

    renderMLPChurn() {
        return (
            <div className="mlp_churn_holder" id={"content_holder"}>
                <StructuredListWrapper ariaLabel="Churn settings">
                    <StructuredListBody>
                        <StructuredListRow>
                            <StructuredListCell>
                                <br/>
                                {this.showChurnWizard()}
                            </StructuredListCell>
                        </StructuredListRow>
                        {this.showButtonsWizard()}
                    </StructuredListBody>
                </StructuredListWrapper>
            </div>
        )
    }

    render() {
        return (<div className="mlp_center mlp_new_width">{this.showChurn()}</div>)
    }
}

