import React from "react";

import {ChurnMeta} from "./ChurnMeta";
import {Accordion, AccordionItem, Button} from "@carbon/react";
import MLPChurnML from "./ML";
import MLPChurnPredict from "./Predict";
import MLPDataInsights from "./DataInsights";
import MLPChurnInsights from "./ChurnInsights";
import CaseView from "../common/CaseView";
import Close from "@carbon/icons-react/lib/close/32";

export default class ViewChurn extends CaseView {

    meta = new ChurnMeta()

    renderContent() {
        return <div className="mlp-churn-content">
            <div className="mlp-churn-section">
                <div className="mlp-pointer"><Close onClick={this.props.onClose}/></div>
            </div>
            <div className="mlp-sentiment-section">
                <h2>{
                    this.meta.jobJson.object.name
                }</h2>
            </div>
            <div className="mlp-churn-section">
                <Accordion>
                    <AccordionItem className={"mlp_churn_accordion_insights"}
                                   title={this.renderDatasetTitle()}
                                   open={false}>
                        {this.renderDatasetInfo()}
                    </AccordionItem>
                    <AccordionItem className={"mlp_churn_accordion_insights"} title={"Report"}
                                   open={true}>
                        <MLPChurnInsights meta={this.meta}/>
                        <MLPDataInsights meta={this.meta}/>
                        <MLPChurnPredict meta={this.meta} view={true}/>
                    </AccordionItem>
                    <AccordionItem className={"mlp_churn_accordion_insights"} title={"Machine learning"}
                                   open={true}>
                        <MLPChurnML meta={this.meta}/>
                    </AccordionItem>
                </Accordion>
            </div>
            <div className="mlp-churn-section">
                {this.showSharingInfo()}
            </div>
        </div>
    }
}