import React from "react";


import { Button, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "carbon-components-react";
import { getPermissions, getUser } from "../common/utils";
import TrashCan from "@carbon/icons-react/lib/trash-can/20";
import CheckboxChecked from "@carbon/icons-react/lib/checkbox--checked/24";
import Checkbox from "@carbon/icons-react/lib/checkbox/24";
import { isShared } from "../common/utils";

export default class SharingInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sharing: [],
        }
        this.state.sharing = this.buildSharing()
    }

    permissionToIdx = (p) => {
        for (let i = 0; i < this.props.permissions.length; i++) {
            if (this.props.permissions[i] === p) {
                return i
            }
        }
        return -1
    }

    buildSharing = () => {
        let res = []
        if (this.props.sharing === null) {
            return res
        }
        this.props.sharing.forEach(s => {
            let perms = new Array(this.props.permissions.length);
            perms = perms.fill(false, 0)
            if (s.permissions === null) {
                s.permissions = []
            }
            s.permissions.forEach(p => {
                perms[this.permissionToIdx(p)] = true
            });
            res.push({
                user: s.user,
                perms: perms
            })
        });
        return res
    }

    isShared = () => {
        let user = getUser()
        return (user !== undefined && user !== this.props.system.author)
    }
   

    canManage = () => {
        let perms = getPermissions()
        if (!(perms.admin || perms.manage_models)) {
            return false;
        }
        if (isShared()) {
            return this.state.sharing[0].perms[0]
        }
        return true
    }

    updateSharing = (user, permIdx, value) => {
        let s = this.state.sharing.map(s => {
            if (s.user === user) {
                s.perms[permIdx] = value
            }
            return s
        })
        this.props.onUpdate(s)
        return s
    }

    renderPermCheck = (sharing, idx) => {
        if (sharing.perms[idx]) {
            return <div className="mlp-clickable" onClick={() => {
                this.setState({
                    sharing: this.updateSharing(sharing.user, idx, false)
                })
                return false
            }
            }><CheckboxChecked /></div>
        }
        return <div className="mlp-clickable" onClick={() => {
            this.setState({
                sharing: this.updateSharing(sharing.user, idx, true)
            })
            return false;
        }
        }><Checkbox /></div>
    }

    deleteUserSharing = (user) => {
        let s = this.state.sharing.filter(e => {
            return e.user !== user
        })
        this.props.onUpdate(s)
        this.setState({
            sharing: s
        })
    }

    render() {
        let user = getUser()

        let sharing = this.state.sharing;
        return <div className={this.props.className}>
            <Table>
                <TableHead>

                    <TableRow>
                        <TableHeader>User</TableHeader>
                        {
                            this.props.permissionsLabels.map(p => <TableHeader key={p}>{p}</TableHeader>)
                        }
                        <TableHeader>Actions</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        sharing.map((s, i) => {
                            return <TableRow key={"row_" + i}>
                                <TableCell>
                                    {s.user}
                                </TableCell>
                                {
                                    this.props.permissions.map((p, i) => {
                                        return <TableCell key={p + i}>
                                            {
                                                this.renderPermCheck(s, i)
                                            }
                                        </TableCell>
                                    })
                                }
                                <TableCell>
                                    <TrashCan className="mlp-clickable" onClick={() => {
                                        this.deleteUserSharing(s.user)
                                    }} />
                                </TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </div>
    }

}