import React from "react";
import "@carbon/charts/styles.css";
import {InlineLoading, Loading} from "@carbon/react";
import './sentiments.css'
import {ComboChart, DonutChart, PieChart, RadarChart} from "@carbon/charts-react";
import {ViewAdapter} from "../common/ViewAdapter";

//https://charts.carbondesignsystem.com/react/?path=/story/simple-charts-donut--donut

export default class MLPSentimentsInsights extends React.Component {

    jobDef = this.props.meta.jobJson
    jobId = this.jobDef.system.id
    viewAdapters = new ViewAdapter()

    constructor(props, context) {
        super(props, context);
        this.state = {
            sentimentsStatsView: null,
            sentimentsHateView: null,
            sentimentsTimeseriesView: null
        }
    }

    _showSentimentsStats() {
        if (this.state.sentimentsStatsView && !this.props.meta.isJobRunning()) {
            const sentimentsData = this.state.sentimentsStatsView.filter((group) => group.group == "Sentiments rated")
                .map((group) => {
                    return {"group": group.key, "value": group.value}
                })
            return (<div className={"mlp_sentiment_ratio_donut"}>
                <DonutChart data={sentimentsData} options={{
                    "title": "Sentiments - from texts",
                    "resizable": true,
                    "donut": {
                        "center": {
                            "label": "Sentences"
                        }
                    },
                    "height": "400px",
                    "width": "600px",
                }}></DonutChart></div>)
        } else {
            this.viewAdapters.registerViewListener('/v1/jobs/' + this.jobId + "/outputs/output_UI_chart1.json",
                (ratioView) => this.setState({sentimentsStatsView: ratioView}))
            return (<div className={"mlp_loading_graph_holder_600_400"}>
                <Loading description="loading sentiments stats" withOverlay={false}/>
            </div>)
        }
    }

    _showSentimentsMap() {
        if (this.state.sentimentsStatsView && !this.props.meta.isJobRunning()) {
            let sentimentsData = this.state.sentimentsStatsView
            return (<div className={"mlp_sentiment_ratio_donut"}>
                <RadarChart
                    data={sentimentsData}
                    options={{
                        "title": "Sentiments radar - from texts",
                        "resizable": true,
                        "height": "400px",
                        "width": "600px",
                    }}></ RadarChart></div>)
        } else {
            return (<div className={"mlp_loading_graph_holder_600_400"}>
                <Loading description="loading sentiments map" withOverlay={false}/>
            </div>)
        }
    }

    _showHateStats() {
        if (this.state.sentimentsHateView && !this.props.meta.isJobRunning()) {
            const sentimentsData = this.state.sentimentsHateView
            return (<div className={"mlp_sentiment_ratio_donut"}>
                <PieChart data={sentimentsData} options={{
                    "title": "Hate speech detection - from texts",
                    "resizable": true,
                    "height": "400px",
                    "width": "600px",
                }}></PieChart></div>)

        } else {
            this.viewAdapters.registerViewListener('/v1/jobs/' + this.jobId + "/outputs/output_aggregated_hate.json",
                (view) => this.setState({sentimentsHateView: view}))
            return (<div className={"mlp_loading_graph_holder_600_400"}>
                <Loading description="loading sentiments hate" withOverlay={false}/>
            </div>)
        }
    }

    _showSentimentsTimeseries() {
        if (this.state.sentimentsTimeseriesView && !this.props.meta.isJobRunning()) {
            const sentimentsTs = this.state.sentimentsTimeseriesView.map(item => {

                    if (typeof (item.time) === "string") {
                        item.time = Date.parse("01 " + item.time)
                    }
                    return item
                }
            ).sort(function (a, b) {
                return b.time - a.time
            });

            return (<div className={"mlp_sentiment_ratio_donut"}>
                <ComboChart
                    data={sentimentsTs}
                    options={{
                        "title": "Sentiments and rates over time - from texts",
                        "titleOrientation": 'right',
                        "stacked": true,
                        "height": "400px",
                        "width": "600px",
                        "curve": 'curveMonotoneX',
                        "axes": {
                            "right": {
                                "title": 'User Rate',
                                "mapsTo": 'stars',
                                "correspondingDatasets": [
                                    '1 stars',
                                    '2 stars',
                                    '3 stars',
                                    '4 stars',
                                    '5 stars'
                                ]
                            },
                            "bottom": {
                                "scaleType": 'time',
                                "mapsTo": 'time'
                            },
                            "left": {
                                "title": 'Sentiments Rate',
                                "mapsTo": 'sentiments'
                            }
                        },
                        "comboChartTypes": [
                            {
                                "type": 'area',
                                "options": {
                                    "points": {
                                        "enabled": false
                                    }
                                },
                                "correspondingDatasets": [
                                    'Very unsatisfied', 'Unsatisfied', 'Neutral', 'Satisfied', 'Very satisfied'
                                ]
                            },
                            {
                                "type": 'line',
                                "correspondingDatasets": [
                                    '1 stars',
                                    '2 stars',
                                    '3 stars',
                                    '4 stars',
                                    '5 stars'
                                ]
                            }
                        ],
                    }}></ComboChart></div>)
        } else {
            this.viewAdapters.registerViewListener('/v1/jobs/' + this.jobId + "/outputs/output_UI_chart2_over_time.json",
                (view) => this.setState({sentimentsTimeseriesView: view}))
            return (<div className={"mlp_loading_graph_holder_600_400"}>
                <Loading description="loading sentiments history" withOverlay={false}/>
            </div>)
        }
    }

    render() {
        if (this.props.meta.isJobFailed()) {
            return (
                <div className={"mlp_center"}><InlineLoading
                    description="This sentiments insight failed with this setup. This will ring a bell on our end."
                    status='error'/></div>
            )
        } else return (
            <div>
                <div className={"mlp_grid_1_2 mlp_chart_grid"}>
                    <div>{this._showSentimentsStats()}</div>
                    <div>{this._showSentimentsMap()}</div>
                </div>

                <div className={"mlp_grid_1_2 mlp_chart_grid"}>
                    <div>{this._showHateStats()}</div>
                    <div>{this._showSentimentsTimeseries()}</div>
                </div>
            </div>
        )
    }
}