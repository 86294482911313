import React from "react";

import MLPHeader from "../header/Header";
import MLPFooter from "../footer/Footer";
import NewSentiment from "./NewSentiment";


export default class MLPSentimentsPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="mlp_content_holder">
            <MLPHeader withAccount={true} subCat={"Sentiments"} />
            <br />
            <div className="mlp_center">
                <NewSentiment />
            </div>
            <MLPFooter />
        </div>


    }
}