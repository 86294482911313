import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import Cookies from 'universal-cookie'
import MLPGoogleAnalytics from "./GA";
import { fetchPermissions, getPermissions } from "../common/utils.js";

export default class MLPGoogleLogin extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            redirect: false
        };
        this.successResponseGoogle = this.successResponseGoogle.bind(this);
    }

    cookies = new Cookies();
    ga = new MLPGoogleAnalytics();

    successResponseGoogle(response) {
        const jwtToken = response.credential
        this.cookies.set('MLP_JWT', jwtToken, { path: '/', maxAge: 3600, sameSite: true });
        fetchPermissions().then(() => {
            console.log(getPermissions())
            this.ga.userLogin()
            this.setState({
                redirect: true
            })
        })
    }

    failResponseGoogle() {
        return false;
    }

    render() {
        if (this.state.redirect) {
            window.location = "/main"
            return (<div />)
        } else return (<div className="mlp_padding_02rem mlp_center">
            <GoogleOAuthProvider clientId={window.GOOGLE_CLIENT_ID}>
                <GoogleLogin
                    size={"large"}
                    shape={"pill"}
                    onSuccess={this.successResponseGoogle}
                    onError={this.failResponseGoogle}
                /></GoogleOAuthProvider>
        </div>
        )
    }

}
