import React from "react";

import './datasets.css'

import {
    DataTable,
    Dropdown,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    Toggletip,
    ToggletipButton,
    ToggletipContent
} from '@carbon/react';
import {queryParams} from "../index";
import Information from "@carbon/icons-react/lib/information/16";

export default class DataFields extends React.Component {

    headerData = [
        {
            key: 'name',
            header: 'Name',
        },
        {
            key: 'data_type',
            header: 'Type',
        },
        {
            key: 'role',
            header: 'Role',
        }
    ];

    constructor(props) {
        super(props);
        
        this.rowData = props.caseMeta.dataSourceJson.object.schema.fields.map(row => {
            row.id = row.name;
            return row
        })
    }

    onRoleFieldChange(cell, event) {
        this.props.caseMeta.onRoleFieldChange(cell, event)
    }

    _isPredefined() {
        const qParams = queryParams()
        return (qParams.id && qParams.id !== "")
    }

    _fieldRole(cell) {
        if (this.props.caseMeta.jobJson && this.props.caseMeta.jobJson.object.roles.length > 0) {
            return this.props.caseMeta.jobJson.object.roles.find(role => role.name === cell.id).role
        } else return this.props.roles[0]
    }

    dataFieldsHeader(header) {
        if (header.key === "role" && this.props.rolesExplanation) {
            return (<div>{header.header}&nbsp;&nbsp;&nbsp;<Toggletip align={"right"}>
                <ToggletipButton label="Additional information">
                    <Information/>
                </ToggletipButton>
                <ToggletipContent>
                    {this.props.rolesExplanation}
                </ToggletipContent>
            </Toggletip></div>)
        } else return header.header
    }

    _renderFieldCell(row, cell) {
        let isRole = cell.id.endsWith(':role')
        if (this.props.readOnly && isRole) {
            return this._fieldRole(row)
        }
        return isRole ?
            <Dropdown
                className={"mlp_churn_drop_role"}
                onChange={e => this.onRoleFieldChange(cell, e)}
                id={"roleSelector-" + cell.id}
                items={this.props.roles}
                label={this._fieldRole(row)}
                size='sm'
                disabled={this._isPredefined()}
                light
            /> : cell.value;
    }

    //const caseStyle = props.disabled ? "mlp_card_info mlp_dataset_card mlp_card_disabled" : "mlp_card_info mlp_dataset_card";
    render() {
        return (
            <div className={"mlp_data_fields_table"}>
                <div>{this.props.description}</div>
                <DataTable rows={this.rowData} headers={this.headerData}>
                    {({rows, headers, getHeaderProps, getTableProps}) => (
                        <TableContainer title="Data fields">
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({header})}>
                                                <div
                                                    className={"mlp_data_fields_" + header.header}>{this.dataFieldsHeader(header)}</div>
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.id}>
                                            {row.cells.map((cell) => {
                                                return <TableCell
                                                    key={cell.id}>{this._renderFieldCell(row, cell)}</TableCell>
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>
            </div>
        )
    }
}