import React from "react";

import MLPModelInfo from "../components/ModelInfo";
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper,
    TableContainer
} from "carbon-components-react";
import {InlineLoading, Loading} from "@carbon/react";
import {ViewAdapter} from "../common/ViewAdapter";


export default class MLPChurnML extends React.Component {

    jobDef = this.props.meta.jobJson
    jobId = this.jobDef.system.id
    viewAdapters = new ViewAdapter()

    constructor(props, context) {
        super(props, context);
        this.state = {
            modelActivityView: []
        }
    }

    render() {
        if (this.props.meta.isJobFailed()) {
            this.viewAdapters.abort();
            return (
                <div className={"mlp_center"}><InlineLoading
                    description="This churn insight failed on this setup. This will ring a bell on our end."
                    status='error'/></div>)
        } else if (this.state.modelActivityView.length === 0) {
            this.viewAdapters.registerViewListener('/v1/models?object.job_id=' + this.jobId,
                (view) => {
                    const executionModels = view.data.filter(model => model.object.job_id === this.jobId)
                    this.setState({modelActivityView: executionModels})
                }, 1000)
            return (
                <div className={"mlp_center"}><Loading description="computing ML Models" withOverlay={false}/></div>)
        } else {
            if (this.props.meta.isJobRunning()) {
                this.viewAdapters.registerViewListener('/v1/models?object.job_id=' + this.jobId,
                    (view) => {
                        const executionModels = view.data.filter(model => model.object.job_id === this.jobId)
                        console.log("running models: " + executionModels.length)
                        this.setState({modelActivityView: executionModels})
                    }, 5000)
            }
            return (
                <TableContainer className={"mlp_churn_ml_holder"}>
                    <StructuredListWrapper>
                        <StructuredListBody>
                            {this.state.modelActivityView.map((model) => (
                                <StructuredListRow key={"model_view_" + model.system.id}>
                                    <StructuredListCell>
                                        <MLPModelInfo model={model}/>
                                    </StructuredListCell>
                                </StructuredListRow>
                            ))}
                        </StructuredListBody>
                    </StructuredListWrapper>
                </TableContainer>
            )
        }
    }
}