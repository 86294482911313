import Cookies from "universal-cookie";
import { mlp_get } from "../index";

export function retry(func, delay, num) {
    return new Promise((resolve, reject) => {
        try {
            resolve(func())
        } catch (e) {
            if (num > 1) {
                setTimeout(() => {
                    retry(func, delay, num - 1).then(resolve, reject)
                }, delay)
            } else {
                reject(e)
            }

        }

    })
}

export function retryWith(func, delay, num) {
    return new Promise((resolve, reject) => {
        func().catch(e => {
            if (num > 1) {
                setTimeout(() => {
                    retryWith(func, delay, num - 1).then(resolve, reject)
                }, delay)
            } else {
                reject(e)
            }
        })

    })
}

const SERVER_API_URL = window.REACT_APP_API_URL || ""

export function fetchPermissions() {
    const cookies = new Cookies();
    return mlp_get(SERVER_API_URL + "/v1/authorization")
        .then(jsonData => {
            if (jsonData.status !== undefined) {
                cookies.set('USER', jsonData.props.user_info.user, { path: '/', maxAge: 3600, sameSite: true })
                cookies.set('PERMISSIONS', jsonData.props.user_info.permissions, { path: '/', maxAge: 3600, sameSite: true })
                return
            }
            cookies.set('PERMISSIONS', jsonData.object.permissions, { path: '/', maxAge: 3600, sameSite: true })
            cookies.set('USER', jsonData.object.user, { path: '/', maxAge: 3600, sameSite: true })
        });

}

export function getUser() {
    const cookies = new Cookies();
    const user = cookies.get('USER');
    return user
}

export function getPermissions() {

    const cookies = new Cookies();
    const permissions = cookies.get('PERMISSIONS');
    if (permissions === undefined) {
        return {}
    }
    return permissions
}

export function hasPermissions(perms) {
    const p = getPermissions();
    if (p.admin) {
        return true
    }
    if (perms.includes("manage_jobs") && !p.manage_jobs) {
        return false
    }
    if (perms.includes("manage_datasets") && !p.manage_datasets) {
        return false
    }
    if (perms.includes("manage_models") && !p.manage_models) {
        return false
    }
    if (perms.includes("enable_models") && !p.enable_models) {
        return false
    }
    if (perms.includes("approve_models") && !p.approve_models) {
        return false
    }
    if (perms.includes("move_models") && !p.move_models) {
        return false
    }
    if (perms.includes("predict") && !p.predict) {
        return false
    }
    return true
}

export function isShared(obj) {
    let user = getUser()
    return (user !== undefined && user !== obj.system.author)
}

export function CanManageShared(obj) {
    let user = getUser()
    if (user === undefined) {
        return false
    }

    let sharing = obj.object.sharing
    if (sharing === undefined) {
        return false
    }

    let toMe = sharing.find(s => (s.user === user))
    if (toMe === undefined) {
        toMe = sharing.find(s => (s.user === "*"))
    }
    if (toMe === undefined) {
        return false
    }

    let hasManage = toMe.permissions.find(p => p === "manage")
    return hasManage !== undefined
}