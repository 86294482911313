import React from "react";
import {Meta} from "../common/Meta";

export class ChurnMeta extends Meta{

    SUPPORTED_ROLES = [
        {
            role: "ignore",
            occurrences: "zero_or_more"
        },
        {
            role: "customer_id",
            occurrences: "one"
        },
        {
            role: "customer_feature",
            occurrences: "zero_or_more"
        },
        {
            role: "product",
            occurrences: "one"
        },
        {
            role: "price",
            occurrences: "one"
        },
        {
            role: "discount",
            occurrences: "zero_or_one"
        },
        {
            role: "quantity",
            occurrences: "zero_or_one"
        },
        {
            role: "transaction_id",
            occurrences: "one"
        },
        {
            role: "transaction_time",
            occurrences: "one"
        }
    ]

    defaultFieldRole = "customer_feature"

    constructor() {
        super();
        this.onCutChange = this.onCutChange.bind(this);
        this.onChurnNotSeenAfterInterval = this.onChurnNotSeenAfterInterval.bind(this);
        this.onRankedByMetric = this.onRankedByMetric.bind(this);

        this.cutTime = new Date().getTime()
        this.onChurnNotSeenAfter = React.createRef();

        this.churnNotSeenAfterInterval = 30
        this.churnNotSeenAfterIntervalDisplay = "Months"

        this.rankedByMetric = "f1"
        this.rankedByMetricDisplay = "F1 Score"
    }

    getCustomersSize() {
        let custNo = 0
        this.getRoles().map(role => {
            if (role.role === "customer") {
                const schema = this.getSchemaField(role.name)
                custNo += schema.summary_stats.count_unique ? schema.summary_stats.count_unique : 0;
            }
        })
        return custNo === 0 ? "computing..." : custNo;
    }

    getProdsSize() {
        let prodsNo = 0
        this.getRoles().map(role => {
            if (role.role === "product") {
                const schema = this.getSchemaField(role.name)
                prodsNo += schema.summary_stats.count_unique ? schema.summary_stats.count_unique : 0;
            }
        })
        return prodsNo === 0 ? "computing..." : prodsNo;
    }

    timeFrom() {
        return "2022/03/04"
    }

    timeUntil() {

        return "2022/09/04"
    }

    getSchemaField(fieldName) {
        return this.getSchemaFields().find(field => field.name === fieldName)
    }

    onCutChange(newCutTime) {
        this.cutTime = newCutTime
    }

    onChurnNotSeenAfterInterval(selectedItem) {
        switch (selectedItem) {
            case "Days":
                this.churnNotSeenAfterInterval = 1
                break;
            case "Months":
                this.churnNotSeenAfterInterval = 30
                break;
            case "Years":
                this.churnNotSeenAfterInterval = 365
                break;
            default:
                this.churnNotSeenAfterInterval = 30
                break;
        }
        this.churnNotSeenAfterIntervalDisplay = selectedItem
    }

    onRankedByMetric(selectedItem) {
        switch (selectedItem) {
            case "F1 Score":
                this.rankedByMetric = "f1"
                break;
            case "Months":
                this.rankedByMetric = "mse"
                break;
            case "Years":
                this.rankedByMetric = "rmse"
                break;
            default:
                this.rankedByMetric = "f1"
                break;
        }

        this.rankedByMetricDisplay = selectedItem
    }
}