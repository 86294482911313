import React from "react";

import './components.css'
import MLPCard from "./Card";
import Check from "./Check.js";
import {
    StructuredListBody,
    StructuredListCell,
    StructuredListRow,
    StructuredListWrapper
} from "carbon-components-react/lib/components/StructuredList";
import { Button } from "@carbon/react";
import { Add } from '@carbon/react/icons';


export default class MLPCase extends React.Component {

    caseStyle = this.props.disabled ? "mlp_card_info mlp_card_disabled" : "mlp_card_info";

    withActionButton() {
        if (this.props.actionButton !== undefined) {
            return (
                <Check perms="manage_jobs">
                    <div className={"mlp_case_actions"}><Button size={"sm"} kind={"primary"} renderIcon={Add} onClick={this.props.actionButton}><p>New</p></Button></div>
                </Check>
            )
        } else {
            return (<b />)
        }
    }

    render() {
        return (
            <MLPCard isFocus={this.props.isFocus} styleClass={this.caseStyle} onClick={this.props.onClick}>
                <StructuredListWrapper ariaLabel="cardType">
                    <StructuredListBody>
                        <StructuredListRow>
                            <StructuredListCell>
                                <h2 className={"mlp_size_large bold"}>{this.props.title}</h2>
                            </StructuredListCell>
                        </StructuredListRow>
                    </StructuredListBody>
                    <div className={"mlp_card_desc"}>{this.props.description}</div>
                    {this.withActionButton()}
                </StructuredListWrapper>
            </MLPCard>
        )
    }
}