import React from "react";
import MLPHeader from "../header/Header";
import MLPFooter from "../footer/Footer";

export default class MLPCustomPage extends React.Component {

    showCustom() {
        return (<div/>)
    }

    render() {
        return (
            <div className="mlp_content_holder">
                <MLPHeader withAccount={true} subCat={"Your custom insight analysis"}/>
                <br/>
                <div className="mlp_center">{this.showCustom()}</div>
                <MLPFooter/>
            </div>
        )
    }
}